import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  regulatoryLimitList: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_REGULATORY_LIMIT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.ADD_REGULATORY_LIMIT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.ADD_REGULATORY_LIMIT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_REGULATORY_LIMITS_REQUEST: return {
      ...state,
      isLoading: true,
      regulatoryLimitList: [],
    };
    case types.GET_REGULATORY_LIMITS_SUCCESS: return {
      ...state,
      isLoading: false,
      regulatoryLimitList: action.payload.dataList,
    };
    case types.GET_REGULATORY_LIMITS_FAILURE: return {
      ...state,
      isLoading: false,
      regulatoryLimitList: [],
    };
    case types.EDIT_REGULATORY_LIMIT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_REGULATORY_LIMIT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_REGULATORY_LIMIT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_REGULATORY_LIMIT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_REGULATORY_LIMIT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_REGULATORY_LIMIT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    default: return state;
  }
};
