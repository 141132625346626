import { takeEvery, delay, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import API from '../../api';

import * as types from '../types';

function* downloadFile(action) {
  try {
    const response = yield call(
      API.downloadFile,
      action.payload.filePath
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.setAttribute('download', action.payload.filePath);
    link.href = url;

    document.body.appendChild(link);

    link.click();
    yield put({
      type: types.DOWNLOAD_FILE_SUCCESS,
    });
    // if (response && response.data && response.data.message) {
    //   yield call(toast.success, response.data.message);
    // }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.DOWNLOAD_FILE_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* uploadFile(action) {
  // Upload File to Google Cloud Storage
  // Call POST API for Validation of uploaded file with a parameter which points to the location of the uploaded file
  // Call POST API of data generation
  try {
    const response = yield call(
      API.uploadFile,
      action.payload.values,
    );
    let errorData = {};
    let modelData = {};

    // File Validation failed
    if (response.data.validationstatus === 'false') {
      errorData = response.data.message;
      yield call(toast.error, 'File Validation Error');
      yield put({
        type: types.UPLOAD_FILE_SUCCESS,
        payload: {
          validationError: true,
          errorData,
          modelData: {},
        },
      });
      if (action.meta.onSuccess) {
        yield call(action.meta.onSuccess);
      }
    } else {
      modelData = response.data.message;
      yield call(toast.success, 'File Validation Successfull');
      yield put({
        type: types.UPLOAD_FILE_SUCCESS,
        payload: {
          validationError: false,
          modelData,
          errorData: {},
        },
      });
      if (action.meta.onSuccess1) {
        yield call(action.meta.onSuccess1);
      }
      // if (response && response.data && response.data.message) {
      // yield call(toast.success, response.data.message);
      // }
    }
    yield delay(1000);
  } catch (err) {
    // Validation Error
    yield call(toast.error, err.message);
    yield put({
      type: types.UPLOAD_FILE_FAILURE,
    });
    // if (err.response && err.response.data) {
    //   yield call(toast.error, err.response.data.message);
    // }
  }
}

export default function* () {
  yield takeEvery(types.UPLOAD_FILE_REQUEST, uploadFile);
  yield takeEvery(types.DOWNLOAD_FILE_REQUEST, downloadFile);
}
