import React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

// Big Loading Icon on App Load
export const Loading = () => (
  <Grid
    container
    spacing={0}
    direction="row"
    alignItems="center"
    justifyContent="center"
    style={{ paddingTop: '15px' }}
  >
    <CircularProgress />
    <Typography component="h2" variant="h6" color="textPrimary">
      Loading
    </Typography>
  </Grid>
);

// Smaller loading icons used throughout app
export const SmallLoading = () => (
  <>
    <CircularProgress />
    <Typography component="h2" variant="h6" color="textPrimary">
      Loading
    </Typography>
  </>
);

export const LoadingIcon = () => (
  <CircularProgress />
);
