import axios from 'axios';
import config from '../../config';

const uploadFile = (values) => {
	// Uploading reports via formData
	// eslint-disable-next-line no-undef
	const formData = new FormData();
	// if (values.date) {
	//   formData.append('date', values.date);
	// }
	// if (values.weight) {
	//   formData.append('weight', values.weight);
	// }
	// if (values.symptoms) {
	//   formData.append('symptoms', values.symptoms);
	// }
	// if (values.diagnosis) {
	//   formData.append('diagnosis', values.diagnosis);
	// }
	// if (values.treatment) {
	//   formData.append('treatment', values.treatment);
	// }
	// if (values.note) {
	//   formData.append('note', values.note);
	// }
	// if (values.Vaccinations && values.Vaccinations.length > 0) {
	//   // formData.append("Vaccinations",
	//   // JSON.stringify(values.Vaccinations));
	//   // for (let i = 0; i < values.Vaccinations.length; i += 1) {
	//   //   formData.append('Vaccinations', values.Vaccinations[i]);
	//   // }
	//   values.Vaccinations.forEach((item) => {
	//     formData.append('Vaccinations[]', JSON.stringify(item));
	//   });
	// }
	// if (values.deworming) {
	//   formData.append('deworming', values.deworming);
	// }
	// if (values.amount) {
	//   formData.append('amount', values.amount);
	// }
	// if (values.precaution) {
	//   formData.append('precaution', values.precaution);
	// }
	// converting multiple files to binary
	if (values.reports) {
		for (let i = 0; i < values.reports.length; i += 1) {
			formData.append('files', values.reports[i]);
		}
	}
	return axios.post(
		`${config.BACKEND_API}/v1/files/upload`,
		formData,
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		},
	);
};

const downloadFile = (filePath) => axios.get(
	`${config.BACKEND_API}/v1/getFile`,
	{
		params: {
			filePath
		},
		responseType: 'blob'
	}
);

export default {
	uploadFile,
	downloadFile,
};