import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory, Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CreateMasterDataModal from '../../components/createButtonModals/MasterData';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  editMasterData,
  deleteMasterData,
  getMasterData,
  addNewMasterData,
} from '../../services/MasterData/actions';
import {
  getUserAccessData,
} from '../../services/User/actions';
import {
  getExchangeRates,
} from '../../services/ExchangeRate/actions';
import MasterDataForm from '../../forms/MasterData';
import DeleteModal from '../../components/modals/DeleteModal';
import { Loading } from '../../components/Loading';
import { toast } from 'react-toastify';
import reckitt_logo from '../../assets/Asset_1.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '80px',
  // overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '180px',
  overflowWrap: 'anywhere',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F5F5',
  },
}));

const MasterDataPage = () => {
  const dispatch = useDispatch();
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setModal] = useState(false);
  const onEditAppointment = (values) => {
    dispatch(
      editMasterData(values, () => {
        dispatch(
          getMasterData(),
        );
      }),
    );
  };

  const onSubmitAppointment = (values) => {
    let duplicateFound = false;
    for (let i = 0; i < masterDataList.length; i++) {
      if (masterDataList[i]['tds'] === values.tds) {
        duplicateFound = true;
        toast.error('Duplicate TDS Found');
      }
      if (masterDataList['rawmaterial'] === values.rawmaterial) {
        duplicateFound = true;
        toast.error('Duplicate Raw Material Found');
      }
    }
    if (!duplicateFound) {
      dispatch(
        addNewMasterData(values, () => {
          setModal(false);
          dispatch(
            getMasterData()
          )
        }),
      );
    }
  };

  const onDeleteAppointment = (values) => {
    dispatch(
      deleteMasterData(values, () => {
        dispatch(
          getMasterData(),
        );
      }),
    );
  };
  const email = useSelector((state) => state.Auth.email);

  useEffect(() => {
    dispatch(
      getExchangeRates(),
    );
    dispatch(
      getUserAccessData({ email: email }, () => {
        dispatch(
          getMasterData()
        )
      })
    )
  }, [dispatch]);
  const masterDataList = useSelector((state) => state.MasterData.masterDataList);
  const isLoading = useSelector((state) => state.MasterData.isLoading);
  const userLoading = useSelector((state) => state.User.isLoading);
  const userData = useSelector((state) => state.User.userData);
  let treatmentCounter = masterDataList.length;
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Master Data
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Cost
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            Master Data
          </Link>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        {userLoading ? (
          <Loading />
        ) : (
          <Box>
            {userData?.masterdata === true ? (
              <Box p={2}>
                <CreateMasterDataModal
                  handleSubmit={onSubmitAppointment}
                  size="sm"
                  buttonText="CREATE NEW ENTRY"
                  modalHeaderText="Add Entry"
                  withoutClient
                  setModal={setModal}
                  showModal={showModal}
                />
                <Box pt={10}>
                  <Grid container>
                    {(isLoading) ? (
                      <Grid item xs={12} alignItems="center">
                        <Loading />
                      </Grid>
                    ) : (
                      <Grid item xs={12} alignItems="center">
                        <TableContainer sx={{ display: 'block', overflowX: 'auto' }}>
                          <Table size="small" aria-label="customized table" sx={{ display: 'block', overflowX: 'auto' }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell rowSpan={3} align="center" padding="none" />
                                <StyledTableCell rowSpan={3} align="center" padding="none">#</StyledTableCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">Classification</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">Raw Material</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">Purity Percentage</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">TDS</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">Local Plant Code</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>Brazil</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>Australia</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>India</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>Malaysia</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>South Africa</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>Global</StyledTableFixedWithCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>RB Factory - Sao Paulo</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>Co-packer Aaron</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>RB Factory - Jammu</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>RB Factory - Johor Bahru</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={3}>RB Factory - Elandfonstein</StyledTableFixedWithCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">BRL/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">AUD/UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">INR/UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">MYR/UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">ZAR/UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">UOM</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">GBP/KG</StyledTableFixedWithCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {masterDataList.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell padding="none" align="center" component="th" scope="row">
                                    <EditIcon
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                      onClick={() => {
                                        setModalData(row); // set modal data to currently clicked row
                                        setUpdateModal(true);
                                        // handleChange(data[tableMeta.rowIndex]);
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <>
                                      {treatmentCounter--}
                                    </>
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.classification && row.classification}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.rawmaterial && row.rawmaterial}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.purityPercentage && row.purityPercentage}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.tds && row.tds}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.localPlantCode && row.localPlantCode}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.brazil.uom && row.brazil.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.brazil.countryCost && row.brazil.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.brazil.usdCost && row.brazil.usdCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.australia.uom && row.australia.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.australia.countryCost && row.australia.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.australia.usdCost && row.australia.usdCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.india.uom && row.india.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.india.countryCost && row.india.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.india.usdCost && row.india.usdCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.malaysia.uom && row.malaysia.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.malaysia.countryCost && row.malaysia.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.malaysia.usdCost && row.malaysia.usdCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.southafrica.uom && row.southafrica.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.southafrica.countryCost && row.southafrica.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.southafrica.usdCost && row.southafrica.usdCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row?.global?.uom && row?.global?.uom}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row?.global?.countryCost && row?.global?.countryCost}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row?.global?.usdCost && row?.global?.usdCost}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ paddingTop: '15px' }}
              >
                <Typography variant="h5">
                  Access is denied. Please contact admin.
                </Typography>
              </Grid>
            )}
          </Box>
        )}
      </Box>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showUpdateModal}
        onClose={() => { setUpdateModal(false); setModalData({}); }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Button
                color="error"
                variant="contained"
                onClick={() => { setUpdateModal(false); setDeleteModal(true); }}
              >
                Delete
              </Button>
            </Box>
            <Box>
              <IconButton onClick={() => { setUpdateModal(false); setDeleteModal(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MasterDataForm
            initialValues={modalData}
            isEdit={true}
            onSubmit={onEditAppointment}
          />
        </DialogContent>
      </Dialog>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setModalData={setModalData}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteAppointment}
        setUpdateModal={setUpdateModal}
        modalData={modalData}
      />
    </SideMenuAppWrapper>
  );
};

export default MasterDataPage;
