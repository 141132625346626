import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteModal(props) {
  const {
    showDeleteModal,
    setModalData,
    setDeleteModal,
    handleDelete,
    setUpdateModal,
    modalData,
  } = props;
  return (
    <>
      <Dialog
        open={showDeleteModal}
        maxWidth="md"
        onClose={() => {
          if (typeof setDeleteModal === 'function') {
            setDeleteModal(false);
          }
          if (typeof setModalData === 'function') {
            setModalData({});
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              Are you sure you want to delete?
            </Box>
            <Box>
              <IconButton onClick={() => {
                if (typeof setDeleteModal === 'function') {
                  setDeleteModal(false);
                }
                if (typeof setModalData === 'function') {
                  setModalData({});
                }
              }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box padding={2}>
            <Typography>
              This action is irreversible and the following record would be permanently deleted.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (typeof setDeleteModal === 'function') {
                setDeleteModal(false);
              }
              if (typeof setUpdateModal === 'function') {
                setUpdateModal(true);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              if (typeof handleDelete === 'function') {
                handleDelete(modalData);
              }
              if (typeof setDeleteModal === 'function') {
                setDeleteModal(false);
              }
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
