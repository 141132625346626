import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@mui/material/Button';
import Input from '../components/Input';
import { renderSelectInput } from '../components/SelectInput';

const validate = (values) => {
  const errors = {};
  if (isEmpty(values.country)) {
    errors.country = 'Enter country';
  }
  if (isEmpty(values.code)) {
    errors.code = 'Enter code';
  }
  if (isEmpty(values.name)) {
    errors.name = 'Enter name';
  }
  if (isEmpty(values.rate)) {
    errors.rate = 'Enter rate';
  }
  return errors;
};

let ExchangeRateForm = (props) => {
  const {
    handleSubmit,
    submitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={`country`}
        type="string"
        label="Country"
        component={renderSelectInput}
        list={[
          {
            label: 'Brazil',
            value: 'brazil',
          },
          {
            label: 'Australia',
            value: 'australia',
          },
          {
            label: 'India',
            value: 'india',
          },
          {
            label: 'Malaysia',
            value: 'malaysia',
          },
          {
            label: 'South Africa',
            value: 'southafrica',
          },
          {
            label: 'Global',
            value: 'global',
          },
        ]}
        required
      />
      <Field
        name={`code`}
        label="Code"
        type="string"
        component={Input}
        required
      />
      <Field
        name={`name`}
        label="Name"
        type="string"
        component={Input}
        required
      />
      <Field
        name={`rate`}
        label="Rate"
        type="number"
        component={Input}
        required
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting}
      >
        Submit
      </Button>
    </form>
  );
};

ExchangeRateForm = reduxForm({
  form: 'MAST_DATA_2_FORM',
  validate,
})(ExchangeRateForm);

export default ExchangeRateForm;
