// import { firebaseReducer } from 'react-redux-firebase';
import { reducer as formReducer } from 'redux-form';
import Auth from './Auth/reducers';
import File from './File/reducers';
import Model from './Model/reducers';
import ExchangeRate from './ExchangeRate/reducers';
import MasterData from './MasterData/reducers';
import Project from './Project/reducers';
import RegulatoryLimit from './RegulatoryLimit/reducers';
import User from './User/reducers';

export default {
  form: formReducer,
  // firebaseReducer,
  Auth,
  File,
  Model,
  ExchangeRate,
  MasterData,
  Project,
  User,
  RegulatoryLimit,
};
