import React, { Component } from 'react';
import Grid from '@mui/material/Grid';

class PageNotFound extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <div>
            <h1>404 not found</h1>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default PageNotFound;
