
import Auth from './Auth/sagas';
import File from './File/sagas';
import Model from './Model/sagas';
import ExchangeRate from './ExchangeRate/sagas';
import MasterData from './MasterData/sagas';
import Project from './Project/sagas';
import RegulatoryLimit from './RegulatoryLimit/sagas';
import User from './User/sagas';

export default [
  Auth,
  File,
  ExchangeRate,
  Model,
  MasterData,
  Project,
  User,
  RegulatoryLimit,
];
