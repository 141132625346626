import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { isEmpty, toLower } from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import detectBrowserLanguage from 'detect-browser-language';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../services/store';
import Router from '../routes';
import config from '../config';
import translations from '../intl/translations.json';
import './App.scss';
import { updateUserInfoLoginSuccess, updateUserInfoLoginFailure } from '../services/Auth/actions';
import { Loading } from '../components/Loading';

const CloseButton = ({ closeToast }) => (
  <FontAwesomeIcon
    icon="times"
    onClick={closeToast}
  />
);

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const Auth = useSelector(state => state.Auth);
  const history = useHistory();

  // const userData = useSelector(state => state.Auth.userData);
  // constructor(props) {
  //   super(props);
  //   // Setup local state
  //   this.state = {
  //     initialized: false,
  //     locale: '',
  //   };

  //   // Setup redux store
  //   this.store = store();

  //   // Setup google analytics
  //   if (config.GOOGLE_ANALYTICS) {
  //     // ReactGA.initialize(config.GOOGLE_ANALYTICS, {
  //     //   debug: process.env.NODE_ENV !== 'production',
  //     // });

  //     // eslint-disable-next-line no-undef
  //     // ReactGA.pageview(window.location.pathname + window.location.search);
  //   }

  //   // Setup internationalization
  //   addLocaleData([
  //     ...en,
  //   ]);

  //   // Add fontawesome icons
  //   library.add(faSpinner);
  //   library.add(faTimes);
  // }

  // componentDidMount() {
  // Handle authentication
  // Check if user is authenticated
  // if (config.STAGE === 'development') {
  //   this.setState({
  //     initialized: true,
  //   })
  // } else {
  //   dispatch(checkUserSession(() => {
  //     const location = window.location.pathname;
  //     history.push(location);
  //     this.setState({
  //       initialized: true,
  //     })
  //   },
  //     () => {
  //       window.localStorage.removeItem(config.localStorageReduxStoreName);
  //       window.location.href = config.SIGN_IN_URL + window.location.host + window.location.pathname;
  //       this.setState({
  //         initialized: true,
  //       });
  //     }));
  // }
  useEffect(() => {
    let url = config.AUTH_URL;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          return {
            error,
            errorCode,
            status: {},
          };
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          return {
            error,
            errorCode,
            status: {},
          };
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json.status.statusCode === "auth-200") {
          dispatch(updateUserInfoLoginSuccess(
            json.data.name,
            json.data.preferred_username,
            json.data.preferred_username,
            true,
            null,
            null,
            json.data.role,
          ));
          // let location = config.hardCoded.homeLocation;
          const location = window.location.pathname;
          // setAuthResolved(true);
          setInitialized(true);
          history.push(location);
        }
        if (json.errorCode === 401) {
          dispatch(updateUserInfoLoginFailure(
            "",
            "",
            "",
            false,
            "UNAUTHORIZED",
            "",
            "",
          ));
          setInitialized(true);
          // window.localStorage.removeItem(config.localStorageReduxStoreName);
          // setAuthResolved(true);
        } else if (json.errorCode) {
          dispatch(updateUserInfoLoginFailure(
            "",
            "",
            "",
            false,
            json.error,
            "",
            "",
          ));
          // updateUserInfo({ error: json.error, errorCode: json.errorCode });
          // setAuthResolved(true);
          setInitialized(true);
        }
      });
  }, []);
  // }
  // Unauthorized user
  useEffect(() => {
    if (Auth.error === "UNAUTHORIZED") {
      updateUserInfoLoginFailure({ isAuth: false });
      // window.localStorage.removeItem(config.localStorageReduxStoreName);
      window.location.href = config.SIGN_IN_URL + window.location.host + window.location.pathname;
    }
    // eslint-disable-next-line
  }, [Auth.error]);
  // getLang = () => {
  //   const browserLang = detectBrowserLanguage().slice(0, 2);
  //   const { locale } = this.state;
  //   if (!isEmpty(locale)) {
  //     return toLower(locale);
  //   }
  //   // Check if browers lang is avalible
  //   // other wise fall back to en.
  //   if (isEmpty(config.AVAILABLE_LANGUAGES.filter(l => l === toLower(browserLang)))) {
  //     return config.AVAILABLE_LANGUAGES[0];
  //   }
  //   return toLower(browserLang);
  // }

  // render() {
  //   const lang = this.getLang();
  //   if (config.STAGE !== 'production') {
  //     console.log('current lang:', lang);
  //   }

  //   const { initialized } = this.state;

  return (
    <>
      {initialized ? (
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      ) : (
        <div style={{ width: '100%', height: '100vh', top: '20px' }}>
          <Loading size="5rem" />
        </div>
      )}
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={3000}
        pauseOnFocusLoss={false}
        closeButton={<CloseButton />}
      />
    </>
  );
  // }
}

export default App;
