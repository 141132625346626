import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import Input from '../components/Input';

const validate = (values) => {
  const errors = {};
  if (isEmpty(values.email)) {
    errors.email = 'Enter email';
  }
  if (isEmpty(values.password)) {
    errors.password = 'Enter password';
  }
  return errors;
};

let LoginForm = (props) => {
  const {
    handleSubmit,
    submitting,
  } = props;
  const isLoading = useSelector((state) => state.Auth.isLoading);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={Input}
        name="email"
        type="email"
        label="Email"
        disabled={submitting || isLoading}
        required
      />
      <Field
        component={Input}
        name="password"
        type="password"
        label="Password"
        disabled={submitting || isLoading}
        required
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        Submit
      </Button>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'LOGIN_FORM',
  validate,
})(LoginForm);

export default LoginForm;
