import axios from 'axios';
import config from '../../config';

// Plant
const getUsers = () => axios.get(
  `${config.BACKEND_API}/v1/users`,
);

const addNewUser = (values) => axios.post(
  `${config.BACKEND_API}/v1/users`,
  values,
);

const editUser = (values) => axios.put(
  `${config.BACKEND_API}/v1/users`,
  values,
);

const deleteUser = (values) => axios.delete(
  `${config.BACKEND_API}/v1/users`,
  { data: values },
);

const getAccessData = (values) => axios.post(
  `${config.BACKEND_API}/v1/user/access`,
  values,
)


export default {
  getUsers,
  addNewUser,
  editUser,
  deleteUser,
  getAccessData
};
