import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory, Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CreateProjectModal from '../../components/createButtonModals/Project';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  editProject,
  deleteProject,
  getProjects,
  addNewProject,
} from '../../services/Project/actions';
import {
  getUserAccessData,
} from '../../services/User/actions';
import ProjectForm from '../../forms/Project';
import DeleteModal from '../../components/modals/DeleteModal';
import { Loading } from '../../components/Loading';
import {
  getMasterData,
} from '../../services/MasterData/actions';
import reckitt_logo from '../../assets/Asset_1.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '80px',
  // overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '180px',
  overflowWrap: 'anywhere',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F5F5',
  },
}));

const Projects = () => {
  const dispatch = useDispatch();
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setModal] = useState(false);

  const onEditAppointment = (values) => {
    dispatch(
      editProject(values, () => {
        dispatch(
          getProjects(),
        );
      }),
    );
  };

  const onSubmitAppointment = (values) => {
    dispatch(
      addNewProject(values, () => {
        setModal(false);
        dispatch(
          getProjects()
        )
      }),
    );
  };

  const onDeleteAppointment = (values) => {
    dispatch(
      deleteProject(values, () => {
        dispatch(
          getProjects(),
        );
      }),
    );
  };

  const email = useSelector((state) => state.Auth.email);
  // Get values from redux store
  useEffect(() => {
    dispatch(
      getUserAccessData({ email: email }, () => {
        dispatch(
          getProjects()
        );
        dispatch(
          getMasterData()
        )
      })
    )
  }, [dispatch]);
  const projectList = useSelector((state) => state.Project.projectList);
  const masterDataList = useSelector((state) => state.MasterData.masterDataList);
  const isLoading = useSelector((state) => state.Project.isLoading);
  const userLoading = useSelector((state) => state.User.isLoading);
  const userData = useSelector((state) => state.User.userData);
  let treatmentCounter = projectList.length;
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Projects
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            Projects
          </Link>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        {userLoading ? (
          <Loading />
        ) : (
          <Box>
            {userData?.projects === true ? (
              <Box p={2}>
                <CreateProjectModal
                  handleSubmit={onSubmitAppointment}
                  size="md"
                  buttonText="CREATE NEW PROJECT"
                  modalHeaderText="Add Project"
                  withoutClient
                  setModal={setModal}
                  showModal={showModal}
                  masterDataList={masterDataList}
                />
                <Box pt={10}>
                  <Grid container>
                    {(isLoading) ? (
                      <Grid item xs={12} alignItems="center">
                        <Loading />
                      </Grid>
                    ) : (
                      <Grid item xs={12} alignItems="center">
                        <TableContainer sx={{ display: 'block', overflowX: 'auto' }}>
                          <Table size="small" aria-label="customized table" sx={{ display: 'block', overflowX: 'auto' }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center" padding="none" />
                                <StyledTableCell align="center" padding="none">#</StyledTableCell>
                                <StyledTableFixedWithCell padding="none" align="center">Name</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Country</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Amount</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Materials</StyledTableFixedWithCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {projectList.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell padding="none" align="center" component="th" scope="row">
                                    <EditIcon
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                      onClick={() => {
                                        setModalData(row); // set modal data to currently clicked row
                                        setUpdateModal(true);
                                        // handleChange(data[tableMeta.rowIndex]);
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <>
                                      {treatmentCounter--}
                                    </>
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.name && row.name}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.country && row.country}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.amount && row.amount}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.data && row.data.map((element) => (
                                      <div key={element.tds}>
                                        {element.tds}
                                        ,
                                        {element.rawmaterial}
                                        ,
                                        {element.classification}
                                        ,
                                        {element.kg}
                                        ,
                                        {element.cost}
                                      </div>
                                    ))}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ paddingTop: '15px' }}
              >
                <Typography variant="h5">
                  Access is denied. Please contact admin.
                </Typography>
              </Grid>
            )}
          </Box>
        )}
      </Box>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={showUpdateModal}
        onClose={() => { setUpdateModal(false); setModalData({}); }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Button
                color="error"
                variant="contained"
                onClick={() => { setUpdateModal(false); setDeleteModal(true); }}
              >
                Delete
              </Button>
            </Box>
            <Box>
              <IconButton onClick={() => { setUpdateModal(false); setDeleteModal(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ProjectForm
            initialValues={modalData}
            onSubmit={onEditAppointment}
          />
        </DialogContent>
      </Dialog>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setModalData={setModalData}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteAppointment}
        setUpdateModal={setUpdateModal}
        modalData={modalData}
      />
    </SideMenuAppWrapper>
  );
};

export default Projects;
