export default {
  STAGE: process.env.NODE_ENV || 'development',
  RELEASE: process.env.REACT_APP_RELEASE || '',
  // NOTE: First one in the list will be the system defalt.
  AVAILABLE_LANGUAGES: ['en'],
  // Optional services that will be enabled by commenting them in a providing a key.
  // GOOGLE_ANALYTICS: 'UA-XXXXXXXXXX-X',
  DEFAULT_ERROR: 'Sorry something went wrong',
  API: {
    development: 'http://127.0.0.1:5000/api',
    dev: '',
    uat: '',
    prod: '',
  },
  BACKEND_API: '/api',
  SIGN_IN_URL: `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`,
  SIGN_OUT_URL: `/auth/sign_out`,
  AUTH_URL: '/api/userinfo',
  USER_INFO_URL: `/api/userinfo?post_sign_in_url=https%3A%2F%2F`,
  AUTHORISE_URL: `/api/isauthorised?post_sign_in_url=https%3A%2F%2F`,
  APP_USER_ROLE_URL: `/api/appuserrole`,
  MESSAGES: {
    workInProgressMessage: "Your content here.",
    authenticatingUserMessage: "Authenticating user",
  },
  HARDCODED: { homeLocation: "/home", appName: "Base App" }
};

/*-------------------------Actual APIs-------------------------*/
// const backendApi = "/api";
// const signInUrl = `/auth/sign_in?post_sign_in_url=https%3A%2F%2F`;
// const signOutUrl = `/auth/sign_out`;
// const authUrl = `${backendApi}/userinfo`;

// const messages = {
//   workInProgressMessage: "Your content here.",
//   authenticatingUserMessage: "Authenticating user",
// };

// const hardCoded = { homeLocation: "/home", appName: "Base App" };

// const api = {
//   authUrl,
//   signInUrl,
//   signOutUrl,
// };

// const config = {
//   footerText: "Powered by RBOne",
//   api,
//   messages,
//   hardCoded,
// };

// export { config };