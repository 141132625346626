import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, FieldArray, change, formValueSelector } from 'redux-form';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../components/Input';
import { renderSelectInput } from '../components/SelectInput';
import renderComponentAdd from '../components/DynamicProjectInput';
import { Typography } from '@mui/material';

const validate = (values) => {
  const errors = {};
  return errors;
};

let ProjectForm = (props) => {
  const {
    handleSubmit,
    submitting,
    initialValues,
    data,
    selectedCountry,
  } = props;
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const masterDataList = useSelector((state) => state.MasterData.masterDataList);
  let totalAddedAmount = 0;
  const dispatch = useDispatch();
  const tempData = data;
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (selectedCountry) {
        if (data[i].kg && Number(data[i].kg) > 0) {
          for (let j = 0; j < masterDataList.length; j++) {
            if (data[i]?.tds === masterDataList[j].tds) {
              tempData[i].cost = Number(Number(masterDataList[j][selectedCountry]?.countryCost) * Number(data[i].kg)).toFixed(3);
              tempData[i].gbpcost = Number(Number(masterDataList[j][selectedCountry]?.usdCost) * Number(data[i].kg)).toFixed(3);
            }
          }
        }
      }
      if (data[i].cost && Number(data[i].cost) > 0) {
        totalAddedAmount += Number(data[i].cost);
      }
    };
    if (totalAddedAmount > 0) {
      dispatch(change('PROJECT_FORM', 'amount', Number(totalAddedAmount).toFixed(3)));
    }
    dispatch(change('PROJECT_FORM', 'data', tempData));
  }
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={`name`}
        type="string"
        label="Project Name"
        component={Input}
        required
      />
      <Field
        name={`country`}
        type="string"
        label="Country"
        component={renderSelectInput}
        list={[
          {
            label: 'Brazil',
            value: 'brazil',
          },
          {
            label: 'Australia',
            value: 'australia',
          },
          {
            label: 'India',
            value: 'india',
          },
          {
            label: 'Malaysia',
            value: 'malaysia',
          },
          {
            label: 'South Africa',
            value: 'southafrica',
          },
          {
            label: 'Global',
            value: 'global',
          },
        ]}
        required
      />
      <Grid item xs={12} md={12}>
        <FieldArray
          name="data"
          component={renderComponentAdd}
          masterDataList={masterDataList}
          arrayData={data}
          label=""
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6">
          TOTAL AMOUNT:
          {Number(totalAddedAmount).toFixed(3)}
        </Typography>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        Submit
      </Button>
    </form>
  );
};
const selector = formValueSelector('PROJECT_FORM');

ProjectForm = reduxForm({
  form: 'PROJECT_FORM',
  validate,
  enableReinitialize: true,
})(ProjectForm);

ProjectForm = connect(state => { // eslint-disable-line
  const data = selector(state, 'data');
  const selectedCountry = selector(state, 'country');
  return {
    data,
    selectedCountry,
  };
})(ProjectForm);

export default ProjectForm;
