import React from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import config from '../config';
import { Loading } from '../components/Loading';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    componentDidMount() {
      const {
        Auth: {
          isAuthenticated,
          isAuth,
          error
        },
      } = this.props;

      // if (error === 'UNAUTHORIZED') {
      //   toast.warn('You need to be signed in to access this page');
      //   // this.props.history.push('/');
      //   // window.localStorage.removeItem(config.localStorageReduxStoreName);
      //   window.location.href = config.SIGN_IN_URL + window.location.host + window.location.pathname;
      // }

      if (!isAuth) {
        return (
          <div style={{ width: '100%', height: '100vh', top: '20px' }}>
            <Loading size="5rem" />
          </div>)
        // toast.warn('You need to be signed in to access this page');
        // this.props.history.push('/');
        // window.localStorage.removeItem(config.localStorageReduxStoreName);
        // window.location.href = config.SIGN_IN_URL + window.location.host + window.location.pathname;
      }
    }

    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }

  const mapStateToProps = state => ({
    Auth: state.Auth,
  });

  const mapDispatchToProps = {
  };

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
