import * as React from 'react';
import { ChartComponent, LineSeries, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, ScatterSeries } from '@syncfusion/ej2-react-charts';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// const tempData = [{ x: -100, y: 0 }, { x: 100, y: 0 }];
const ScatterPlot = ({ graphData }) => {
  const res1 = graphData.reduce(function (prev, current) {
    return (prev.x < current.x) ? prev : current
  });
  const res2 = graphData.reduce(function (prev, current) {
    return (prev.x > current.x) ? prev : current
  });
  const tempData = [{ x: res1.x, y: 0 }, { x: res2.x, y: 0 }];
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" variant="h6">
          Residual Plot
        </Typography>
        <Tooltip title="Residuals indicate unexplained variation. They should be randomly distributed around 0" placement="top">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <ChartComponent
        style={{ textAlign: 'center' }}
        palettes={['#0096FF', 'grey', '#6FAAB0', '#C4C24A']}
        primaryXAxis={{
          // minimum: 100,
          // maximum: 220,
          majorGridLines: { width: 0 },
          edgeLabelPlacement: 'Shift',
          title: '',
        }}
        primaryYAxis={{
          // majorTickLines: {
          // width: 10
          // },
          // lineStyle: { width: 10 },
          // minimum: 50,
          // maximum: 80,
          title: '',
          rangePadding: 'None',
        }}
        title=""
        // tooltip={{ enable: true, format: 'Weight: <b>${point.x} lbs</b> <br/> Height: <b>${point.y}"</b>' }}
        // width={Browser.isDevice ? '100%' : '80%'}
        chartArea={{ border: { width: 1 } }}
      >
        <Inject services={[ScatterSeries, LineSeries, Legend, Category]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={graphData}
            width={2}
            xName="x"
            yName="y"
            name=""
            type="Scatter"
            // opacity={0.6}
            marker={{ visible: true, width: 12, height: 12, shape: 'Circle', color: '#0000FF' }}
          />
          <SeriesDirective
            dataSource={tempData}
            xName="x"
            yName="y"
            name=""
            width={2}
            marker={{ visible: false, width: 10, height: 10 }}
            type="Line"
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </>
  );
};

export default ScatterPlot;
