import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import Stack from '@mui/material/Stack';
import isEmpty from 'lodash/isEmpty';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import card1 from '../../assets/img/card1.png';
import card2 from '../../assets/img/card2.png';
import card3 from '../../assets/img/card3.png';
import card4 from '../../assets/img/card4.png';
import commonStyle from '../../common-style';
import '../../assets/stylesheet/landing-page.css';
import reckitt_logo from '../../assets/Asset_1.png';

const AerosolPage = () => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const validationError = useSelector(state => state.File.validationError);
  const errorData = useSelector(state => state.File.errorData);
  const classes = commonStyle();
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Bioefficacy Prediction & Formulation Optimization : AEROSOL
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            Aerosol
          </Link>
        </Breadcrumbs>
        {/* <Box>
          <Grid item xs={12} alignItems="center" pt={1}>
            <Typography align="center" variant="body1">
              Select Activity
            </Typography>
          </Grid>
        </Box> */}
        <Box p={10}>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* <Grid item xs={12} md={3} alignItems="center">
              <Card sx={{ maxWidth: 230 }}>
                <Link to="/showModels">
                  <CardMedia
                    component="img"
                    height="200"
                    image={card1}
                    alt="green iguana"
                  />
                  <CardActions style={{
                    background: '#fff'
                  }}>
                    <Button
                      className={classes.root}
                      fullWidth={true}
                      style={{ margin: '0 auto', padding: '0px', color: 'black', textTransform: 'capitalize', fontSize: '14px', fontWeight: '400', border: 'none', outline: 'none', background: 'none' }}
                    >
                      Upload Data File
                    </Button>
                  </CardActions>
                </Link>
              </Card>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Card sx={{ maxWidth: 300 }}>
                <Link to="/models/actives">
                  <CardMedia
                    component="img"
                    height="200"
                    image={card2}
                    alt="green iguana"
                  />
                  <CardActions style={{
                    background: '#fff'
                  }}>
                    <Tooltip placement="top" title="Predict KT50 and M24 based on historical experiments">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Button style={{ margin: '0 auto', padding: '0px', color: 'black', textTransform: 'capitalize', fontSize: '18px', fontWeight: '400', border: 'none', outline: 'none', background: 'none' }}>
                      Bioefficacy Predictions
                    </Button>
                  </CardActions>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ maxWidth: 300 }}>
                <Link to="/formulaOptimisation">
                  <CardMedia
                    component="img"
                    height="200"
                    image={card3}
                    alt="green iguana"
                  />
                  <CardActions style={{
                    background: '#fff'
                  }}>
                    <Tooltip placement="top" title="Get the best Active Combination(s) for a specific KT50/M24 value">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Button style={{ margin: '0 auto', padding: '0px', color: 'black', textTransform: 'capitalize', fontSize: '18px', fontWeight: '400', border: 'none', outline: 'none', background: 'none' }}>
                      Suggestive Formulations
                    </Button>
                  </CardActions>
                </Link>
              </Card>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '10px' }}>
              <Alert severity="info">
                Current tool works for oil based formulations in a 20 m3 chamber <strong>*</strong>
              </Alert>
            </Grid>
            {/* <Grid item xs={12} md={3} alignItems="center">
              <Card sx={{ maxWidth: 230 }}>
                <Link to="/models/all">
                  <CardMedia
                    component="img"
                    height="200"
                    image={card4}
                    alt="green iguana"
                  />
                  <CardActions style={{
                    background: '#fff'
                  }}>
                    <Button style={{ margin: '0 auto', padding: '0px', color: 'black', textTransform: 'capitalize', fontSize: '14px', fontWeight: '400', border: 'none', outline: 'none', background: 'none' }}>
                      Show All Models
                    </Button>
                  </CardActions>
                </Link>
              </Card>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={showDeleteModal}
        maxWidth="md"
        onClose={() => {
          if (typeof setDeleteModal === 'function') {
            setDeleteModal(false);
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              This file cannot be used for building a model.
            </Box>
            <Box>
              <IconButton onClick={() => {
                if (typeof setDeleteModal === 'function') {
                  setDeleteModal(false);
                }
              }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box padding={2}>
            {!isEmpty(errorData) && validationError && (
              <Stack spacing={2}>
                <Alert severity="error">
                  {Object.keys(errorData).map((key, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={i}>
                      <Typography variant="h5" component="div">
                        {key}
                      </Typography>
                      {(errorData[key].map((media, ind) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={ind}>
                          {/* {key} */}
                          {/* {ind} */}
                          <Typography variant="body1" component="p">
                            {errorData[key][ind]}
                          </Typography>
                          {/* <h3>{media.name}</h3> */}
                        </div>
                      ))
                      )}
                    </Box>
                  ))}
                </Alert>
              </Stack>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (typeof setDeleteModal === 'function') {
                setDeleteModal(false);
              }
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </SideMenuAppWrapper>
  );
};

export default AerosolPage;
