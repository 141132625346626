import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  modelsList: [],
  modelsList1: [],
  activesList: [],
  predictionResult: {},
  predictionResultLoading: false,
  modelDataForPrediction: {},
  suggestiveFormulationsResultLoading: false,
  suggestiveFormulationsResult: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.GET_ALL_MODELS_REQUEST: return {
      ...state,
      isLoading: true,
      modelsList: [],
    };
    case types.GET_ALL_MODELS_FAILURE: return {
      ...state,
      isLoading: false,
      modelsList: [],
    };
    case types.SAVE_SELECTED_MODELS_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.SAVE_SELECTED_MODELS_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.SAVE_SELECTED_MODELS_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_MODEL_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_MODEL_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_MODEL_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_MODEL_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_MODEL_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_MODEL_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_ACTIVES_LIST_REQUEST: return {
      ...state,
      isLoading: true,
      activesList: [],
      modelsList: [],
    };
    case types.GET_ACTIVES_LIST_SUCCESS: return {
      ...state,
      isLoading: false,
      activesList: action.payload.activesList,
    };
    case types.GET_ACTIVES_LIST_FAILURE: return {
      ...state,
      isLoading: false,
      activesList: [],
    };
    case types.SHOW_AVAILABLE_MODELS_REQUEST: return {
      ...state,
      isLoading: true,
      isGettingModelsLoading: true,
      modelsList: [],
      predictionResult: {},
    };
    case types.SHOW_AVAILABLE_MODELS_SUCCESS: return {
      ...state,
      isLoading: false,
      isGettingModelsLoading: false,
      modelsList: action.payload.modelsList,
    };
    case types.SHOW_AVAILABLE_MODELS_FAILURE: return {
      ...state,
      isGettingModelsLoading: false,
      isLoading: false,
      modelsList: [],
    };
    case types.SHOW_AVAILABLE_MODELS_1_REQUEST: return {
      ...state,
      isLoading: true,
      isGettingModelsLoading: true,
      modelsList1: [],
      predictionResult: {},
    };
    case types.SHOW_AVAILABLE_MODELS_1_SUCCESS: return {
      ...state,
      isLoading: false,
      isGettingModelsLoading: false,
      modelsList1: action.payload.modelsList,
    };
    case types.SHOW_AVAILABLE_MODELS_1_FAILURE: return {
      ...state,
      isGettingModelsLoading: false,
      isLoading: false,
      modelsList1: [],
    };
    case types.SHOW_ALL_MODELS_REQUEST: return {
      ...state,
      isGettingModelsLoading: true,
      isLoading: true,
      modelsList: [],
    };
    case types.SHOW_ALL_MODELS_SUCCESS: return {
      ...state,
      isLoading: false,
      isGettingModelsLoading: false,
      modelsList: action.payload.modelsList,
    };
    case types.SHOW_ALL_MODELS_FAILURE: return {
      ...state,
      isGettingModelsLoading: false,
      isLoading: false,
      modelsList: [],
    };
    case types.PREDICT_REQUEST: return {
      ...state,
      predictionResultLoading: true,
      predictionResult: {},
    };
    case types.PREDICT_SUCCESS: return {
      ...state,
      predictionResultLoading: false,
      predictionResult: action.payload.predictionResult,
    };
    case types.PREDICT_FAILURE: return {
      ...state,
      predictionResultLoading: false,
      predictionResult: {},
    };
    case types.GET_SUGGESTIVE_FORMULATIONS_RESULT_REQUEST: return {
      ...state,
      suggestiveFormulationsResultLoading: true,
      suggestiveFormulationsResult: {},
    };
    case types.GET_SUGGESTIVE_FORMULATIONS_RESULT_SUCCESS: return {
      ...state,
      suggestiveFormulationsResultLoading: false,
      suggestiveFormulationsResult: action.payload.suggestiveFormulationsResult,
    };
    case types.GET_SUGGESTIVE_FORMULATIONS_RESULT_FAILURE: return {
      ...state,
      suggestiveFormulationsResultLoading: false,
      suggestiveFormulationsResult: {},
    };
    case types.GET_MODEL_DATA_FROM_ID_REQUEST: return {
      ...state,
      isLoading: true,
      modelDataForPrediction: {},
      predictionResult: {},
      suggestiveFormulationsResult: [],
    };
    case types.GET_MODEL_DATA_FROM_ID_SUCCESS: return {
      ...state,
      isLoading: false,
      modelDataForPrediction: action.payload.modelData,
    };
    case types.GET_MODEL_DATA_FROM_ID_FAILURE: return {
      ...state,
      isLoading: false,
      modelDataForPrediction: {},
    };
    default: return state;
  }
};
