import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import UploadFileForm from '../../forms/UploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import {
  saveSelectedModels,
} from '../../services/Model/actions';
import DynamicModelSelectionForm from '../../forms/DynamicModelSelectionForm';
import isEmpty from 'lodash/isEmpty';
import { Loading } from '../../components/Loading';
import { submitUploadFile } from '../../services/File/actions';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  getUserAccessData,
} from '../../services/User/actions';
import '../../assets/stylesheet/upload-file.css';
import reckitt_logo from '../../assets/Asset_1.png';

const ShowModelsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(true);
  const [fileData, setFileData] = useState({});
  const [showModelSelection, setShowModelSelection] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const validationError = useSelector(state => state.File.validationError);
  const errorData = useSelector(state => state.File.errorData);
  const authEmail = useSelector((state) => state.Auth.email);
  const isLoading = useSelector((state) => state.Model.isLoading);
  const modelData = useSelector((state) => state.File.modelData);
  const email = useSelector((state) => state.Auth.email);
  const userData = useSelector((state) => state.User.userData);
  const userAccessLoading = useSelector((state) => state.User.isLoading);

  const handleUploadAndPreviewFile = (values) => {
    dispatch(
      submitUploadFile(values, () => {
        // File Validation Failed
        setDeleteModal(true);
      }, () => {
        setFileData(values);
        setShowModelSelection(true);
        // File Validated Successfully
        // history.push('/showModels');
      }),
    );
  };
  useEffect(() => {
    // recall if any of the parameters are changed
    dispatch(
      getUserAccessData({ email: email }, () => {
      })
    )
  }, [dispatch]);
  function hasDuplicates(arry) {
    // return uniq(a).length !== a.length;
    let resultToReturn = false;
    for (let i = 0; i < arry.length; i++) { // nested for loop
      for (let j = 0; j < arry.length; j++) {
        // prevents the element from comparing with itself
        if (i !== j) {
          // check if elements' values are equal
          if (arry[i] === arry[j]) {
            // duplicate element present                                
            resultToReturn = true;
            // terminate inner loop
            break;
          }
        }
      }
      // terminate outer loop                                                                      
      if (resultToReturn) {
        break;
      }
    }
  }

  const saveModels = (values) => {
    let doNotAccept = false;
    //Validations
    //All 3 arrays are less than or equal to length of selected (if not of same lenght, some data)
    if (isEmpty(values)) {
      doNotAccept = true;
    }
    if (doNotAccept === false && values && values.name && hasDuplicates(values.name)) {
      doNotAccept = true;
    }
    if (doNotAccept === false && values && values.narration && hasDuplicates(values.narration)) {
      doNotAccept = true;
    }
    if (doNotAccept === false && values) {
      if ("name" in values) {

      } else {
        doNotAccept = true;
        toast.error('Please enter name');
      }
      if ("narration" in values) {

      } else {
        doNotAccept = true;
        toast.error('Please enter narration');
      }
    }
    if (doNotAccept === false && values && values.name && values.narration && values.selected) {
      if (values.name.length === values.selected.length && values.narration.length === values.selected.length) {
        for (let i = 0; i < values.selected.length; i++) {
          if ((values.selected[i]) === true) {
            if (values.name[i] === undefined || values.name[i] === '') {
              doNotAccept = true;
            }
            if (values.narration[i] === undefined || values.narration[i] === '') {
              doNotAccept = true;
            }
          }
        }
      }
    }
    if (!doNotAccept) {
      dispatch(
        saveSelectedModels(values, modelData, authEmail, fileData, () => {
          // File Validated Successfully
          // if (validationError === true) {
          // setDeleteModal(true);
          history.push('/aerosol');
          // }
        }),
      );
    } else {
      toast.error('Please enter correct values');
    }
  };
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Upload File
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to="/aerosol">
            Aerosol
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to={{}}>
            Upload File
          </Link>
        </Breadcrumbs>
        <Paper sx={{ backgroundColor: '#F8EEF7', mt: 5, border: '1px solid #DFC2DC', boxShadow: 'none' }} className='input-bg'>
          <Box p={2}>
            {isLoading && (
              <Loading />
            )}
          </Box>
          <Box p={2}>
            {userAccessLoading ? (
              <Loading />
            ) : (
              <>
                {userData?.uploaddatafile === true ? (
                  <>
                    {showModelSelection ? (
                      <Box>
                        {!validationError ? (
                          <Grid item xs={12} alignItems="center">
                            <Box>
                              <DynamicModelSelectionForm fields={modelData} isLoading={isLoading} onSubmit={saveModels} />
                            </Box>
                          </Grid>
                        ) : (
                          <Box />
                        )}
                      </Box>
                    ) : (
                      <Box p={2}>
                        {uploadFile && (
                          <>
                            <UploadFileForm style={{ backgroundColor: 'red' }}
                              onSubmit={handleUploadAndPreviewFile}
                            />
                          </>
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ paddingTop: '15px' }}
                  >
                    <Typography variant="h5">
                      Access is denied. Please contact admin.
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Box>
          <Dialog
            maxWidth="md"
            fullWidth
            open={showDeleteModal}
            onClose={() => {
              if (typeof setDeleteModal === 'function') {
                setDeleteModal(false);
              }
            }}
          >
            <DialogTitle>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  This file cannot be used for building a model.
                </Box>
                <Box>
                  <IconButton onClick={() => {
                    if (typeof setDeleteModal === 'function') {
                      setDeleteModal(false);
                    }
                  }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box padding={2}>
                {!isEmpty(errorData) && validationError && (
                  <Stack spacing={2}>
                    <Alert severity="error">
                      {Object.keys(errorData).map((key, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={i}>
                          <Typography variant="h5" component="div">
                            {key}
                          </Typography>
                          {(errorData[key].map((media, ind) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={ind}>
                              <Typography variant="body1" component="p">
                                {errorData[key][ind]}
                              </Typography>
                            </div>
                          ))
                          )}
                        </Box>
                      ))}
                    </Alert>
                  </Stack>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (typeof setDeleteModal === 'function') {
                    setDeleteModal(false);
                  }
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </SideMenuAppWrapper>
  );
};

export default ShowModelsPage;
