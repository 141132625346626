import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Breadcrumbs,
  Grid,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import {
  deleteModel,
  showAllModels,
} from '../../services/Model/actions';
import { Loading } from '../../components/Loading';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import MUIDataTable from 'mui-datatables';
import '../../assets/stylesheet/landing-page.css';
import Typography from '@mui/material/Typography';
import {
  downloadFile,
} from '../../services/File/actions';
import {
  getUserAccessData,
} from '../../services/User/actions';
import DeleteModal from '../../components/modals/DeleteModal';
import reckitt_logo from '../../assets/Asset_1.png';

const PredictiveAnalysisPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setModal] = useState(false);
  // const [startAt, setStartAt] = useState(0);
  const email = useSelector((state) => state.Auth.email);
  const onDeleteAppointment = (values) => {
    dispatch(
      deleteModel(modalData, () => {
        dispatch(
          showAllModels(),
        );
      })
    )
  };
  useEffect(() => {
    // recall if any of the parameters are changed
    dispatch(
      getUserAccessData({ email: email }, () => {
        dispatch(
          showAllModels(),
        );
      })
    )
  }, [dispatch]);
  const isLoading = useSelector((state) => state.Model.isLoading);
  const modelsList = useSelector((state) => state.Model.modelsList);
  const userData = useSelector((state) => state.User.userData);
  const userAccessLoading = useSelector((state) => state.User.isLoading);

  const [value, setValue] = React.useState('');
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  //   showPredictiveAnalysisButton(true);
  // };

  const columns = [
    {
      name: '',
      label: '',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <Button
            onClick={() => {
              history.push(`/predictiveAnalysis/${modelsList[tableMeta.rowIndex].id}`);
              // setModalData(billList[tableMeta.rowIndex]); // set modal data to currently clicked row
            }}
          >
            Prediction
          </Button>
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: 'center' }}>
            {value}
          </div >
        ),
        // display: 'excluded',
      },
    },
    {
      name: 'narration',
      label: 'Narration',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },

        filter: true,
        sort: true,
        // display: 'excluded',
        customBodyRender: (value) => (
          <div style={{ textAlign: 'center' }}>
            {value}
          </div >
        ),
      },
    },
    {
      name: 'Actives',
      label: 'Actives',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },
        filter: true,
        sort: true,
        // display: 'excluded',
        customBodyRender: (value) => (
          <div style={{ textAlign: 'center' }}>
            {(value && Array.isArray(value)) && value.map((e) => (
              <div key={e}>
                {e}
              </div>
            ))}
          </div>
        ),
      },
    },
    // {
    //   name: 'createdBy',
    //   label: 'Created By',
    //   options: {
    //     customHeadRender: ({ index, ...column }) => {
    //       return (
    //         <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
    //           {column.label}
    //         </TableCell>
    //       )
    //     },
    //     customBodyRender: (value) => (
    //       <div style={{ textAlign: 'center' }}>
    //         {value}
    //       </div >
    //     ),
    //     filter: true,
    //     sort: true,
    //     // display: 'excluded',
    //   },
    // },
    // {
    //   name: 'createdAt',
    //   label: 'Created At',
    //   options: {
    //     customHeadRender: ({ index, ...column }) => {
    //       return (
    //         <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
    //           {column.label}
    //         </TableCell>
    //       )
    //     },
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => {
    //       if (value) {
    //         return (
    //           <>
    //             <div style={{ textAlign: 'center' }}>
    //               {new Date(value).toLocaleDateString("en-IN")}
    //             </div>
    //           </>
    //         )
    //       }
    //       return '';
    //     },
    //   },
    // },
    {
      name: 'filePath',
      label: 'File',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <>
            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => {
                dispatch(
                  downloadFile(value)
                )
              }
              }>
                Open corresponding file
              </Button>
            </div>
          </>

        ),
      },
    },
    {
      name: '',
      label: 'Delete',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return (
            <TableCell key={index} style={{ backgroundColor: '#E7D3E1', textAlign: 'center' }}>
              {column.label}
            </TableCell>
          )
        },
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <>
            <div style={{ textAlign: 'center' }}>
              <Button
                onClick={() => { setUpdateModal(false); setDeleteModal(true); setModalData(modelsList[tableMeta.rowIndex]) }}
              // onClick={() => {
              //   dispatch(
              //     deleteModel(modelsList[tableMeta.rowIndex], () => {
              //       dispatch(
              //         showAllModels(),
              //       );
              //     })
              //   )
              // }
              //}
              >
                Delete
              </Button>
            </div>
          </>

        ),
      },
    },
  ];

  // Options for Mui-Datatable
  const options = {
    // elevation: 0,
    // filterType: 'textField',
    // confirmFilters: true,
    setTableProps: () => {
      return {
        size: 'small',
      };
    },
    responsive: 'standard',
    serverSide: false,
    filter: true,
    viewColumns: true,
    selectableRows: 'none',
    download: true,
    search: true, // Disable search option
    enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
  };

  const handleSaveModelDataForPrediction = () => {
    // File Validated Successfully
    history.push(`/predictiveAnalysis/${modelsList[value].id}`);
  };

  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Show All Models
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to="/aerosol">
            Aerosol
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to={{}}>
            Show All Models
          </Link>
        </Breadcrumbs>
        {isLoading || userAccessLoading ? (
          <Loading />
        ) : (
          <>
            {userData?.showallmodels === true ? (
              <MUIDataTable
                data={modelsList}
                columns={columns}
                options={options}
              />
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ paddingTop: '15px' }}
              >
                <Typography variant="h5">
                  Access is denied. Please contact admin.
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Box>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setModalData={setModalData}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteAppointment}
        setUpdateModal={setUpdateModal}
        modalData={modalData}
      />
    </SideMenuAppWrapper>
  );
};

export default PredictiveAnalysisPage;
