import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Input from '../components/Input';
// import './spreadsheet.css';
// import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import DataModelDisplay from '../components/DataModelDisplay';
import CheckboxInput from '../components/CheckboxInput';

let DynamicModelSelectionForm = (props) => {
  const {
    handleSubmit,
    submitting,
    fields,
    isLoading,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={submitting || isLoading}
            className="mt-3"
            fullWidth
          >
            SAVE SELECTED MODELS
          </Button>
        </Grid>
        <div style={{ overflow: 'auto', height: '80vh' }}>
          <Grid item xs={12} md={12}>
            {fields && fields.map((member, index) => {
              return (
                <Paper
                  key={index}
                  style={{ border: '1px solid grey' }}
                  sx={{ padding: '20px', margin: '20px' }}
                >
                  <Grid container key={`${member}${index + 1}`}>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name={`name.${index}`}
                        type="select"
                        label="Model Name"
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Input}
                        name={`narration.${index}`}
                        type="select"
                        label="Model Narration"
                        disabled={submitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name={`selected.${index}`}
                        type="checkbox"
                        component={CheckboxInput}
                        label="Check to save this model"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataModelDisplay modelData={member} nameAvailable={false} />
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </div>
      </Grid>
    </form>
  );
};

DynamicModelSelectionForm = reduxForm({
  form: 'SELECT_MODELS_FORM',
})(DynamicModelSelectionForm);

export default DynamicModelSelectionForm;
