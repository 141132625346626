import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  exchangeRateList: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_EXCHANGE_RATE_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.ADD_EXCHANGE_RATE_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.ADD_EXCHANGE_RATE_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_EXCHANGE_RATES_REQUEST: return {
      ...state,
      isLoading: true,
      exchangeRateList: [],
    };
    case types.GET_EXCHANGE_RATES_SUCCESS: return {
      ...state,
      isLoading: false,
      exchangeRateList: action.payload.dataList,
    };
    case types.GET_EXCHANGE_RATES_FAILURE: return {
      ...state,
      isLoading: false,
      exchangeRateList: [],
    };
    case types.EDIT_EXCHANGE_RATE_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_EXCHANGE_RATE_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_EXCHANGE_RATE_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_EXCHANGE_RATE_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_EXCHANGE_RATE_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_EXCHANGE_RATE_FAILURE: return {
      ...state,
      isLoading: false,
    };
    default: return state;
  }
};
