import * as types from '../types';

export const getRegulatoryLimits = () => ({
  type: types.GET_REGULATORY_LIMITS_REQUEST,
});

export const addNewRegulatoryLimit = (values, onSuccess) => ({
  type: types.ADD_REGULATORY_LIMIT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const editRegulatoryLimit = (values, onSuccess) => ({
  type: types.EDIT_REGULATORY_LIMIT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteRegulatoryLimit = (values, onSuccess) => ({
  type: types.DELETE_REGULATORY_LIMIT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});
