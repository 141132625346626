import { takeEvery, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import API from '../../api';
import * as types from '../types';

function* addNewProject(action) {
  try {
    const response = yield call(
      API.addNewProject,
      action.payload.values,
    );
    yield put({
      type: types.ADD_PROJECT_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.ADD_PROJECT_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* getProjects(action) {
  try {
    const response = yield call(
      API.getProjects,
    );
    // Convert response data list into appropriate form for dynamicForm Input
    // Sort elements for dynamicFormInput
    // for (let i = 0; i < arrayObj.length; i += 1) {
    //   arrayObj[i].dishes = arrayObj[i].Dishes.map(({ id, name, Order_Dish }) => ({ quantity: Order_Dish.quantity, name: id, dish:name }));
    //   delete arrayObj[i].Dishes;
    // }
    yield put({
      type: types.GET_PROJECTS_SUCCESS,
      payload: {
        dataList: response.data.data,
      },
    });
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_PROJECTS_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* editProject(action) {
  try {
    const response = yield call(
      API.editProject,
      action.payload.values,
    );
    yield put({
      type: types.EDIT_PROJECT_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.EDIT_PROJECT_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* deleteProject(action) {
  try {
    const response = yield call(
      API.deleteProject,
      action.payload.values,
    );
    yield put({
      type: types.DELETE_PROJECT_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.DELETE_PROJECT_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}
// eslint-disable-next-line func-names
export default function* () {
  yield takeEvery(types.ADD_PROJECT_REQUEST, addNewProject);
  yield takeEvery(types.GET_PROJECTS_REQUEST, getProjects);
  yield takeEvery(types.EDIT_PROJECT_REQUEST, editProject);
  yield takeEvery(types.DELETE_PROJECT_REQUEST, deleteProject);
}
