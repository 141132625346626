import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const RenderField = ({
  input,
  placeholder,
  defaultValue,
  type,
  label,
  name,
  disabled,
  required,
  meta: { touched, error, warning },
  min,
  max,
  significantkt50,
  significantm24,
}) => {
  useEffect(() => {
    if (defaultValue) {
      input.onChange(defaultValue);
    }
  }, [defaultValue])
  return (
    <>
      <Box>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
            <Typography color="initial">
              {label}
            </Typography>
            {significantkt50 && (
              <Chip
                color="error"
                label={"K"}
              />
            )}
            {significantm24 && (
              <Chip
                color="warning"
                label={"M"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input}
              // label={label}
              type={type}
              id={name}
              variant="standard"
              margin="normal"
              fullWidth
              size="small"
              placeholder={placeholder || `Enter ${label}`}
              disabled={disabled}
              required={required}
              autoComplete="off"
              error={(touched && !!error)}
              helperText={touched && (error || warning)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {max && (
              <div>
                H
                :
                {max}
              </div>
            )}
            {min && (
              <div>
                L
                :
                {min}
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))} */}
    </>
  );
};

export default RenderField;
