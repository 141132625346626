import React from 'react';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../assets/stylesheet/upload-file.css';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from '@mui/material';

const renderField = ({
  input: { onChange },
  label,
  name,
  resetFileSelection,
}) => (
  <>
    <Box my={2}>
      <Typography component="h2" color="initial">
        {label}
      </Typography>
      <label style={{ background: '#fff', border: '1px solid #CECECE', padding: '20px 60px' }}>
        <Button variant="contained" component="span" startIcon={<FileUploadIcon />} style={{ textTransform: 'capitalize', backgroundColor: '#FF4D82' }}>
          Upload
        </Button >
        <input type="file" name={name} style={{ borderBottom: '1px solid #ACACAC', marginLeft: '30px', width: '500px' }}
          id={name} multiple className="choose" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(event) => {
            const { files } = event.target;
            onChange(files);
            resetFileSelection(true);
          }}></input>
      </label>
    </Box>
  </>
);

export default renderField;
