import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  isAuthenticated: false,
  name: '',
  email: '',
  preferred_username: '',
  isAuth: false,
  error: null,
  status: null,
  role: '',
  errorCode: '',
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.LOGIN_REQUEST: return {
      ...state,
      isLoading: true,
      isAuthenticated: false,
    };
    case types.LOGIN_SUCCESS: return {
      ...state,
      isLoading: false,
      isAuthenticated: true,
      name: action.payload.name,
      email: action.payload.preferred_username,
      preferred_username: action.payload.preferred_username,
      isAuth: true,
      error: null,
      status: null,
      role: action.payload.role,
      errorCode: action.payload.errorCode,
    };
    case types.LOGIN_FAILURE: return {
      ...state,
      isLoading: false,
      isAuthenticated: false,
      name: "",
      email: "",
      preferred_username: "",
      isAuth: false,
      error: "UNAUTHORIZED",
      errCode: '',
    };
    case types.LOGOUT_REQUEST: return {
      ...state,
      isLoading: true,
      isAuthenticated: false,
    };
    case types.LOGOUT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.LOGOUT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.CHECK_USER_SESSION: return {
      ...state,
      isLoading: true,
      isAuthenticated: true,
    };
    default: return state;
  }
};
