import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory, Link } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateUserModal from '../../components/createButtonModals/User';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  editUser,
  deleteUser,
  getUsers,
  addNewUser,
  getUserAccessData,
} from '../../services/User/actions';
import UserForm from '../../forms/User';
import DeleteModal from '../../components/modals/DeleteModal';
import { Loading } from '../../components/Loading';
import reckitt_logo from '../../assets/Asset_1.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '80px',
  // overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '180px',
  overflowWrap: 'anywhere',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F5F5',
  },
}));

const UsersPage = () => {
  const dispatch = useDispatch();
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setModal] = useState(false);
  const onEditAppointment = (values) => {
    dispatch(
      editUser(values, () => {
        dispatch(
          getUsers(),
        );
      }),
    );
  };

  const onSubmitAppointment = (values) => {
    dispatch(
      addNewUser(values, () => {
        setModal(false);
        dispatch(
          getUsers()
        )
      }),
    );
  };

  const onDeleteAppointment = (values) => {
    dispatch(
      deleteUser(values, () => {
        dispatch(
          getUsers(),
        );
      }),
    );
  };
  const email = useSelector((state) => state.Auth.email);

  useEffect(() => {
    dispatch(
      getUserAccessData({ email: email }, () => {
        dispatch(
          getUsers()
        )
      })
    )
  }, [dispatch]);
  const userList = useSelector((state) => state.User.userList);
  const isLoading = useSelector((state) => state.User.isLoading);
  const userData = useSelector((state) => state.User.userData);
  let treatmentCounter = userList.length;
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            User Access
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            User Management
          </Link>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        {isLoading ? (
          <Loading />
        ) : (
          <Box>
            {userData?.users === true ? (
              <Box p={2}>
                <CreateUserModal
                  handleSubmit={onSubmitAppointment}
                  size="sm"
                  buttonText="CREATE NEW USER"
                  modalHeaderText="Add USER"
                  withoutClient
                  setModal={setModal}
                  showModal={showModal}
                />
                <Box pt={10}>
                  <Grid container>
                    {(isLoading) ? (
                      <Grid item xs={12} alignItems="center">
                        <Loading />
                      </Grid>
                    ) : (
                      <Grid item xs={12} alignItems="center">
                        <TableContainer sx={{ display: 'block', overflowX: 'auto' }}>
                          <Table size="small" aria-label="customized table" sx={{ display: 'block', overflowX: 'auto' }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center" padding="none" />
                                <StyledTableCell align="center" padding="none">#</StyledTableCell>
                                <StyledTableFixedWithCell padding="none" align="center">Email</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Projects</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">User Mgmt</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Master Data</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Exchange Rate</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upload Data File</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Show All Models</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Regulatory Limit</StyledTableFixedWithCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userList.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell padding="none" align="center" component="th" scope="row">
                                    <EditIcon
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                      onClick={() => {
                                        setModalData(row); // set modal data to currently clicked row
                                        setUpdateModal(true);
                                        // handleChange(data[tableMeta.rowIndex]);
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <>
                                      {treatmentCounter--}
                                    </>
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.email && row.email}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.projects === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.users === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.masterdata === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.exchangerate === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.showallmodels === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.uploaddatafile === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.regulatorylimit === true && (
                                      <div>
                                        <CheckCircleIcon />
                                      </div>
                                    )}                          </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ paddingTop: '15px' }}
              >
                <Typography variant="h5">
                  Access is denied. Please contact admin.
                </Typography>
              </Grid>
            )}
          </Box>
        )}
      </Box>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showUpdateModal}
        onClose={() => { setUpdateModal(false); setModalData({}); }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Button
                color="error"
                variant="contained"
                onClick={() => { setUpdateModal(false); setDeleteModal(true); }}
              >
                Delete
              </Button>
            </Box>
            <Box>
              <IconButton onClick={() => { setUpdateModal(false); setDeleteModal(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UserForm
            initialValues={modalData}
            onSubmit={onEditAppointment}
          />
        </DialogContent>
      </Dialog>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setModalData={setModalData}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteAppointment}
        setUpdateModal={setUpdateModal}
        modalData={modalData}
      />
    </SideMenuAppWrapper>
  );
};

export default UsersPage;
