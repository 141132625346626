import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  userList: [],
  userData: {},
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_USER_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.ADD_USER_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.ADD_USER_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_USERS_REQUEST: return {
      ...state,
      isLoading: true,
      userList: [],
    };
    case types.GET_USERS_SUCCESS: return {
      ...state,
      isLoading: false,
      userList: action.payload.dataList,
    };
    case types.GET_USERS_FAILURE: return {
      ...state,
      isLoading: false,
      userList: [],
    };
    case types.EDIT_USER_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_USER_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_USER_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_USER_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_USER_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_USER_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_USER_ACCESS_DATA_REQUEST: return {
      ...state,
      isLoading: true,
      userData: {}
    };
    case types.GET_USER_ACCESS_DATA_SUCCESS: return {
      ...state,
      isLoading: false,
      userData: action.payload.data,
    };
    case types.GET_USER_ACCESS_DATA_FAILURE: return {
      ...state,
      isLoading: false,
      userData: {},
    };
    default: return state;
  }
};
