import axios from 'axios';
import config from '../../config';

// Plant
const getRegulatoryLimits = () => axios.get(
  `${config.BACKEND_API}/v1/regulatorylimits`,
);

const addNewRegulatoryLimit = (values) => axios.post(
  `${config.BACKEND_API}/v1/regulatorylimits`,
  values,
);

const editRegulatoryLimit = (values) => axios.put(
  `${config.BACKEND_API}/v1/regulatorylimits`,
  values,
);

const deleteRegulatoryLimit = (values) => axios.delete(
  `${config.BACKEND_API}/v1/regulatorylimits`,
  { data: values },
);


export default {
  getRegulatoryLimits,
  addNewRegulatoryLimit,
  editRegulatoryLimit,
  deleteRegulatoryLimit,
};
