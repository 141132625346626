import isEmpty from 'lodash/isEmpty';

const returnObjectFromArray = (array, value) => {
  return array.find(x => x.Name === value);
};
// Validation for all input elements
export const dynamicValidator = (value, allValues, props, name) => {
  if (!allValues) {
    return 'Enter Values';
  }
  if (name === 'Active 1%'
    || name === 'Active 2%'
    || name === 'Active 3%'
    || name === 'Solvent %'
    || name === 'Spray Rate'
    || name === 'Spray duration'
    || name === 'Average Droplet Size'
    || name === 'Dose Concentration'
    || name === 'Insect Insertion time'
    || name === 'Temperature'
    || name === 'Humidity'
    || name === 'Age of Insects'
    || name === 'Gas pressure'
  ) {
    if (allValues[name] < returnObjectFromArray(props.modelData.KT50.Numerical, name)?.Min || allValues[name] < returnObjectFromArray(props.modelData.M24.Numerical, name)?.Min) {
      return 'Value should be more than min';
    }
    if (allValues[name] > returnObjectFromArray(props.modelData.KT50.Numerical, name)?.Max || allValues[name] > returnObjectFromArray(props.modelData.M24.Numerical, name)?.Max) {
      return 'Value should be less than max';
    }
  }
  if (name === 'Insect Tested') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Insect Tested')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Insect Tested')?.Significant) {
      if (isEmpty(allValues['Insect Tested'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'formula base') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'formula base')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'formula base')?.Significant) {
      if (isEmpty(allValues['formula base'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Ratio Bulk/Propellant') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Ratio Bulk/Propellant')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Ratio Bulk/Propellant')?.Significant) {
      if (isEmpty(allValues['Ratio Bulk/Propellant'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Gas Type') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Gas Type')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Gas Type')?.Significant) {
      if (isEmpty(allValues['Gas Type'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Solvent type') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Solvent type')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Solvent type')?.Significant) {
      if (isEmpty(allValues['Solvent type'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Valve Orifice dimensions') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Valve Orifice dimensions')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Valve Orifice dimensions')?.Significant) {
      if (isEmpty(allValues['Valve Orifice dimensions'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Housing Orifice') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Housing Orifice')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Housing Orifice')?.Significant) {
      if (isEmpty(allValues['Housing Orifice'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Spray pattern') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Spray pattern')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Spray pattern')?.Significant) {
      if (isEmpty(allValues['Spray pattern'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Cone pattern') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Cone pattern')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Cone pattern')?.Significant) {
      if (isEmpty(allValues['Cone pattern'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Vapour Tap') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Vapour Tap')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Vapour Tap')?.Significant) {
      if (isEmpty(allValues['Vapour Tap'])) {
        return 'Value Required';
      }
    }
  }
  if (name === 'Protocol') {
    if (returnObjectFromArray(props.modelData.KT50.Categorical, 'Protocol')?.Significant || returnObjectFromArray(props.modelData.M24.Categorical, 'Protocol')?.Significant) {
      if (isEmpty(allValues['Protocol'])) {
        return 'Value Required';
      }
    }
  }
  return undefined;
};
