import * as React from 'react';
import {
  ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject,
  DataLabel, BarSeries, Category, Legend, Tooltip,
} from '@syncfusion/ej2-react-charts';

// ********** NOTE ***********
// Vertical X axis is X axis,
// Horizontal is Y axis
const BarPlot = ({ graphData }) => {
  const maxAbsoluteX = graphData.reduce(function (prev, current) {
    return (Math.abs(prev.x) > Math.abs(current.x)) ? prev : current
  });
  const res2 = graphData.reduce(function (prev, current) {
    return (prev.y > current.y) ? prev : current
  });
  return (
    <ChartComponent
      style={{ textAlign: 'center' }}
      palettes={['#0096FF', '#F6B53F', '#6FAAB0', '#C4C24A']}
      primaryXAxis={{
        valueType: 'Category',
        title: 'Coefficients',
        // majorGridLines: { width: 0 },
        // minimum: -100,
        // maximum: 100,
        // crossesAt: 0,
        minimum: -1,
        maximum: res2.y + 1,
        majorGridLines: { width: 2 },
        majorTickLines: { width: 2 },
        labelFormat: '{value}',
      }}
      primaryYAxis={{
        minimum: -Math.abs(maxAbsoluteX.x),
        maximum: Math.abs(maxAbsoluteX.x),
        title: 'Features',
        labelFormat: '{value}',
        // edgeLabelPlacement: 'Shift',
        majorGridLines: { width: 2 },
        majorTickLines: { width: 2 },
        // lineStyle: { width: 0 },
        labelStyle: {
          color: 'transparent',
        },
      }}
      legendSettings={{ visible: true }}
      chartArea={{ border: { width: 1 } }}
      title=""
      tooltip={{ enable: true }}
    >
      <Inject services={[BarSeries, DataLabel, Category, Legend, Tooltip]} />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={graphData}
          xName="ylabel"
          yName="x"
          type="Bar"
          tooltipMappingName="ylabel"
          name="Tornado Chart"
          width={2}
          marker={{
            dataLabel: {
              visible: false,
              position: 'Top',
              font: {
                fontWeight: '800', color: 'blue',
              },
            },
          }}
        />
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default BarPlot;
