import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  masterDataList: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_MASTER_DATA_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.ADD_MASTER_DATA_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.ADD_MASTER_DATA_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_MASTER_DATA_REQUEST: return {
      ...state,
      isLoading: true,
      masterDataList: [],
    };
    case types.GET_MASTER_DATA_SUCCESS: return {
      ...state,
      isLoading: false,
      masterDataList: action.payload.dataList,
    };
    case types.GET_MASTER_DATA_FAILURE: return {
      ...state,
      isLoading: false,
      masterDataList: [],
    };
    case types.EDIT_MASTER_DATA_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_MASTER_DATA_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_MASTER_DATA_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_MASTER_DATA_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_MASTER_DATA_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_MASTER_DATA_FAILURE: return {
      ...state,
      isLoading: false,
    };
    default: return state;
  }
};
