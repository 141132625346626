import * as React from 'react';
import { ChartComponent, LineSeries, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DataLabel } from '@syncfusion/ej2-react-charts';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// const tempData = [{ x: -100, y: 0 }, { x: 100, y: 0 }];
const LinePlot = ({ suggestiveFormulationsResult, checked }) => {
  const asdtemp = [];
  const asdtemp1 = [];
  for (let j = 0; j < checked.length; j++) {
    for (let i = 0; i < suggestiveFormulationsResult.length; i++) {
      if (checked[j] === suggestiveFormulationsResult[i]?.id) {
        asdtemp.push(suggestiveFormulationsResult[i]['SuggestedData']['KT']);
        asdtemp1.push(suggestiveFormulationsResult[i].name);
      }
    }
  }
  // const res1 = graphData.reduce(function (prev, current) {
  //   return (prev.x < current.x) ? prev : current
  // });
  // const res2 = graphData.reduce(function (prev, current) {
  //   return (prev.x > current.x) ? prev : current
  // });
  // const tempData = [{ x: res1.x, y: 0 }, { x: res2.x, y: 0 }];
  return (
    <>
      <ChartComponent
        id='charts'
        tooltip={{ enable: true }}
        style={{ textAlign: 'center', width: '100%' }}
        marker={{ dataLabel: { visible: true, shared: true } }}
        isTransposed={true}
        legendSettings={{ visible: true }}
        primaryXAxis={{
          // minimum: 100,
          // maximum: 220,
          // majorGridLines: { width: 0 },
          // edgeLabelPlacement: 'Shift',
          valueType: 'Category',
        }}
        primaryYAxis={{
          minimum: 0,
          maximum: 60,
        }}
      >
        <Inject services={[LineSeries, DataLabel, Tooltip, Category, Legend]} />
        <SeriesCollectionDirective>
          {asdtemp.length > 0 ? (
            asdtemp.map((rowData, index) => (
              <SeriesDirective
                key={rowData}
                name={asdtemp1[index]}
                dataSource={rowData}
                xName='y'
                yName='x'
                type='Line'
                marker={{ visible: true, width: 12, height: 12, shape: 'Circle', color: '#0000FF' }}
              />))
          ) : (
            <SeriesDirective
              dataSource={[]}
              xName='y'
              yName='x'
              type='Line'
              marker={{ visible: true, width: 12, height: 12, shape: 'Circle', color: '#0000FF' }}
            />
          )}
        </SeriesCollectionDirective>
      </ChartComponent>
    </>
  );
};

export default LinePlot;
