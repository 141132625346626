import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemIcon from '@mui/material/ListItemIcon';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsIcon from '@mui/icons-material/Settings';
import { useHistory } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { logout } from '../services/Auth/actions';
import logo from '../assets/Asset_1.png';
import logo1 from '../assets/MicrosoftTeams-image (2).png';
import { makeStyles } from '@mui/styles';
import '../assets/stylesheet/sidebar_style.css';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-center',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: 'transparent',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      overflow: 'hidden !important',
      position: 'relative',
      whiteSpace: 'nowrap',
      display: 'contents',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        display: 'contents',
        overflow: 'hidden !important',
        overflowX: 'hidden',
        overflowY: 'none',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export default function MiniDrawer() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
  const history = useHistory();
  const handleLogout = () => {
    dispatch(logout(() => {
      history.push('/');
    }));
  };
  const onLogout = () => {
    dispatch(
      logout(() => {
        history.push('/');
      }),
    );
  };
  const handleClick1 = () => {
    setAnchorEl(divRef.current);
    setOpen1(!open1);
    setOpen(true);
  };
  const handleClick2 = () => {
    setAnchorEl(divRef.current);
    setOpen2(!open2);
    setOpen(true);
  };
  const handleDrawerToggle = () => {
    setOpen(!open);
    setOpen1(false);
  };

  const text = {
    color: "white"
  };

  const styles = {
    root: {

    }
  };

  const useStyles = makeStyles({
    active: {
      background: '#b90c70'
    },
  });

  const classes = useStyles()
  return (
    <>
      {isAuthenticated ? (
        <div
          className="custom-header"
          style={{
            background: 'rgb(238,78,84)',
            background: 'linear-gradient(180deg, rgba(238,78,84,1) 0%, rgba(238,78,84,1) 100%)'
          }}>
          <div>
            <CssBaseline />
            <AppBar
              position="absolute"
              open={open}
              elevation={0}
            >
              <Toolbar
                sx={{
                  pr: '24px', // keep right padding when drawer closed
                }}
              >
                <Box display="flex" flexGrow={1} />
              </Toolbar>
            </AppBar>
            <Drawer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                background: 'blue'
              }}
              anchor="left"
              variant="permanent"
              open={open}
            >
              {/* <DrawerHeader className='logo-background' onClick={() => { history.push('/home'); }}>
                <IconButton onClick={handleDrawerToggle}>
                  {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </DrawerHeader> */}
              <Divider />
              <List className='sidebar-gradient'>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  {open ? (
                    <ListItemButton sx={{ pl: 4 }} onClick={() => { history.push('/home'); }}>
                      <img
                        style={{
                          width: '130px', margin: '0 auto', padding: '10px', cursor: 'pointer',
                        }}
                        src={logo1}
                        alt="logo"
                      />
                    </ListItemButton>
                  ) : (
                    <ListItemButton onClick={() => { history.push('/home'); }}>
                      <ListItemText primary="RB" />
                    </ListItemButton>
                  )}
                </Box>
                <ListItemButton style={{ color: '#fff' }} key="1" onClick={handleClick1} >
                  <ListItemIcon>
                    {/* <ArrowForwardIcon className='sidebar-icons' /> */}
                    {open1 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: text }} primary="Aerosol" />
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit >
                  <List component="div" disablePadding>
                    <ListItemButton
                      className={classes.active} onClick={() => { history.push('/aerosol'); }}>
                      <ListItemIcon>
                        <HomeOutlinedIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Home" />
                    </ListItemButton>
                    <ListItemButton style={{ color: '#fff' }} key="2" onClick={() => { history.push('/showModels'); }} >
                      <ListItemIcon>
                        <UploadFileIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Upload Data File" />
                    </ListItemButton>
                    <ListItemButton style={{ color: '#fff' }} key="2" onClick={() => { history.push('/models/all'); }} >
                      <ListItemIcon>
                        <ListIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Show All Models" />
                    </ListItemButton>
                    <ListItemButton style={{ color: '#fff' }} key="2" onClick={handleClick2} >
                      <ListItemIcon>
                        <AttachMoneyIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Cost" />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit >
                      <List component="div" disablePadding>
                        <ListItemButton style={{ color: '#fff' }} key="11" onClick={() => { history.push('/cost/exchangeRate'); }} >
                          <ListItemText primaryTypographyProps={{ style: text }} primary="Exchange Rate" />
                        </ListItemButton>
                        <ListItemButton style={{ color: '#fff' }} key="12" onClick={() => { history.push('/cost/masterdata'); }} >
                          <ListItemText primaryTypographyProps={{ style: text }} primary="Master Data" />
                        </ListItemButton>
                        <Divider />
                      </List>
                    </Collapse>
                    <ListItemButton style={{ color: '#fff' }} key="13" onClick={() => { history.push('/projects'); }} >
                      <ListItemIcon>
                        <DashboardIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Projects" />
                    </ListItemButton>
                    <ListItemButton style={{ color: '#fff' }} key="13" onClick={() => { history.push('/regulatorylimits'); }} >
                      <ListItemIcon>
                        <DashboardIcon className='sidebar-icons' />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ style: text }} primary="Regulatory Limits" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton disabled onClick={() => { history.push('/models/actives'); }}>
                  {/* <ListItemIcon>
                    <ArrowForwardIcon className='sidebar-icons' />
                  </ListItemIcon> */}
                  <ListItemText primary="Liquid Vaporiser" />
                </ListItemButton>
                <ListItemButton disabled onClick={() => { history.push('/models/actives'); }}>
                  {/* <ListItemIcon>
                    <ArrowForwardIcon className='sidebar-icons' />
                  </ListItemIcon> */}
                  <ListItemText primary="Coil" />
                </ListItemButton>
                <ListItemButton
                  disabled
                  onClick={() => { history.push('/models/actives'); }}
                >
                  {/* <ListItemIcon>
                    <ArrowForwardIcon className='sidebar-icons' />
                  </ListItemIcon> */}
                  <ListItemText primary="Personal Insect Repellant" />
                </ListItemButton>

                <ListItemButton style={{ color: '#fff' }} key="14" onClick={() => { history.push('/users'); }} >
                  <ListItemIcon>
                    <SettingsIcon className='sidebar-icons' />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: text }} primary="User Management" />
                </ListItemButton>
                {/* <ListItemButton key="15" onClick={() => { onLogout(); }}>
                  <ListItemIcon>
                    <LogoutIcon className='sidebar-icons' />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: text }} primary="Logout" />
                </ListItemButton> */}
                {/* <Divider />
            <ListItemButton key="4" onClick={() => { history.push('/models/all'); }}>
              <ListItemIcon>
                <ModelTrainingIcon />
              </ListItemIcon>
              <ListItemText primary="View all models" />
            </ListItemButton> */}
              </List>
            </Drawer>
          </div>
        </div>
      ) : (
        <>
        </>
      )
      }
    </>
  );
}
