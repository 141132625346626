import * as types from '../types';

export const getProjects = () => ({
  type: types.GET_PROJECTS_REQUEST,
});

export const addNewProject = (values, onSuccess) => ({
  type: types.ADD_PROJECT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const editProject = (values, onSuccess) => ({
  type: types.EDIT_PROJECT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteProject = (values, onSuccess) => ({
  type: types.DELETE_PROJECT_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});
