import axios from 'axios';
import config from '../../config';

const recover = () => axios.post(
  `${config.AUTH_URL}`,
  {},
);

export default {
  recover
};