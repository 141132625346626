import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
// import from './components/RouteRequiresAuth';
import requireNoAuth from './components/RouteRequiresNoAuth';
import requireAuth from './components/RouteRequiresAuth';

import Wrapper from './components/AppWrapper';

// Pages
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';
import Home from './pages/Home';
import Aerosol from './pages/Aerosol';
import FormulaOptimisation from './pages/FormulaOptimisation';
import PredictiveAnalysis from './pages/PredictiveAnalysis';
import ShowModelsActives from './pages/ShowModelsActives';
import ShowModelsAll from './pages/ShowModelsAll';
import ShowModelsFromFile from './pages/ShowModelsFromFile';
import Users from './pages/Users';
import Projects from './pages/Projects';
import RegulatoryLimits from './pages/RegulatoryLimits';
import MasterData from './pages/MasterData';
import ExchangeRatePage from './pages/ExchangeRate';

class Routes extends Component {
  render() {
    return (
      <Wrapper>
        <Switch>
          <Route exact path="/unauthorized" component={requireNoAuth(Home)} />
          <Route exact path="/" component={requireAuth(Home)} />
          {/* <Route exact path="/login" component={Login} /> */}
          <Route exact path="/home" component={requireAuth(Home)} />
          <Route exact path="/aerosol" component={requireAuth(Aerosol)} />
          {/* <Route exact path="/aerosol" component={Aerosol} /> */}
          <Route exact path="/showModels" component={requireAuth(ShowModelsFromFile)} />
          <Route exact path="/formulaOptimisation" component={requireAuth(FormulaOptimisation)} />
          <Route exact path="/models/all" component={requireAuth(ShowModelsAll)} />
          <Route exact path="/models/actives" component={requireAuth(ShowModelsActives)} />
          <Route exact path="/predictiveAnalysis/:modelId" component={requireAuth(PredictiveAnalysis)} />
          <Route exact path="/users" component={requireAuth(Users)} />
          <Route exact path="/projects" component={requireAuth(Projects)} />
          <Route exact path="/regulatorylimits" component={requireAuth(RegulatoryLimits)} />
          <Route exact path="/cost/masterdata" component={requireAuth(MasterData)} />
          <Route exact path="/cost/exchangeRate" component={requireAuth(ExchangeRatePage)} />
          <Route component={PageNotFound} />
        </Switch>
      </Wrapper>
    );
  }
}

export default Routes;
