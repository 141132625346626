import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {
  Breadcrumbs, Divider, TableCell, Button
} from '@mui/material';
import Chip from '@mui/material/Chip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { isEmpty } from 'lodash';
import { Loading } from '../../components/Loading';
import {
  getPrediction, getModelDataFromId,
} from '../../services/Model/actions';
import PredictiveAnalysisForm from '../../forms/PredictiveAnalysis';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import LinePlot from '../../components/Charts/LinePlot';
import DoneIcon from '@mui/icons-material/Done';
import CostingForm from '../../forms/DynamicCostSelectionForm';
import PrintIcon from '@mui/icons-material/Print';
import Select from 'react-select';
import {
  getMasterData,
} from '../../services/MasterData/actions';
import {
  getProjects,
} from '../../services/Project/actions';
import {
  getExchangeRates,
} from '../../services/ExchangeRate/actions';
import '../../assets/stylesheet/landing-page.css';
import '../../assets/stylesheet/kpi-pred.css';
import reckitt_logo from '../../assets/Asset_1.png';

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container>
          <Box>
            {children}
          </Box>
        </Container>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PredictiveAnalysisPage = (props) => {
  const dispatch = useDispatch();
  const modelDataForPrediction = useSelector((state) => state.Model.modelDataForPrediction);
  const isLoading = useSelector((state) => state.Model.isLoading);
  const predictionResultLoading = useSelector((state) => state.Model.predictionResultLoading);
  const predictionResult = useSelector((state) => state.Model.predictionResult);
  const [showPredictionResult, setShowPredictionResult] = useState(false);
  const exchangeRateList = useSelector((state) => state.ExchangeRate.exchangeRateList);
  const [value, setValue] = React.useState('');
  const [value1, setValue1] = React.useState(0);
  const [active1Data, setActive1Data] = useState('');
  const [showSignificant, setShowSignificant] = useState(true);
  const [projectData, setProjectData] = useState('');
  const [projectData1, setProjectData1] = useState({});
  const projectList = useSelector((state) => state.Project.projectList);
  const [tempValue1, setTempValue1] = useState({});
  const [formSubmittedValues, setFormSubmittedValues] = useState({});
  const masterDataList = useSelector((state) => state.MasterData.masterDataList);

  const [active1PurityPercentage, setActive1PurityPercentage] = React.useState(null);
  const [active2PurityPercentage, setActive2PurityPercentage] = React.useState(null);
  const [active3PurityPercentage, setActive3PurityPercentage] = React.useState(null);

  const [active1PurityPercentageList, setActive1PurityPercentageList] = React.useState([]);
  const [active2PurityPercentageList, setActive2PurityPercentageList] = React.useState([]);
  const [active3PurityPercentageList, setActive3PurityPercentageList] = React.useState([]);

  let totalActiveCostForProject = 0;
  let totalActiveCostForProject2 = 0;

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    // masterDataList.filter((value) => value.rawmmaterial === newValue)
  };
  const handleProjectChange = (newValue) => {
    setProjectData(newValue);
    for (let j = 0; j < projectList.length; j++) {
      if (projectList[j].id === newValue.value) {
        setProjectData1(projectList[j]);
      }
    }
  };

  const handleCostingSubmit = (values) => {

  };

  const {
    match: {
      params: {
        modelId,
      },
    },
  } = props;
  useEffect(() => {
    // recall if any of the parameters are changed
    dispatch(
      getModelDataFromId(modelId),
    );
    dispatch(
      getMasterData()
    )
  }, [dispatch]);
  useEffect(() => {
    // recall if any of the parameters are changed
    if (modelDataForPrediction && !isEmpty(modelDataForPrediction)) {
      returnMatchingForActivesWithMultiplePurityPercentage(masterDataList, modelDataForPrediction?.Actives[0], 1);
      returnMatchingForActivesWithMultiplePurityPercentage(masterDataList, modelDataForPrediction?.Actives[1], 2);
      returnMatchingForActivesWithMultiplePurityPercentage(masterDataList, modelDataForPrediction?.Actives[2], 3);
    }
  }, [modelDataForPrediction]);
  const returnMatching = (dataList, rawMaterialValue, purityPercentageSelectedValue) => {
    for (let i = 0; i < dataList.length; i++) {
      if (purityPercentageSelectedValue === null) {
        if (dataList[i]?.rawmaterial?.toLowerCase() === rawMaterialValue?.toLowerCase()) {
          return dataList[i];
        }
      } else {
        if (dataList[i]?.rawmaterial?.toLowerCase() === rawMaterialValue?.toLowerCase() && dataList[i]?.purityPercentage === purityPercentageSelectedValue) {
          return dataList[i];
        }
      }
    }
    return {}
  }

  const returnMatchingForActivesWithMultiplePurityPercentage = (dataList, rawMaterialValue, activeNo) => {
    const tempPurityPercentageArray = [];
    let tempObj = null;
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i]?.rawmaterial?.toLowerCase() === rawMaterialValue?.toLowerCase()) {
        tempPurityPercentageArray.push({
          label: dataList[i]?.purityPercentage,
          value: dataList[i]?.purityPercentage,
        });
        tempObj = dataList[i];
      }
    }
    if (tempPurityPercentageArray.length > 0) {
      if (activeNo === 1) {
        setActive1PurityPercentageList(tempPurityPercentageArray);
      }
      if (activeNo === 2) {
        setActive2PurityPercentageList(tempPurityPercentageArray);
      }
      if (activeNo === 3) {
        setActive3PurityPercentageList(tempPurityPercentageArray);
      }
    }
    return setActive1PurityPercentageList;
    // if (tempObj !== null) {
    //   return tempObj;
    // }
    // return {}
  }

  const handlePredictionSubmit = (values) => {
    setFormSubmittedValues(values)
    dispatch(
      getPrediction(values, modelDataForPrediction, () => {
        setShowPredictionResult(true);
      }),
    );
    dispatch(
      getProjects()
    )
    dispatch(
      getExchangeRates()
    )
  };
  const tempProjectList = [];
  if (projectList && projectList.length > 0) {
    projectList.forEach(function (element) {
      tempProjectList.push({
        label: element['name'],
        value: element['id']
      })
    });
  }
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Predictive Analysis
            {!isEmpty(predictionResult) && (
              <>
                {' '}
                - Result
              </>
            )}
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to="/aerosol">
            Aerosol
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            Predictive Analysis
          </Link>
        </Breadcrumbs>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!isEmpty(modelDataForPrediction) && (
              <>
                <Grid container item xs={12}>
                  {predictionResultLoading && (
                    <Loading />
                  )}
                  {!isEmpty(predictionResult) && (
                    <Grid item xs={12} md={12} p={2} alignItems="center">
                      <Paper sx={{ mt: 5, boxShadow: 'none' }} className='input-bg'>
                        <Box sx={{ backgroundColor: '#a1de7e' }} p={2}>
                          <Grid container item xs={12}>
                            <Grid item xs={12} md={4} p={2} alignItems="center">
                              <Typography variant="h4">
                                RESULT SUMMARY
                              </Typography>
                              <Typography variant="h5">
                                Predicted KT50 :
                                {' '}
                                {predictionResult && predictionResult['Predicted KT50']}
                                {' '}
                                Minutes
                              </Typography>
                              <Typography variant="h5">
                                Predicted M24 :
                                {' '}
                                {predictionResult && predictionResult['Predicted M24']}
                                {' '}
                                %
                              </Typography>
                              <Button startIcon={<PrintIcon />} variant="contained" onClick={() => { window.print() }}>
                                PRINT
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={8} p={2} alignItems="center">
                              <Box p={2}>
                                <LinePlot graphData={predictionResult['KT']} />
                              </Box>
                            </Grid>
                          </Grid>
                          <Box sx={{ m: 2, p: 2, backgroundColor: 'whitesmoke!important' }}>
                            <Typography variant="h6">
                              COSTING
                            </Typography>
                            <Box>
                              <Select
                                value={active1Data}
                                onChange={(temp => setActive1Data(temp))}
                                options={[
                                  {
                                    label: 'Global',
                                    value: 'global',
                                  },
                                  {
                                    label: 'Brazil',
                                    value: 'brazil',
                                  },
                                  {
                                    label: 'Australia',
                                    value: 'australia',
                                  },
                                  {
                                    label: 'India',
                                    value: 'india',
                                  },
                                  {
                                    label: 'Malaysia',
                                    value: 'malaysia',
                                  },
                                  {
                                    label: 'South Africa',
                                    value: 'southafrica',
                                  },
                                ]}
                                placeholder="Select Country"
                              />
                            </Box>
                            {active1Data && (
                              <Box>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                  <Tabs value={value1} onChange={handleChange1} aria-label="basic tabs example">
                                    <Tab sx={{ color: 'black' }} label="ACTIVE COST" {...a11yProps(0)} />
                                    <Tab sx={{ color: 'black' }} label="FORMULATION COST" {...a11yProps(1)} />
                                    {/* <Tab sx={{ color: 'black' }} label="PROJECT COST" {...a11yProps(2)} /> */}
                                  </Tabs>
                                </Box>
                                <Box>
                                  <TabPanel value={value1} index={0}>
                                    <Box p={2}>
                                      <Box>
                                        <TableContainer>
                                          <Table size="small">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  Classification
                                                </TableCell>
                                                <TableCell>
                                                  Concentration
                                                </TableCell>
                                                <TableCell>
                                                  {active1Data.label}
                                                  {' '}
                                                  Cost
                                                </TableCell>
                                                <TableCell>
                                                  GBP Cost
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow>
                                                <TableCell>
                                                  {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && (
                                                    <Box p={1}>
                                                      Active 1
                                                      :
                                                      (
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && modelDataForPrediction.Actives[0]}
                                                      {' '}
                                                      )
                                                    </Box>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  Concentration = {formSubmittedValues['Active 1%']} %
                                                </TableCell>
                                                <TableCell>
                                                  {active1Data && (
                                                    <Box>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] ? (
                                                        <Typography>
                                                          {active1Data.label + " Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </Box>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {active1Data && (
                                                    <Box>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] ? (
                                                        <Typography>
                                                          {"GBP Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </Box>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 1 && (
                                                    <Box p={1}>
                                                      Active 2
                                                      :
                                                      (
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[1] && modelDataForPrediction.Actives[1]}
                                                      {' '}
                                                      )
                                                    </Box>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  Concentration = {formSubmittedValues['Active 2%']} %
                                                </TableCell>
                                                <TableCell>
                                                  {returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] ? (
                                                    <Typography>
                                                      {active1Data.label + " Cost : "}
                                                      {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] / 100).toFixed(3)}
                                                    </Typography>
                                                  ) : (
                                                    <Typography>
                                                      NA
                                                    </Typography>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] ? (
                                                    <Typography>
                                                      {"GBP Cost : "}
                                                      {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] / 100).toFixed(3)}
                                                    </Typography>
                                                  ) : (
                                                    <Typography>
                                                      NA
                                                    </Typography>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell>
                                                  {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 2 && (
                                                    <Box p={1}>
                                                      Active 3
                                                      :
                                                      (
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[2] && modelDataForPrediction.Actives[2]}
                                                      {' '}
                                                      )
                                                    </Box>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  Concentration = {formSubmittedValues['Active 3%']} %
                                                </TableCell>
                                                <TableCell>
                                                  {returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] ? (
                                                    <Typography>
                                                      {active1Data.label + " Cost : "}
                                                      {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] / 100).toFixed(3)}
                                                    </Typography>
                                                  ) : (
                                                    <Typography>
                                                      NA
                                                    </Typography>
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  {returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] ? (
                                                    <Typography>
                                                      {"GBP Cost : "}
                                                      {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] / 100).toFixed(3)}
                                                    </Typography>
                                                  ) : (
                                                    <Typography>
                                                      NA
                                                    </Typography>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell>
                                                  <Typography sx={{ fontSize: '18' }}>
                                                    Total Actives ({active1Data.label} Cost):
                                                    {((Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] / 100) || 0)).toFixed(3)}
                                                  </Typography>
                                                </TableCell>
                                                <TableCell>
                                                  <Typography sx={{ fontSize: '18' }}>
                                                    Total Actives (GBP Cost):
                                                    {((Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0], active1PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[1], active2PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[2], active3PurityPercentage?.value)[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] / 100) || 0)).toFixed(3)}
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Box>
                                    </Box>
                                    <Divider />
                                    <Typography variant="h6">
                                      Project Cost Comparison
                                    </Typography>
                                    <Box>
                                      <Select
                                        value={projectData}
                                        onChange={(temp => handleProjectChange(temp))}
                                        options={tempProjectList}
                                        placeholder="Select Project"
                                      />
                                    </Box>
                                    <Box p={2}>
                                      <>
                                        {projectData1 && (
                                          <>
                                            <TableContainer>
                                              <Table size="small">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      Classification
                                                    </TableCell>
                                                    <TableCell>
                                                      Raw Material
                                                    </TableCell>
                                                    <TableCell>
                                                      TDS
                                                    </TableCell>
                                                    <TableCell>
                                                      KG
                                                    </TableCell>
                                                    <TableCell>
                                                      Cost
                                                      (
                                                      {active1Data.value === 'india' && (
                                                        <>
                                                          INR
                                                        </>
                                                      )}
                                                      {active1Data.value === 'brazil' && (
                                                        <>
                                                          BRL
                                                        </>
                                                      )}
                                                      {active1Data.value === 'southafrica' && (
                                                        <>
                                                          ZAR
                                                        </>
                                                      )}
                                                      {active1Data.value === 'australia' && (
                                                        <>
                                                          AUD
                                                        </>
                                                      )}
                                                      {active1Data.value === 'malaysia' && (
                                                        <>
                                                          MYR
                                                        </>
                                                      )}
                                                      {active1Data.value === 'global' && (
                                                        <>

                                                        </>
                                                      )}
                                                      )
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {projectData1?.data && projectData1?.data?.map((element) => {
                                                    if (element?.classification?.toLowerCase() === 'active' || element.classification === 'Active' || element.classification === 'ACTIVE') {
                                                      totalActiveCostForProject = totalActiveCostForProject + Number(element.cost)
                                                    }
                                                    if (element?.classification?.toLowerCase() === 'active' || element.classification === 'Active' || element.classification === 'ACTIVE') {
                                                      return (
                                                        <TableRow key={element.tds + element.rawmaterial + element.classification}>
                                                          <TableCell>
                                                            {element.classification}
                                                          </TableCell>
                                                          <TableCell>
                                                            {element.rawmaterial}
                                                          </TableCell>
                                                          <TableCell>
                                                            {element.tds}
                                                          </TableCell>
                                                          <TableCell>
                                                            {element.kg}
                                                          </TableCell>
                                                          <TableCell>
                                                            {Number(element.cost).toFixed(3)}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    }
                                                  })}
                                                  <TableRow>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell>
                                                      <Box>
                                                        {projectData.label && (
                                                          <Typography sx={{ fontSize: '18' }}>
                                                            Total Active Cost For
                                                            {' '}
                                                            {projectData?.label}
                                                            :
                                                            {' '}
                                                            {Number(totalActiveCostForProject).toFixed(3)}
                                                            {' '}
                                                            {active1Data.value === 'india' && (
                                                              <>
                                                                INR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'brazil' && (
                                                              <>
                                                                BRL
                                                              </>
                                                            )}
                                                            {active1Data.value === 'southafrica' && (
                                                              <>
                                                                ZAR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'australia' && (
                                                              <>
                                                                AUD
                                                              </>
                                                            )}
                                                            {active1Data.value === 'malaysia' && (
                                                              <>
                                                                MYR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'global' && (
                                                              <>

                                                              </>
                                                            )}
                                                          </Typography>
                                                        )}
                                                      </Box>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                            {/* <Box>
                                              <Typography variant="h6">
                                                Total Cost:
                                                {' '}
                                                {projectData1?.amount && projectData1.amount}
                                              </Typography>
                                            </Box> */}
                                          </>
                                        )}
                                      </>
                                    </Box>
                                  </TabPanel>
                                  <TabPanel value={value1} index={1}>
                                    <Box p={2}>
                                      <Grid container>
                                        <Grid item xs={12} md={12}>
                                          <Box>
                                            <TableContainer>
                                              <Table size="small">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>
                                                      Classification
                                                    </TableCell>
                                                    <TableCell>
                                                      Concentration
                                                    </TableCell>
                                                    <TableCell>
                                                      {active1Data.label}
                                                      {' '}
                                                      Cost
                                                    </TableCell>
                                                    <TableCell>
                                                      GBP Cost
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  <TableRow>
                                                    <TableCell>
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && (
                                                        <Box p={1}>
                                                          Active 1
                                                          :
                                                          (
                                                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && modelDataForPrediction.Actives[0]}
                                                          {' '}
                                                          )
                                                        </Box>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      Concentration = {formSubmittedValues['Active 1%']} %
                                                    </TableCell>
                                                    <TableCell>
                                                      {active1Data && (
                                                        <Box>
                                                          {returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] ? (
                                                            <Typography>
                                                              {active1Data.label + " Cost : "}
                                                              {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] / 100).toFixed(3)}
                                                            </Typography>
                                                          ) : (
                                                            <Typography>
                                                              NA
                                                            </Typography>
                                                          )}
                                                        </Box>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {active1Data && (
                                                        <Box>
                                                          {returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] ? (
                                                            <Typography>
                                                              {"GBP Cost : "}
                                                              {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] / 100).toFixed(3)}
                                                            </Typography>
                                                          ) : (
                                                            <Typography>
                                                              NA
                                                            </Typography>
                                                          )}
                                                        </Box>
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 1 && (
                                                        <Box p={1}>
                                                          Active 2
                                                          :
                                                          (
                                                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[1] && modelDataForPrediction.Actives[1]}
                                                          {' '}
                                                          )
                                                        </Box>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      Concentration = {formSubmittedValues['Active 2%']} %
                                                    </TableCell>
                                                    <TableCell>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] ? (
                                                        <Typography>
                                                          {active1Data.label + " Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] ? (
                                                        <Typography>
                                                          {"GBP Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell>
                                                      {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 2 && (
                                                        <Box p={1}>
                                                          Active 3
                                                          :
                                                          (
                                                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[2] && modelDataForPrediction.Actives[2]}
                                                          {' '}
                                                          )
                                                        </Box>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      Concentration = {formSubmittedValues['Active 3%']} %
                                                    </TableCell>
                                                    <TableCell>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] ? (
                                                        <Typography>
                                                          {active1Data.label + " Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </TableCell>
                                                    <TableCell>
                                                      {returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] ? (
                                                        <Typography>
                                                          {"GBP Cost : "}
                                                          {Number(returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] / 100).toFixed(3)}
                                                        </Typography>
                                                      ) : (
                                                        <Typography>
                                                          NA
                                                        </Typography>
                                                      )}
                                                    </TableCell>
                                                  </TableRow>
                                                  <TableRow>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell>
                                                      <Typography sx={{ fontSize: '18' }}>
                                                        Total Actives ({active1Data.label} Cost):
                                                        {((Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.countryCost * formSubmittedValues['Active 1%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.countryCost * formSubmittedValues['Active 2%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.countryCost * formSubmittedValues['Active 3%'] / 100) || 0)).toFixed(3)}
                                                      </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                      <Typography sx={{ fontSize: '18' }}>
                                                        Total Actives (GBP Cost):
                                                        {((Number(returnMatching(masterDataList, modelDataForPrediction.Actives[0])[active1Data.value]?.usdCost * formSubmittedValues['Active 1%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[1])[active1Data.value]?.usdCost * formSubmittedValues['Active 2%'] / 100) || 0) + ((returnMatching(masterDataList, modelDataForPrediction.Actives[2])[active1Data.value]?.usdCost * formSubmittedValues['Active 3%'] / 100) || 0)).toFixed(3)}
                                                      </Typography>
                                                    </TableCell>
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <CostingForm selectedCountry={active1Data} masterDataList={masterDataList} onSubmit={handleCostingSubmit} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Divider />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Typography variant="h6">
                                            Project Cost Comparison
                                          </Typography>
                                          <Box>
                                            <Select
                                              value={projectData}
                                              onChange={(temp => handleProjectChange(temp))}
                                              options={tempProjectList}
                                              placeholder="Select Project"
                                            />
                                          </Box>
                                          <Box p={2}>
                                            <>
                                              {projectData1 && (
                                                <>
                                                  <TableContainer>
                                                    <Table size="small">
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell>
                                                            Classification
                                                          </TableCell>
                                                          <TableCell>
                                                            Raw Material
                                                          </TableCell>
                                                          <TableCell>
                                                            TDS
                                                          </TableCell>
                                                          <TableCell>
                                                            KG
                                                          </TableCell>
                                                          <TableCell>
                                                            Cost
                                                            (
                                                            {active1Data.value === 'india' && (
                                                              <>
                                                                INR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'brazil' && (
                                                              <>
                                                                BRL
                                                              </>
                                                            )}
                                                            {active1Data.value === 'southafrica' && (
                                                              <>
                                                                ZAR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'australia' && (
                                                              <>
                                                                AUD
                                                              </>
                                                            )}
                                                            {active1Data.value === 'malaysia' && (
                                                              <>
                                                                MYR
                                                              </>
                                                            )}
                                                            {active1Data.value === 'global' && (
                                                              <>

                                                              </>
                                                            )}
                                                            )
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {projectData1?.data && projectData1?.data?.map((element) => {
                                                          totalActiveCostForProject2 = totalActiveCostForProject2 + Number(element.cost)
                                                          return (
                                                            <TableRow key={element.tds + element.rawmaterial + element.classification}>
                                                              <TableCell>
                                                                {element.classification}
                                                              </TableCell>
                                                              <TableCell>
                                                                {element.rawmaterial}
                                                              </TableCell>
                                                              <TableCell>
                                                                {element.tds}
                                                              </TableCell>
                                                              <TableCell>
                                                                {element.kg}
                                                              </TableCell>
                                                              <TableCell>
                                                                {Number(element.cost).toFixed(3)}
                                                              </TableCell>
                                                            </TableRow>
                                                          )
                                                        })}
                                                        <TableRow>
                                                          <TableCell />
                                                          <TableCell />
                                                          <TableCell />
                                                          <TableCell />
                                                          <TableCell>
                                                            <Box>
                                                              <Typography variant="h6">
                                                                Total Cost:
                                                                {' '}
                                                                {projectData1?.amount && Number(projectData1.amount).toFixed(3)}
                                                                {' '}
                                                                {active1Data.value === 'india' && (
                                                                  <>
                                                                    INR
                                                                  </>
                                                                )}
                                                                {active1Data.value === 'brazil' && (
                                                                  <>
                                                                    BRL
                                                                  </>
                                                                )}
                                                                {active1Data.value === 'southafrica' && (
                                                                  <>
                                                                    ZAR
                                                                  </>
                                                                )}
                                                                {active1Data.value === 'australia' && (
                                                                  <>
                                                                    AUD
                                                                  </>
                                                                )}
                                                                {active1Data.value === 'malaysia' && (
                                                                  <>
                                                                    MYR
                                                                  </>
                                                                )}
                                                                {active1Data.value === 'global' && (
                                                                  <>

                                                                  </>
                                                                )}
                                                              </Typography>
                                                            </Box>
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                </>
                                              )}
                                            </>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </TabPanel>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12} md={3} p={2} alignItems="center">
                    {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && (
                      <>
                        <Typography align="center">
                          Active 1
                          :
                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[0] && modelDataForPrediction.Actives[0]}
                        </Typography>
                        {active1PurityPercentageList.length > 0 ? (
                          <div>
                            <Typography align="center" variant="body2">
                              Please select purity percentage
                            </Typography>
                            <Select
                              onChange={(temp => setActive1PurityPercentage(temp))}
                              options={active1PurityPercentageList}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} p={2} alignItems="center">
                    {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 1 && (
                      <>
                        <Typography align="center">
                          Active 2
                          :
                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[1] && modelDataForPrediction.Actives[1]}
                        </Typography>
                        {active2PurityPercentageList.length > 0 ? (
                          <div>
                            <Typography align="center" variant="body2">
                              Please select purity percentage
                            </Typography>
                            <Select
                              onChange={(temp => setActive2PurityPercentage(temp))}
                              options={active2PurityPercentageList}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} p={2} alignItems="center">
                    {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives.length > 2 && (
                      <>
                        <Typography align="center">
                          Active 3
                          :
                          {modelDataForPrediction && modelDataForPrediction.Actives && modelDataForPrediction.Actives[2] && modelDataForPrediction.Actives[2]}
                        </Typography>
                        {active3PurityPercentageList.length > 0 ? (
                          <div>
                            <Typography align="center" variant="body2">
                              Please select purity percentage
                            </Typography>
                            <Select
                              onChange={(temp => setActive3PurityPercentage(temp))}
                              options={active3PurityPercentageList}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} p={2} alignItems="center">
                    <Tooltip placement="top" title="If you want to Input all the parameters please press Toggle">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    <Button variant="outlined" onClick={() => { setShowSignificant(!showSignificant) }}>
                      TOGGLE
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={12}>
                  <Grid item xs={12} md={12} alignItems="center">
                    <PredictiveAnalysisForm
                      modelData={modelDataForPrediction}
                      onSubmit={handlePredictionSubmit}
                      showSignificant={showSignificant}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </SideMenuAppWrapper >
  );
};

export default PredictiveAnalysisPage;
