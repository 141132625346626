import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { isEmpty } from 'lodash';
import ScatterPlot from './Charts/ScatterPlot';
import BarPlot from './Charts/BarPlot';
import { useHistory } from 'react-router-dom';
import {
  downloadFile,
} from '../services/File/actions';
import { connect, useDispatch, useSelector } from 'react-redux';

// Display Data on ShowModels Page
const DataModelDisplay = ({ modelData, nameAvailable, masterDataList, selectedCountryValue, }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSaveModelDataForPrediction = (uid) => {
    history.push(`/predictiveAnalysis/${uid}`);
  };
  if (modelData) {

    return (
      <>
        <Grid container style={{ padding: '15px', backgroundColor: '#F8EEF7' }} p={2}>
          {nameAvailable === true && (
            <Grid item xs={12} md={2} >
              Name
              :
              {' '}
              {modelData && modelData.name && modelData.name}
            </Grid>
          )}
          {nameAvailable === true && (
            <Grid item xs={12} md={3}>
              Narration
              :
              {' '}
              {modelData && modelData.narration && modelData.narration}
            </Grid>
          )}
          {nameAvailable && (
            <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
              {/* Created At
            :
            {' '}
            {new Date(modelData.createdAt).toLocaleDateString("en-IN")} */}
            </Grid>
          )}
          {nameAvailable && (
            <Grid item xs={3} md={5} sx={{ textAlign: 'right' }}>
              {/* Created By
            :
            {' '}
          {modelData && modelData.createdBy && modelData.createdBy} */}
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={
                    () => {
                      dispatch(
                        downloadFile(modelData.filePath)
                      )
                    }
                  }>
                  VIEW CORRESPONDING FILE
                </Button>
                <Button onClick={() => { handleSaveModelDataForPrediction(modelData.id); }} variant="contained" style={{ textTransform: 'capitalize', border: 'none', outline: 'none', background: '#F10086' }}>
                  Go to Prediction
                </Button>
              </Stack>
            </Grid>
          )}
          <Grid item xs={3} md={5}>
            Active(s) :
            <ul>
              {modelData?.Actives?.map((row, index) => (
                <Stack>
                  {index === 0 && (
                    <li>
                      <>
                        {modelData?.hasOwnProperty('SuggestedData') ? (
                          <>
                            {row} (%)
                            :
                            {modelData.SuggestedData['Active 1%']}
                          </>
                        ) : (
                          <div>
                            <Typography variant="h5" style={{ fontSize: '16px' }}>
                              {row}
                            </Typography>
                          </div>
                        )}
                      </>
                    </li>
                  )}
                  {index === 1 && (
                    <li>
                      {modelData?.hasOwnProperty('SuggestedData') ? (
                        <>
                          {row} (%)
                          :
                          {modelData.SuggestedData['Active 2%']}
                        </>
                      ) : (
                        <div>
                          <Typography variant="h5" style={{ fontSize: '16px' }}>
                            {row}
                          </Typography>
                        </div>)}
                    </li>
                  )}
                  {index === 2 && (
                    <li>
                      {modelData?.hasOwnProperty('SuggestedData') ? (
                        <>
                          {row} (%)
                          :
                          {modelData.SuggestedData['Active 3%']}
                        </>
                      ) : (
                        <div>
                          <Typography variant="h5" style={{ fontSize: '16px' }}>
                            {row}
                          </Typography>
                        </div>)}
                    </li>
                  )}
                </Stack>
              ))}
            </ul>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Box p={2}>
                  <TableContainer >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {modelData && modelData?.Actives?.map((row, index) => (
                            <TableCell component="th" scope="row" key={index} sx={{ textAlign: 'center' }} style={{ fontSize: '28px' }}>
                              A
                              {index + 1}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {modelData?.Actives.map((row, index) => (
                            <TableCell key={index} scope="row" sx={{ textAlign: 'center' }} >
                              <Typography variant="h5" style={{ fontSize: '16px' }}>
                                {row}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                {modelData?.SuggestedData && !isEmpty(modelData.SuggestedData) ? (
                  <Box p={2}>
                    <Alert severity="success" p={2} m={2}>
                      <AlertTitle sx={{ background: '#80c0ff', padding: '5px' }}>
                        <div>
                          {modelData.SuggestedData['KT50'] && (
                            <>
                              KT50
                              :
                              {modelData.SuggestedData['KT50']}
                              {' '}
                              Minutes
                            </>
                          )}
                        </div>
                        <div>
                          {modelData.SuggestedData['M24'] && (
                            <>
                              M24
                              :
                              {modelData.SuggestedData['M24']}
                              {' '}
                              %
                            </>
                          )}
                        </div>
                      </AlertTitle>
                      <div>
                        {modelData.SuggestedData['Active 1%'] && (
                          <>
                            Active 1%
                            :
                            {modelData.SuggestedData['Active 1%']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Active 2%'] && (
                          <>
                            Active 2%
                            :
                            {modelData.SuggestedData['Active 2%']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Active 3%'] && (
                          <>
                            Active 3%
                            :
                            {modelData.SuggestedData['Active 3%']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['formula base'] && (
                          <>
                            formula base
                            :
                            {modelData.SuggestedData['formula base']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Insect Tested'] && (
                          <>
                            Insect Tested
                            :
                            {modelData.SuggestedData['Insect Tested']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Cone pattern'] && (
                          <>
                            Cone pattern
                            :
                            {modelData.SuggestedData['Cone pattern']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Spray pattern'] && (
                          <>
                            Spray pattern
                            :
                            {modelData.SuggestedData['Spray pattern']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Vapour Tap'] && (
                          <>
                            Vapour Tap
                            :
                            {modelData.SuggestedData['Vapour Tap']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Valve Orifice dimensions'] && (
                          <>
                            Valve Orifice dimensions
                            :
                            {modelData.SuggestedData['Valve Orifice dimensions']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Solvent type'] && (
                          <>
                            Solvent type
                            :
                            {modelData.SuggestedData['Solvent type']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Housing Orifice'] && (
                          <>
                            Housing Orifice
                            :
                            {modelData.SuggestedData['Housing Orifice']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Gas Type'] && (
                          <>
                            Gas Type
                            :
                            {modelData.SuggestedData['Gas Type']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Active 2'] && (
                          <>
                            Active 2
                            :
                            {modelData.SuggestedData['Active 2']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Ratio Bulk/Propellant'] && (
                          <>
                            Ratio Bulk/Propellant
                            :
                            {modelData.SuggestedData['Ratio Bulk/Propellant']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Active 1'] && (
                          <>
                            Active 1
                            :
                            {modelData.SuggestedData['Active 1']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Active 3'] && (
                          <>
                            Active 3
                            :
                            {modelData.SuggestedData['Active 3']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Temperature'] && (
                          <>
                            Temperature
                            :
                            {modelData.SuggestedData['Temperature']}
                          </>
                        )}
                      </div>
                      {/* <div>
                {modelData.SuggestedData['Mosquito Insertion Time'] && (
                  <>
                    Mosquito Insertion Time
                    :
                    {modelData.SuggestedData['Mosquito Insertion Time']}
                  </>
                )}
              </div> */}
                      <div>
                        {modelData.SuggestedData['Spray duration'] && (
                          <>
                            Spray duration
                            :
                            {modelData.SuggestedData['Spray duration']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Dose Concentration'] && (
                          <>
                            Dose Concentration
                            :
                            {modelData.SuggestedData['Dose Concentration']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Number of Insects'] && (
                          <>
                            Number of Insects
                            :
                            {modelData.SuggestedData['Number of Insects']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Spray Rate'] && (
                          <>
                            Spray Rate
                            :
                            {modelData.SuggestedData['Spray Rate']}
                          </>
                        )}
                      </div>
                      <div>
                        {modelData.SuggestedData['Humidity'] && (
                          <>
                            Humidity
                            :
                            {modelData.SuggestedData['Humidity']}
                          </>
                        )}
                      </div>
                      {/* <div>
                {modelData.SuggestedData['Gas pressure'] && (
                  <>
                    Gas pressure
                    :
                    {modelData.SuggestedData['Gas pressure']}
                  </>
                )}
              </div> */}
                      <div>
                        {modelData.SuggestedData['Solvent %'] && (
                          <>
                            Solvent %
                            :
                            {modelData.SuggestedData['Solvent %']}
                          </>
                        )}
                      </div>
                      {/* <div>
                {modelData.SuggestedData['Average Droplet Size'] && (
                  <>
                    Average Droplet Size
                    :
                    {modelData.SuggestedData['Average Droplet Size']}
                  </>
                )}
              </div> */}
                      <div>
                        {modelData.SuggestedData['Age of Insects'] && (
                          <>
                            Age of Insects
                            :
                            {modelData.SuggestedData['Age of Insects']}
                          </>
                        )}
                      </div>
                    </Alert>
                  </Box>
                ) : (
                  <>
                  </>
                  // <Box p={2}>
                  //   <Alert severity="error">
                  //     <div>

                  //     </div>
                  //   </Alert>
                  // </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item md={4}>
                    <Typography variant="h4" sx={{ float: 'right' }} mt={5}>
                      KT50
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <List>
                        <ListItem disablePadding>
                          <Typography variant="subtitle1" pl={4}>
                            Significant Predictors
                          </Typography>
                        </ListItem>
                        <ul style={{ width: '200px', textAlign: 'center' }}>
                          {modelData?.KT50?.Numerical.map((row, index) => row.Significant && (
                            <li key={index} style={{ background: '#EF6C58', listStyle: 'none', color: '#fff', fontSize: '14px' }}>
                              {row.Name}
                            </li>
                          ))}
                          {modelData?.KT50?.Categorical.map((row, index) => row.Significant && (
                            <li key={index}>
                              {row.Name}
                            </li>
                          ))}
                        </ul>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item md={4}>
                    <Typography variant="h4" sx={{ float: 'right' }} mt={5}>
                      M24
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <List>
                        <ListItem disablePadding>
                          <Typography variant="subtitle1" pl={4}>
                            Significant Predictors
                          </Typography>
                        </ListItem>
                        <ul style={{ width: '200px', textAlign: 'center' }}>
                          {modelData?.M24?.Numerical.map((row, index) => row.Significant === true && (
                            <li key={index} style={{ background: '#EF6C58', listStyle: 'none', color: '#fff', fontSize: '14px' }}>
                              {row.Name}
                            </li>
                          ))}
                          {modelData?.M24?.Categorical.map((row, index) => row.Significant === true && (
                            <li key={index}>
                              {row.Name}
                            </li>
                          ))}
                        </ul>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {modelData?.KT50?.Residuals && modelData?.KT50?.Residuals.length > 0 && (
                      <ScatterPlot graphData={modelData?.KT50?.Residuals} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box p={2}>
                      {modelData?.KT50?.Tornado && modelData?.KT50?.Tornado.length > 0 && (
                        <BarPlot graphData={modelData?.KT50?.Tornado} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Box p={2}>
                      {modelData?.M24?.Residuals && modelData?.M24?.Residuals.length > 0 && (
                        <ScatterPlot graphData={modelData?.M24?.Residuals} />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {modelData?.M24?.Tornado && modelData?.M24?.Tornado.length > 0 && (
                      <BarPlot graphData={modelData?.M24?.Tornado} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <div />
  )
};

export default DataModelDisplay;
