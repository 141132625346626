import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const renderField = ({
  input,
  placeholder,
  type,
  label,
  name,
  disabled,
  required,
  meta: { touched, error, warning },
  min,
  max,
  significantkt50,
  significantm24,
  showSignificant,
  autoSelectIfOnlyOneParameter
}) => {
  if (autoSelectIfOnlyOneParameter && (min === max)) {
    input.onChange(min)
  }
  if (showSignificant) {
    if (significantkt50 || significantm24) {
      return (
        <>
          <Box>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
                <Typography color="initial">
                  {label}
                  {(significantkt50 && !significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for KT50">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {(!significantkt50 && significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for M24">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {(significantkt50 && significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for KT50 and M24">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...input}
                  // label={label}
                  type={type}
                  id={name}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  size="small"
                  placeholder={placeholder || `Enter ${label}`}
                  disabled={disabled}
                  required={required}
                  autoComplete="off"
                  error={(touched && !!error)}
                  helperText={touched && (error || warning)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  H
                  :
                  {max}
                </div>
                <div>
                  L
                  :
                  {min}
                </div>
              </Grid>
            </Grid>
          </Box>
          {/* {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))} */}
        </>
      );
    } else {
      return null;
    }
  } else {
    return (
      <>
        <Box>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
              <Typography color="initial">
                {label}
                {(significantkt50 && !significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }} variant="h5" component="h6">
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for KT50">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
                {(!significantkt50 && significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }} variant="h5" component="h6">
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for M24">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
                {(significantkt50 && significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }} variant="h5" component="h6">
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for KT50 and M24">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...input}
                // label={label}
                type={type}
                id={name}
                variant="standard"
                margin="normal"
                fullWidth
                size="small"
                placeholder={placeholder || `Enter ${label}`}
                disabled={disabled}
                required={required}
                autoComplete="off"
                error={(touched && !!error)}
                helperText={touched && (error || warning)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <div>
                H
                :
                {max}
              </div>
              <div>
                L
                :
                {min}
              </div>
            </Grid>
          </Grid>
        </Box>
        {/* {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))} */}
      </>
    );
  }
};

export default renderField;
