import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Input from '../components/Input';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { renderSelectInput } from '../components/SelectInput';
import { connect, useDispatch, useSelector } from 'react-redux';

const validate = (values) => {
  const errors = {};
  // if (isEmpty(values.email)) {
  //   errors.email = 'Enter email';
  // }
  // if (isEmpty(values.password)) {
  //   errors.password = 'Enter password';
  // }
  return errors;
};

let MasterDataForm = (props) => {
  const {
    handleSubmit,
    submitting,
    isEdit,
    brazildata,
    australiadata,
    indiadata,
    malaysiadata,
    southafricadata,
    globaldata,
  } = props;
  const exchangeRateList = useSelector((state) => state.ExchangeRate.exchangeRateList);
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const dispatch = useDispatch();
  if (brazildata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'brazil') {
        dispatch(change('MASTER_DATA_FORM', 'brazil.usdCost', (brazildata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  if (australiadata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'australia') {
        dispatch(change('MASTER_DATA_FORM', 'australia.usdCost', (australiadata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  if (indiadata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'india') {
        dispatch(change('MASTER_DATA_FORM', 'india.usdCost', (indiadata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  if (malaysiadata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'malaysia') {
        dispatch(change('MASTER_DATA_FORM', 'malaysia.usdCost', (malaysiadata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  if (southafricadata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'southafrica') {
        dispatch(change('MASTER_DATA_FORM', 'southafrica.usdCost', (southafricadata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  if (globaldata) {
    for (let i = 0; i < exchangeRateList.length; i++) {
      if (exchangeRateList[i]?.country == 'global') {
        dispatch(change('MASTER_DATA_FORM', 'global.usdCost', (globaldata / Number(exchangeRateList[i].rate)).toFixed(3)));
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Alert severity="error">
        <AlertTitle>
          All costs should be written per KG. If UOM is litre, then divide it by density to work out the cost per kg
        </AlertTitle>
      </Alert>
      <Field
        name={`classification`}
        type="string"
        label="Classification"
        component={Input}
        required
      />
      <Field
        name={`rawmaterial`}
        type="string"
        label="Raw Material"
        component={Input}
        // disabled={isEdit}
        required
      />
      <Field
        name={`purityPercentage`}
        type="Number"
        label="Purity Percentage (%)"
        component={Input}
      />
      <Field
        name={`tds`}
        type="string"
        label="TDS"
        component={Input}
        disabled={isEdit}
        required
      />
      <Field
        name={`localPlantCode`}
        type="string"
        label="Local Plant Code"
        component={Input}
      />
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          BRAZIL
        </Typography>
        <Field
          name={`brazil.uom`}
          type="string"
          label="Unit"
          component={Input}
          disabled
          defaultValue={"KG"}
        />
        <Field
          name={`brazil.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`brazil.usdCost`}
          type="number"
          label="GBP Cost"
          component={Input}
          disabled
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          AUSTRALIA
        </Typography>
        <Field
          name={`australia.uom`}
          type="string"
          label="Unit"
          defaultValue={"KG"}
          component={Input}
          disabled
        />
        <Field
          name={`australia.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`australia.usdCost`}
          type="number"
          component={Input}
          label="GBP Cost"
          disabled
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          INDIA
        </Typography>
        <Field
          name={`india.uom`}
          type="string"
          label="Unit"
          defaultValue={"KG"}
          component={Input}
          disabled
        />
        <Field
          name={`india.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`india.usdCost`}
          type="number"
          label="GBP Cost"
          component={Input}
          disabled
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          MALAYSIA
        </Typography>
        <Field
          name={`malaysia.uom`}
          type="string"
          label="Unit"
          defaultValue={"KG"}
          component={Input}
          disabled
        />
        <Field
          name={`malaysia.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`malaysia.usdCost`}
          type="number"
          label="GBP Cost"
          component={Input}
          disabled
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          SOUTH AFRICA
        </Typography>
        <Field
          name={`southafrica.uom`}
          type="string"
          label="Unit"
          component={Input}
          defaultValue={"KG"}
          disabled
        />
        <Field
          name={`southafrica.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`southafrica.usdCost`}
          type="number"
          label="GBP Cost"
          component={Input}
          disabled
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          GLOBAL
        </Typography>
        <Field
          name={`global.uom`}
          type="string"
          label="Unit"
          component={Input}
          defaultValue={"KG"}
          disabled
        />
        <Field
          name={`global.countryCost`}
          type="number"
          label="Country Cost"
          component={Input}
        />
        <Field
          name={`global.usdCost`}
          type="number"
          label="GBP Cost"
          component={Input}
          disabled
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        Submit
      </Button>
    </form >
  );
};

const selector = formValueSelector('MASTER_DATA_FORM');


MasterDataForm = reduxForm({
  form: 'MASTER_DATA_FORM',
  validate,
  enableReinitialize: true,
})(MasterDataForm);

MasterDataForm = connect(state => { // eslint-disable-line
  const brazildata = selector(state, 'brazil.countryCost');
  const australiadata = selector(state, 'australia.countryCost');
  const indiadata = selector(state, 'india.countryCost');
  const malaysiadata = selector(state, 'malaysia.countryCost');
  const southafricadata = selector(state, 'southafrica.countryCost');
  const globaldata = selector(state, 'global.countryCost');
  return {
    brazildata,
    australiadata,
    indiadata,
    malaysiadata,
    southafricadata,
    globaldata,
  };
})(MasterDataForm);

export default MasterDataForm;
