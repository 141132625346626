import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import PredictiveAnalysisFieldInput from '../components/PredictiveAnalysisFieldInput';
import { renderSelectInput2 } from '../components/SelectInput';
import { dynamicValidator } from '../components/DynamicValidator';

const returnObjectFromArray = (array, value) => {
  return array.find(x => x.Name === value);
};
const lower = value => value && parseFloat(value);

const validate = (values) => {
  const errors = {};
  // if (isEmpty(values.string)) {
  //   errors.string = 'Enter string';
  // }
  // if (isEmpty(values.password)) {
  //   errors.password = 'Enter password';
  // }
  // if (values['Solvent %'] && values['Solvent %'] < (returnObjectFromArray(modelData.KT50.Numerical, 'Solvent %')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Solvent %')?.Min)) {
  //   errors['Solvent %'] = "MIN VALUE ERROR";
  // }
  return errors;
};
let PredictiveAnalysisForm = (props) => {
  const {
    submitting,
    modelData,
    handleSubmit,
    showSignificant,
  } = props;
  const isLoading = useSelector((state) => state.Model.isLoading);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting || isLoading}
          >
            PREDICT
          </Button>
        </Grid>
        <div style={{ overflow: 'auto', height: '80vh', paddingLeft: '100px', paddingRight: '100px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Box
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              // minHeight="100vh"
              >
                Formulation Parameters
              </Box>
            </Grid>
            <Grid container item xs={12} md={10}>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="formula base"
                  type="select"
                  label="formula base"
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'formula base')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'formula base')?.Categories}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  autoSelectIfOnlyOneParameter
                  // required
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'formula base')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'formula base')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Ratio Bulk/Propellant"
                  type="select"
                  label="Ratio Bulk/Propellant"
                  disabled={submitting || isLoading}
                  autoSelectIfOnlyOneParameter
                  // required
                  validate={dynamicValidator}
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Ratio Bulk/Propellant')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Ratio Bulk/Propellant')?.Categories}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Ratio Bulk/Propellant')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Ratio Bulk/Propellant')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Gas Type"
                  type="select"
                  label="Gas Type"
                  // autoSelectIfOnlyOneParameter
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Gas Type')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Gas Type')?.Categories}
                  disabled={submitting || isLoading}
                  // required
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Gas Type')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Gas Type')?.Significant}
                />
              </Grid>
              {modelData && modelData.Actives[0] && modelData.Actives[0] && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      Active 1%
                      :
                      {modelData && modelData.Actives[0] && modelData.Actives[0]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={PredictiveAnalysisFieldInput}
                      showSignificant={showSignificant}
                      name="Active 1%"
                      type="number"
                      normalize={lower}
                      label="% of Active 1"
                      disabled={submitting || isLoading}
                      required={returnObjectFromArray(modelData.KT50.Numerical, 'Active 1%')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Active 1%')?.Significant}
                      min={returnObjectFromArray(modelData.KT50.Numerical, 'Active 1%')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Active 1%')?.Min}
                      max={returnObjectFromArray(modelData.KT50.Numerical, 'Active 1%')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Active 1%')?.Max}
                      // Validation for all fields
                      validate={dynamicValidator}
                      significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Active 1%')?.Significant}
                      significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Active 1%')?.Significant}
                    />
                  </Grid>
                </>
              )}
              {modelData && modelData.Actives[1] && modelData.Actives[1] && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      Active 2%
                      :
                      {modelData && modelData.Actives[1] && modelData.Actives[1]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={PredictiveAnalysisFieldInput}
                      showSignificant={showSignificant}
                      name="Active 2%"
                      type="number"
                      normalize={lower}
                      label="% of Active 2"
                      disabled={submitting || isLoading}
                      required={returnObjectFromArray(modelData.KT50.Numerical, 'Active 2%')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Active 2%')?.Significant}
                      min={returnObjectFromArray(modelData.KT50.Numerical, 'Active 2%')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Active 2%')?.Min}
                      max={returnObjectFromArray(modelData.KT50.Numerical, 'Active 2%')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Active 2%')?.Max}
                      // Validation for all fields
                      validate={dynamicValidator}
                      significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Active 2%')?.Significant}
                      significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Active 2%')?.Significant}
                    />
                  </Grid>
                </>
              )}
              {modelData && modelData.Actives[2] && modelData.Actives[2] && (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography>
                      Active 3%
                      :
                      {modelData && modelData.Actives[2] && modelData.Actives[2]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      component={PredictiveAnalysisFieldInput}
                      showSignificant={showSignificant}
                      name="Active 3%"
                      type="number"
                      normalize={lower}
                      label="% of Active 3"
                      disabled={submitting || isLoading}
                      required={returnObjectFromArray(modelData.KT50.Numerical, 'Active 3%')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Active 3%')?.Significant}
                      min={returnObjectFromArray(modelData.KT50.Numerical, 'Active 3%')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Active 3%')?.Min}
                      max={returnObjectFromArray(modelData.KT50.Numerical, 'Active 3%')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Active 3%')?.Max}
                      // Validation for all fields
                      validate={dynamicValidator}
                      significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Active 3%')?.Significant}
                      significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Active 3%')?.Significant}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Solvent type"
                  type="select"
                  label="Solvent type"
                  // autoSelectIfOnlyOneParameter
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Solvent type')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Solvent type')?.Categories}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  // required
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Solvent type')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Solvent type')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Solvent %"
                  type="number"
                  normalize={lower}
                  label="Solvent %"
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Solvent %')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Solvent %')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Solvent %')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Solvent %')?.Max}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Solvent %')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Solvent %')?.Significant}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Solvent %')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Solvent %')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  autoSelectIfOnlyOneParameter
                  name="Gas pressure"
                  type="number"
                  normalize={lower}
                  label="Gas pressure"
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Gas pressure')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Gas pressure')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Gas pressure')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Gas pressure')?.Max}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Gas pressure')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Gas pressure')?.Significant}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Gas pressure')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Gas pressure')?.Significant}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              // minHeight="100vh"
              >
                Packaging Parameters
              </Box>
            </Grid>
            <Grid container item xs={12} md={10}>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Valve Orifice dimensions"
                  type="select"
                  label="Valve Orifice dimensions"
                  // autoSelectIfOnlyOneParameter
                  disabled={submitting || isLoading}
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Valve Orifice dimensions')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Valve Orifice dimensions')?.Categories}
                  validate={dynamicValidator}
                  // required
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Valve Orifice dimensions')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Valve Orifice dimensions')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Housing Orifice"
                  type="select"
                  label="Housing Orifice"
                  // autoSelectIfOnlyOneParameter
                  disabled={submitting || isLoading}
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Housing Orifice')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Housing Orifice')?.Categories}
                  validate={dynamicValidator}
                  // required
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Housing Orifice')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Housing Orifice')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Vapour Tap"
                  type="select"
                  // autoSelectIfOnlyOneParameter
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Vapour Tap')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Vapour Tap')?.Categories}
                  label="Vapour Tap"
                  disabled={submitting || isLoading}
                  // required
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Vapour Tap')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Vapour Tap')?.Significant}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                p={2}
                display="flex"
                justifyContent="center" i
                alignItems="center"
              // minHeight="100vh"
              >
                Performance Parameters
              </Box>
            </Grid>
            <Grid container item xs={12} md={10}>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Dose Concentration"
                  type="number"
                  normalize={lower}
                  autoSelectIfOnlyOneParameter
                  label="Dose Concentration (g)"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Dose Concentration')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Dose Concentration')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Dose Concentration')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Dose Concentration')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Dose Concentration')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Dose Concentration')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Dose Concentration')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Dose Concentration')?.Significant}
                />
              </Grid>
              {/* <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                                    showSignificant={showSignificant}
                  name="Spray duration"
                  type="number"
                  normalize={lower}
                  label="Spray duration (sec)"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Spray duration')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Spray duration')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Spray duration')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Spray duration')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Spray duration')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Spray duration')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Spray duration')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Spray duration')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                                    showSignificant={showSignificant}
                  name="Spray Rate"
                  type="number"
                  normalize={lower}
                  label="Spray Rate (g/s)"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Spray Rate')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Spray Rate')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Spray Rate')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Spray Rate')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Spray Rate')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Spray Rate')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Spray Rate')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Spray Rate')?.Significant}
                />
              </Grid> */}
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Spray pattern"
                  type="select"
                  label="Spray pattern"
                  // autoSelectIfOnlyOneParameter
                  disabled={submitting || isLoading}
                  // required
                  validate={dynamicValidator}
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Spray pattern')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Spray pattern')?.Categories}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Spray pattern')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Spray pattern')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Cone pattern"
                  validate={dynamicValidator}
                  type="select"
                  label="Cone pattern"
                  // autoSelectIfOnlyOneParameter
                  disabled={submitting || isLoading}
                  // required
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Cone pattern')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Cone pattern')?.Categories}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Cone pattern')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Cone pattern')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Average Droplet Size"
                  type="number"
                  normalize={lower}
                  label="Average Droplet Size"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Average Droplet Size')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Average Droplet Size')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Average Droplet Size')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Average Droplet Size')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Average Droplet Size')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Average Droplet Size')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Average Droplet Size')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Average Droplet Size')?.Significant}
                // required
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box
                p={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              // minHeight="100vh"
              >
                Bioefficacy Parameters
              </Box>
            </Grid>
            <Grid container item xs={12} md={10}>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Insect Tested"
                  autoSelectIfOnlyOneParameter
                  type="select"
                  label="Insect Tested"
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Insect Tested')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Insect Tested')?.Categories}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  // required={returnObjectFromArray(modelData.KT50.Categorical, 'Insect Tested')?.Significant || returnObjectFromArray(modelData.M24.Categorical, 'Insect Tested')?.Significant}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Insect Tested')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Insect Tested')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={renderSelectInput2}
                  showSignificant={showSignificant}
                  name="Protocol"
                  // autoSelectIfOnlyOneParameter
                  type="select"
                  label="Protocol"
                  list={returnObjectFromArray(modelData.KT50.Categorical, 'Protocol')?.Categories || returnObjectFromArray(modelData.M24.Categorical, 'Protocol')?.Categories}
                  disabled={submitting || isLoading}
                  validate={dynamicValidator}
                  // required={returnObjectFromArray(modelData.KT50.Categorical, 'Protocol')?.Significant || returnObjectFromArray(modelData.M24.Categorical, 'Protocol')?.Significant}
                  significantkt50={returnObjectFromArray(modelData.KT50.Categorical, 'Protocol')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Categorical, 'Protocol')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Mosquito Insertion Time"
                  type="number"
                  normalize={lower}
                  label="Mosquito Insertion Time (0,2,4 hrs, etc)"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Mosquito Insertion Time')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Mosquito Insertion Time')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Mosquito Insertion Time')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Mosquito Insertion Time')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Mosquito Insertion Time')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Mosquito Insertion Time')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Mosquito Insertion Time')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Mosquito Insertion Time')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Temperature"
                  type="number"
                  normalize={lower}
                  label="Temperature"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Temperature')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Temperature')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Temperature')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Temperature')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Temperature')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Temperature')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Temperature')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Temperature')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Humidity"
                  type="number"
                  normalize={lower}
                  label="Humidity"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Humidity')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Humidity')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Humidity')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Humidity')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Humidity')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Humidity')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Humidity')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Humidity')?.Significant}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={PredictiveAnalysisFieldInput}
                  showSignificant={showSignificant}
                  name="Age of Insects"
                  type="number"
                  normalize={lower}
                  label="Age of Insects (days)"
                  disabled={submitting || isLoading}
                  required={returnObjectFromArray(modelData.KT50.Numerical, 'Age of Insects')?.Significant || returnObjectFromArray(modelData.M24.Numerical, 'Age of Insects')?.Significant}
                  min={returnObjectFromArray(modelData.KT50.Numerical, 'Age of Insects')?.Min || returnObjectFromArray(modelData.M24.Numerical, 'Age of Insects')?.Min}
                  max={returnObjectFromArray(modelData.KT50.Numerical, 'Age of Insects')?.Max || returnObjectFromArray(modelData.M24.Numerical, 'Age of Insects')?.Max}
                  // Validation for all fields
                  validate={dynamicValidator}
                  significantkt50={returnObjectFromArray(modelData.KT50.Numerical, 'Age of Insects')?.Significant}
                  significantm24={returnObjectFromArray(modelData.M24.Numerical, 'Age of Insects')?.Significant}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </form>
  );
};

PredictiveAnalysisForm = reduxForm({
  form: 'PREDICTIVE_ANALYSIS_FORM',
  validate,
})(PredictiveAnalysisForm);

export default PredictiveAnalysisForm;
