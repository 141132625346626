import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';

class AppWrapper extends Component {
  render() {
    const { children } = this.props;
    return (
      <Box sx={{
        // backgroundColor: '#ebfbff',
        display: 'flex',
        // flexGrow: 1,
        // height: '100%',
      }}
      >
        <CssBaseline />
        <Header />
        <Box
          sx={{
            backgroundColor: '#fff',
            flexGrow: 1,
            minHeight: '100vh',
            pb: 5,
          }}
          style={{ paddingTop: '0px' }}
        >
          {children}
        </Box>
      </Box>
    );
  }
}

export default withRouter(AppWrapper);
