import { takeEvery, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import API from '../../api';
import * as types from '../types';

function* addNewUser(action) {
  try {
    const response = yield call(
      API.addNewUser,
      action.payload.values,
    );
    yield put({
      type: types.ADD_USER_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.ADD_USER_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* getUsers(action) {
  try {
    const response = yield call(
      API.getUsers,
    );
    // Convert response data list into appropriate form for dynamicForm Input
    // Sort elements for dynamicFormInput
    // for (let i = 0; i < arrayObj.length; i += 1) {
    //   arrayObj[i].dishes = arrayObj[i].Dishes.map(({ id, name, Order_Dish }) => ({ quantity: Order_Dish.quantity, name: id, dish:name }));
    //   delete arrayObj[i].Dishes;
    // }
    yield put({
      type: types.GET_USERS_SUCCESS,
      payload: {
        dataList: response.data.data,
      },
    });
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_USERS_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* editUser(action) {
  try {
    const response = yield call(
      API.editUser,
      action.payload.values,
    );
    yield put({
      type: types.EDIT_USER_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.EDIT_USER_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* deleteUser(action) {
  try {
    const response = yield call(
      API.deleteUser,
      action.payload.values,
    );
    yield put({
      type: types.DELETE_USER_SUCCESS,
    });
    if (response && response.data && response.data.message) {
      yield call(toast.success, response.data.message);
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.DELETE_USER_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

function* getUserAccessData(action) {
  try {
    const response = yield call(
      API.getAccessData,
      action.payload.values
    );
    // Convert response data list into appropriate form for dynamicForm Input
    // Sort elements for dynamicFormInput
    // for (let i = 0; i < arrayObj.length; i += 1) {
    //   arrayObj[i].dishes = arrayObj[i].Dishes.map(({ id, name, Order_Dish }) => ({ quantity: Order_Dish.quantity, name: id, dish:name }));
    //   delete arrayObj[i].Dishes;
    // }
    yield put({
      type: types.GET_USER_ACCESS_DATA_SUCCESS,
      payload: {
        data: response.data.data[0],
      },
    });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_USER_ACCESS_DATA_FAILURE,
    });
    if (err.response && err.response.data) {
      yield call(toast.error, err.response.data.message);
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeEvery(types.ADD_USER_REQUEST, addNewUser);
  yield takeEvery(types.GET_USERS_REQUEST, getUsers);
  yield takeEvery(types.EDIT_USER_REQUEST, editUser);
  yield takeEvery(types.DELETE_USER_REQUEST, deleteUser);
  yield takeEvery(types.GET_USER_ACCESS_DATA_REQUEST, getUserAccessData);
}
