import React from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';

const ErrMsg = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  marginTop: '0.25rem',
  fontSize: '80%',
  color: '#dc3545',
}));

const CheckboxInput = ({
  meta: { touched, error, warning },
  input: { onChange, value },
  label,
}) => {
  return (
    <>
      <Box my={2}>
        <Grid container>
          <Grid item xs={12} sm={3} style={{ paddingTop: '10px' }}>
            <Typography component="h2">
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormControlLabel
              label={""}
              key={label}
              value={value}
              control={<Checkbox
                value={value}
                onChange={onChange}
                checked={value}
                sx={{
                  color: indigo[800],
                  '&.Mui-checked': {
                    color: indigo[600],
                  },
                }}
              />}
            />
          </Grid>
        </Grid>
      </Box>
      {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
    </>
  )
};

export default CheckboxInput;
