import { takeEvery, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import API from '../../api';

import * as types from '../types';

// function* login(action) {
//   try {
//     const auth = firebase.auth();
//     yield call(
//       [auth, auth.signInWithEmailAndPassword],
//       action.payload.email,
//       action.payload.password,
//     );
//     yield put({
//       type: types.LOGIN_SUCCESS,
//     });

//     yield call(toast.success, 'Logged in');
//     if (action.meta.onSuccess) {
//       yield call(action.meta.onSuccess);
//     }
//   } catch (err) {
//     console.log('error');
//     console.log(err);
//     yield put({
//       type: types.LOGIN_FAILURE,
//     });
//     yield call(toast.error, err.message);
//   }
// }

// function* logout(action) {
//   try {
//     const auth = firebase.auth();
//     yield call(
//       [auth, auth.signOut],
//     );
//     yield put({
//       type: types.LOGOUT_SUCCESS,
//     });
//     yield call(toast.warn, 'Logged out successfully');
//     if (action.meta.onSuccess) {
//       yield call(action.meta.onSuccess);
//     }
//   } catch (err) {
//     console.log('error');
//     console.log(err);
//     yield put({
//       type: types.LOGOUT_FAILURE,
//     });
//     yield call(toast.error, err.message);
//   }
// }

function* recover(action) {
  try {
    // yield call(onAuthStateChanged);
    // yield put({
    //   type: types.LOGIN_SUCCESS,
    // });
    // if (action.meta.onCompletion) {
    //   yield call(action.meta.onCompletion);
    // }
    const response = yield call(
      API.recover,
    );
    if (response.status.statusCode === "auth-200") {
      yield put({
        type: types.LOGIN_SUCCESS,
        payload: {
          name: response.data.name,
          email: response.data.preferred_username,
          preferred_username: response.data.preferred_username,
          isAuth: true,
          error: null,
          status: null,
          role: response.data.role,
        }
      });
    }
    // updateUserInfo({
    //   name: response.data.name,
    //   email: response.data.preferred_username,
    //   preferred_username: response.data.preferred_username,
    //   isAuth: true,
    //   error: null,
    //   status: null,
    //   role: response.data.role,
    // });
    // let location = config.hardCoded.homeLocation;
    // const location = window.location.pathname;
    // setAuthResolved(true);
    // history.push(location);
    // if (response.errorCode === 401) {
    //   updateUserInfo({
    //     name: "",
    //     email: "",
    //     preferred_username: "",
    //     isAuth: false,
    //     error: "UNAUTHORIZED",
    //   });
    // }
    if (action.meta.onCompletion) {
      yield call(action.meta.onCompletion);
    }
    yield call(toast.success, 'Logged in');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.LOGIN_FAILURE,
    });
    if (action.meta.onFailure) {
      yield call(action.meta.onFailure);
    }
    // yield call(toast.error, err.message);
  }
}

export default function* () {
  // yield takeEvery(types.LOGIN_REQUEST, login);
  // yield takeEvery(types.LOGOUT_REQUEST, logout);
  yield takeEvery(types.CHECK_USER_SESSION, recover);
}
