import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import ProjectForm from '../../forms/Project';

const CreateProjectModalWithButton = (props) => {
  const {
    buttonText,
    modalHeaderText,
    handleSubmit,
    masterDataList,
    setModal,
    showModal,
    buttonType,
  } = props;
  return (
    <>
      {(buttonType && buttonType === 'icon') ? (
        <IconButton color="primary" onClick={() => { setModal(true); }}>
          <AddIcon />
        </IconButton>
      ) : (
        <Button variant="contained" color="primary" onClick={() => { setModal(true); }}>
          <AddIcon />
          {' '}
          {buttonText}
        </Button>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showModal}
        onClose={() => { setModal(false); }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {modalHeaderText}
            </Box>
            <Box>
              <IconButton onClick={() => { setModal(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ minHeight: '50vh' }}>
          <ProjectForm
            // initialValues={{ data: masterDataList }}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateProjectModalWithButton;
