import * as types from '../types';

export const login = (email, password, onSuccess) => ({
  type: types.LOGIN_REQUEST,
  payload: {
    email,
    password,
  },
  meta: {
    onSuccess,
  },
});

export const logout = onSuccess => ({
  type: types.LOGOUT_REQUEST,
  meta: {
    onSuccess,
  },
});

export const checkUserSession = (onCompletion, onFailure) => ({
  type: types.CHECK_USER_SESSION,
  meta: {
    onCompletion,
    onFailure,
  },
});

export const updateUserInfoLoginSuccess = (name, email, preferred_username, isAuth, error, status, role, errorCode, onSuccess) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    name,
    email,
    preferred_username,
    isAuth,
    error,
    status,
    role,
    errorCode,
  },
  meta: {
    onSuccess
  }
});

export const updateUserInfoLoginFailure = (name, email, preferred_username, isAuth, error, status, role, errorCode, onFailure) => ({
  type: types.LOGIN_FAILURE,
  payload: {
    name,
    email,
    preferred_username,
    isAuth,
    error,
    status,
    role,
    errorCode,
  },
  meta: {
    onFailure
  }
});