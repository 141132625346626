import axios from 'axios';
import file from './endpoints/file';
import model from './endpoints/model';
import exchangerate from './endpoints/exchangerate';
import masterdata from './endpoints/masterdata';
import project from './endpoints/project';
import user from './endpoints/user';
import regulatoryLimit from './endpoints/regulatoryLimit';
import auth from './endpoints/auth';

// Set default headers for all API Requests(Set Headers as per back-end settings)
// axios.defaults.headers['Content-Type'] = 'application/json';
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.withCredentials = true;

// const token = localStorage.getItem('accessToken');
// if (token) {
//   axios.defaults.headers['x-access-token'] = token;
// }
// Set the AUTH token for any request
// instance.interceptors.request.use(function (config) {
//   // config.headers.Authorization =  token ? `Bearer ${token}` : '';
//   return config;
// });

export default {
  ...file,
  ...model,
  ...exchangerate,
  ...masterdata,
  ...project,
  ...user,
  ...regulatoryLimit,
  ...auth,
};
