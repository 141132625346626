import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Field, reduxForm, FieldArray, change } from 'redux-form';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../components/Input';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { renderSelectInput } from '../components/SelectInput';
import { connect, useDispatch, useSelector } from 'react-redux';

const validate = (values) => {
  const errors = {};
  // if (isEmpty(values.email)) {
  //   errors.email = 'Enter email';
  // }
  // if (isEmpty(values.password)) {
  //   errors.password = 'Enter password';
  // }
  return errors;
};

let RegulatoryLimitsForm = (props) => {
  const {
    handleSubmit,
    submitting,
    isEdit,
  } = props;
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const dispatch = useDispatch();

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={`active`}
        type="string"
        label="Active"
        component={Input}
        required
      />
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          BRAZIL
        </Typography>
        <Field
          name={`brazil.banned`}
          type="string"
          label="Banned"
          component={renderSelectInput}
          list={[
            {
              label: 'Allowed',
              value: 'allowed',
            },
            {
              label: 'Banned',
              value: 'banned',
            },
          ]}
        />
        <Field
          name={`brazil.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          AUSTRALIA
        </Typography>
        <Field
          name={`australia.banned`}
          type="string"
          label="Banned"
          component={renderSelectInput}
          list={[
            {
              label: 'Allowed',
              value: 'allowed',
            },
            {
              label: 'Banned',
              value: 'banned',
            },
          ]}
        />
        <Field
          name={`australia.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          INDIA
        </Typography>
        <Field
          name={`india.banned`}
          type="string"
          label="Banned"
          component={renderSelectInput}
          list={[
            {
              label: 'Allowed',
              value: 'allowed',
            },
            {
              label: 'Banned',
              value: 'banned',
            },
          ]}
        />
        <Field
          name={`india.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          MALAYSIA
        </Typography>
        <Field
          name={`malaysia.banned`}
          type="string"
          label="Banned"
          component={renderSelectInput}
          list={[
            {
              label: 'Allowed',
              value: 'allowed',
            },
            {
              label: 'Banned',
              value: 'banned',
            },
          ]}
        />
        <Field
          name={`malaysia.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          SOUTH AFRICA
        </Typography>
        <Field
          name={`southafrica.banned`}
          type="string"
          label="Banned"
          component={renderSelectInput}
          list={[
            {
              label: 'Allowed',
              value: 'allowed',
            },
            {
              label: 'Banned',
              value: 'banned',
            },
          ]}
        />
        <Field
          name={`southafrica.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Box sx={{ border: '0.5px solid grey', p: 2, m: 2 }}>
        <Typography variant="h6">
          GLOBAL
        </Typography>
        <Field
          name={`global.upperConcentrationLimit`}
          type="number"
          label="Upper Concentration Limit (in %)"
          component={Input}
        />
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        Submit
      </Button>
    </form >
  );
};

RegulatoryLimitsForm = reduxForm({
  form: 'REGULATORY_LIMITS_FORM',
  validate,
})(RegulatoryLimitsForm);

export default RegulatoryLimitsForm;
