import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory, Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CreateRegulatoryLimitModalWithButton1 from '../../components/createButtonModals/RegulatoryLimit';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  editRegulatoryLimit,
  deleteRegulatoryLimit,
  getRegulatoryLimits,
  addNewRegulatoryLimit,
} from '../../services/RegulatoryLimit/actions';
import {
  getUserAccessData,
} from '../../services/User/actions';
import RegulatoryLimitForm from '../../forms/RegulatoryLimit';
import DeleteModal from '../../components/modals/DeleteModal';
import { Loading } from '../../components/Loading';
import {
  getMasterData,
} from '../../services/MasterData/actions';
import reckitt_logo from '../../assets/Asset_1.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '80px',
  // overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '180px',
  overflowWrap: 'anywhere',
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F5F5',
  },
}));

const RegulatoryLimits1 = () => {
  const dispatch = useDispatch();
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setModal] = useState(false);

  const onEditAppointment = (values) => {
    dispatch(
      editRegulatoryLimit(values, () => {
        dispatch(
          getRegulatoryLimits(),
        );
      }),
    );
  };

  const onSubmitAppointment = (values) => {
    dispatch(
      addNewRegulatoryLimit(values, () => {
        setModal(false);
        dispatch(
          getRegulatoryLimits()
        )
      }),
    );
  };

  const onDeleteAppointment = (values) => {
    dispatch(
      deleteRegulatoryLimit(values, () => {
        dispatch(
          getRegulatoryLimits(),
        );
      }),
    );
  };

  const email = useSelector((state) => state.Auth.email);
  // Get values from redux store
  useEffect(() => {
    dispatch(
      getUserAccessData({ email: email }, () => {
        dispatch(
          getRegulatoryLimits()
        );
        dispatch(
          getMasterData()
        )
      })
    )
  }, [dispatch]);
  const regulatoryLimitList = useSelector((state) => state.RegulatoryLimit.regulatoryLimitList);
  const isLoading = useSelector((state) => state.Project.isLoading);
  const userLoading = useSelector((state) => state.User.isLoading);
  const userData = useSelector((state) => state.User.userData);
  let treatmentCounter = regulatoryLimitList.length;
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Regulatory Limits

          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px', fontStyle: 'normal' }} underline="hover" to={{}}>
            Regulatory Limits
          </Link>
        </Breadcrumbs>
      </Box>
      <Box p={2}>
        {userLoading ? (
          <Loading />
        ) : (
          <Box>
            {userData?.regulatorylimit === true ? (
              <Box p={2}>
                <CreateRegulatoryLimitModalWithButton1
                  handleSubmit={onSubmitAppointment}
                  size="sm"
                  buttonText="CREATE NEW Regulatory Limit"
                  modalHeaderText="Add Regulatory Limit"
                  withoutClient
                  setModal={setModal}
                  showModal={showModal}
                />
                <Box pt={10}>
                  <Grid container>
                    {(isLoading) ? (
                      <Grid item xs={12} alignItems="center">
                        <Loading />
                      </Grid>
                    ) : (
                      <Grid item xs={12} alignItems="center">
                        <TableContainer sx={{ display: 'block', overflowX: 'auto' }}>
                          <Table size="small" aria-label="customized table" sx={{ display: 'block', overflowX: 'auto' }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell rowSpan={3} align="center" padding="none" />
                                <StyledTableCell rowSpan={3} align="center" padding="none">#</StyledTableCell>
                                <StyledTableFixedWithCell rowSpan={3} padding="none" align="center">Active</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={2}>Brazil</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={2}>Australia</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={2}>India</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={2}>Malaysia</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" colSpan={2}>South Africa</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >Global</StyledTableFixedWithCell>
                              </TableRow>
                              <TableRow>
                                <StyledTableFixedWithCell padding="none" align="center" >Banned</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >Banned</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >Banned</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >Banned</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center" >Banned</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                                <StyledTableFixedWithCell padding="none" align="center">Upper Concentration Limit(in %)</StyledTableFixedWithCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {regulatoryLimitList.map((row) => (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell padding="none" align="center" component="th" scope="row">
                                    <EditIcon
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                      onClick={() => {
                                        setModalData(row); // set modal data to currently clicked row
                                        setUpdateModal(true);
                                        // handleChange(data[tableMeta.rowIndex]);
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <>
                                      {treatmentCounter--}
                                    </>
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.active && row.active}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.brazil.banned && row.brazil.banned}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.brazil?.upperConcentrationLimit && row.brazil?.upperConcentrationLimit}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.australia.banned && row.australia.banned}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.australia?.upperConcentrationLimit && row.australia?.upperConcentrationLimit}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.india.banned && row.india.banned}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.india?.upperConcentrationLimit && row.india?.upperConcentrationLimit}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.malaysia.banned && row.malaysia.banned}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.malaysia?.upperConcentrationLimit && row.malaysia?.upperConcentrationLimit}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.southafrica.banned && row.southafrica.banned}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.southafrica?.upperConcentrationLimit && row.southafrica?.upperConcentrationLimit}
                                  </StyledTableCell>
                                  <StyledTableCell padding="none" align="center">
                                    {row.global?.upperConcentrationLimit && row.global.upperConcentrationLimit}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ paddingTop: '15px' }}
              >
                <Typography variant="h5">
                  Access is denied. Please contact admin.
                </Typography>
              </Grid>
            )}
          </Box>
        )}
      </Box>
      <Dialog
        maxWidth="md"
        fullWidth
        open={showUpdateModal}
        onClose={() => { setUpdateModal(false); setModalData({}); }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Button
                color="error"
                variant="contained"
                onClick={() => { setUpdateModal(false); setDeleteModal(true); }}
              >
                Delete
              </Button>
            </Box>
            <Box>
              <IconButton onClick={() => { setUpdateModal(false); setDeleteModal(false); }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <RegulatoryLimitForm
            initialValues={modalData}
            onSubmit={onEditAppointment}
          />
        </DialogContent>
      </Dialog>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setModalData={setModalData}
        setDeleteModal={setDeleteModal}
        handleDelete={onDeleteAppointment}
        setUpdateModal={setUpdateModal}
        modalData={modalData}
      />
    </SideMenuAppWrapper>
  );
};

export default RegulatoryLimits1;
