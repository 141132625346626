import * as types from '../types';

export const submitUploadFile = (values, onSuccess, onSuccess1) => ({
  type: types.UPLOAD_FILE_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
    onSuccess1,
  },
});

export const downloadFile = (filePath) => ({
  type: types.DOWNLOAD_FILE_REQUEST,
  payload: {
    filePath,
  }
});