import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  projectList: [],
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_PROJECT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.ADD_PROJECT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.ADD_PROJECT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.GET_PROJECTS_REQUEST: return {
      ...state,
      isLoading: true,
      projectList: [],
    };
    case types.GET_PROJECTS_SUCCESS: return {
      ...state,
      isLoading: false,
      projectList: action.payload.dataList,
    };
    case types.GET_PROJECTS_FAILURE: return {
      ...state,
      isLoading: false,
      projectList: [],
    };
    case types.EDIT_PROJECT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.EDIT_PROJECT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.EDIT_PROJECT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_PROJECT_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DELETE_PROJECT_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DELETE_PROJECT_FAILURE: return {
      ...state,
      isLoading: false,
    };
    default: return state;
  }
};
