import React from 'react';
import Container from '@mui/material/Container';

// const drawerWidth = 240;

const SideMenuAppWrapper = (props) => {
  const { children } = props;
  return (
    <main>
      {/* <div style={{
        paddingLeft: '100px',
        paddingTop: '40px',
      }}
      /> */}
      {/* <Container disableGutters maxWidth="xl" sx={{ pl: 10, pr: 2 }}> */}
      {children}
    </main>
  );
};

export default SideMenuAppWrapper;
