import React from 'react';
import { Field, reduxForm, FieldArray, formValueSelector, change } from 'redux-form';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RenderComponentAdd from '../components/DynamicCostInput';
import Input from '../components/Input';
import { renderSelectInput } from '../components/SelectInput';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
// import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';

let DynamicModelSelectionForm = (props) => {
  const {
    handleSubmit,
    costing,
    submitting,
    fields,
    isLoading,
    masterDataList,
    selectedCountry,
  } = props;
  const exchangeRateList = useSelector((state) => state.ExchangeRate.exchangeRateList);

  let totalAddedAmount1 = 0;
  let totalAddedAmount2 = 0;
  let gbpAmount = 0;
  if (costing && costing.length > 0) {
    costing.forEach((row) => {
      if (Number(row.amount1) > 0) {
        totalAddedAmount1 += row.amount1;
      }
      if (Number(row.amount2) > 0) {
        totalAddedAmount2 += row.amount2;
      }
    });
  }
  if (selectedCountry) {
    for (let k = 0; k < exchangeRateList.length; k++) {
      if (exchangeRateList[k]['country'] === selectedCountry.value) {
        gbpAmount = exchangeRateList[k]['rate'] * totalAddedAmount1;
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <FieldArray
            name="costing"
            masterDataList={masterDataList}
            selectedCountry={selectedCountry}
            component={RenderComponentAdd}
            costingData={costing}
            label="Cost Entry"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 2, m: 2, border: '1px solid grey' }}>
            <Typography sx={{ fontSize: '18' }}>
              Total Cost:
              <Box>
                Country Cost:
                {' '}
                {Number(totalAddedAmount1).toFixed(3)}
              </Box>
              <Box>
                GBP:
                {' '}
                {Number(gbpAmount).toFixed(3)}
              </Box>
              {/* <Box>
                USD Cost :
                {totalAddedAmount2}
              </Box> */}
            </Typography>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting || isLoading}
          >
            CALCULATE COST
          </Button>
        </Grid> */}
      </Grid>
    </form>
  );
};

DynamicModelSelectionForm = reduxForm({
  form: 'DYNAMIC_COST_SELECTION_FORM',
  enableReinitialize: true,
})(DynamicModelSelectionForm);
const selector = formValueSelector('DYNAMIC_COST_SELECTION_FORM');

DynamicModelSelectionForm = connect(state => { // eslint-disable-line
  const costing = selector(state, 'costing');
  return {
    costing,
  };
})(DynamicModelSelectionForm);

export default DynamicModelSelectionForm;
