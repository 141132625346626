import * as types from '../types';

export const getExchangeRates = () => ({
  type: types.GET_EXCHANGE_RATES_REQUEST,
});

export const addNewExchangeRate = (values, onSuccess) => ({
  type: types.ADD_EXCHANGE_RATE_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const editExchangeRate = (values, onSuccess) => ({
  type: types.EDIT_EXCHANGE_RATE_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteExchangeRate = (values, onSuccess) => ({
  type: types.DELETE_EXCHANGE_RATE_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});
