import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const RenderField = ({
  input,
  placeholder,
  defaultValue,
  type,
  label,
  name,
  disabled,
  required,
  meta: { touched, error, warning },
  min,
  max,
  significantkt50,
  significantm24,
}) => {
  useEffect(() => {
    if (defaultValue) {
      input.onChange(defaultValue);
    }
  }, [defaultValue])
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography color="initial">
          {label}
        </Typography>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
          // label={label}
          type={type}
          id={name}
          variant="standard"
          margin="normal"
          size="small"
          placeholder={placeholder || `Enter ${label}`}
          disabled={disabled}
          required={required}
          autoComplete="off"
          error={(touched && !!error)}
          helperText={touched && (error || warning)}
        />
      </Stack>
      {/* {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))} */}
    </>
  );
};

export default RenderField;
