import React from 'react';
import Button from '@mui/material/Button';
import { Field } from 'redux-form';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import { renderSelectInput } from './SelectInput';
import Input from './Input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableFooter, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { renderSelectInput4 } from './SelectInput';

const renderComponentAdd = ({
  masterDataList, fields, isLoading, submitting, label, meta: { touched, error }, arrayData,
}) => (
  <Box>
    <Grid container>
      <Grid item xs={12} sm={3} style={{ paddingTop: '5px' }}>
        <Typography color="initial">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <div>
          <Button color="secondary" variant="contained" size="medium" onClick={() => fields.push({})}>
            Add
          </Button>
          {touched && error && <span>{error}</span>}
        </div>
      </Grid>
      {fields.length > 0 && (
        <Grid item xs={12}>
          {fields.map((member, index) => {
            return (
              <Box key={`${member}${index + 1}`}>
                <Stack direction="row" spacing={0.5} justifyContent="space-evenly"
                  alignItems="center">
                  {index + 1}
                  <Field
                    name={`${member}.classification`}
                    list={masterDataList}
                    label="Classification"
                    tempKey="classification"
                    type="string"
                    component={renderSelectInput4}
                    required
                    getunique
                  />
                  <Field
                    getunique
                    name={`${member}.rawmaterial`}
                    label="Raw Material"
                    type="string"
                    list={masterDataList}
                    component={renderSelectInput4}
                    tempKey="rawmaterial"
                    appendPurityPercentage
                    required
                    filterkey1={arrayData ? arrayData[index]?.classification : undefined}
                  />
                  <Field
                    name={`${member}.tds`}
                    label="TDS"
                    type="string"
                    component={renderSelectInput4}
                    list={masterDataList}
                    tempKey="tds"
                    required
                    filterkey1={arrayData ? arrayData[index]?.classification : undefined}
                    filterkey2={arrayData ? arrayData[index]?.rawmaterial : undefined}
                  />
                  <Field
                    name={`${member}.kg`}
                    label="KG"
                    type="number"
                    component={Input}
                    required
                  />
                  <Field
                    name={`${member}.cost`}
                    type="number"
                    label="Cost"
                    component={Input}
                    required
                  />
                  <Field
                    name={`${member}.gbpcost`}
                    type="number"
                    label="GBP COST"
                    component={Input}
                    required
                  />
                  <ClearIcon
                    style={{ marginTop: '10px', cursor: 'pointer' }}
                    onClick={() => fields.remove(index)}
                  />
                </Stack>
              </Box>
            )
          })}
        </Grid>
      )}
    </Grid>
  </Box>
);

export default renderComponentAdd;
