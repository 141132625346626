import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
// import Energy from '../application/fonts/Energy-Regular.ttf';

const rawTheme = createTheme({
  palette: {
    primary: {
      light: '#E72388',
      main: '#E72388',
      dark: '#9e195e',
      contrastText: '#fff',
    },
    secondary: orange,
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700],
    },
    text: {
      primary: '#273653',
      secondary: '#fff',
      disabled: '#02609b',
      hint: '#02609b',
    },
  },
  typography: {
    allVariants: {
      color: 'black',
    },
    fontFamily: [
      'HindSiliguri',
      'HindSiliguri-Regular',
      // 'Energy',
      // 'Roboto',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 500, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: [
      'HindSiliguri',
      'HindSiliguri-Regular',
      // 'Roboto',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [energyWeb],
  //     },
  //   },
  // },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary,
  // textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  overrides: {
    // Mui datatable styling
    MUIDataTableSelectCell: {
      checkboxRoot: {
        color: '#273653 !important',
        '&$checked': {
          color: '#f44336 !important',
          background: '#000'
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,
        border: "none",
        outline: "none"
      },
    },
    MuiPaper: {
      outlined: {
        borderColor: grey[500],

      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "red",
      },
    },
  },
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      // default: '#ffffad',
      placeholder: grey[200],
      fontSize: '10px'
    },
  },
  typography: {
    ...rawTheme.typography,
    useNextVariants: true,
    fontHeader,
    // button: {
    // ...rawTheme.button,
    // background: '#38aca1',
    // },
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },

  },
};

export default theme;
