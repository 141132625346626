import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Alert,
  Paper,
  Breadcrumbs,
} from '@mui/material';
import { indigo } from '@mui/material/colors';
import DataModelDisplay from '../../components/DataModelDisplay';
import {
  getActivesList, showAvailableModels,
} from '../../services/Model/actions';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { Loading } from '../../components/Loading';
import { Link } from 'react-router-dom';
import '../../assets/stylesheet/kpi-pred.css';
import reckitt_logo from '../../assets/Asset_1.png';

const PredictiveAnalysisPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // recall if any of the parameters are changed
    dispatch(
      getActivesList(),
    );
  }, [dispatch]);

  const activesList = useSelector((state) => state.Model.activesList);
  const modelsList = useSelector((state) => state.Model.modelsList);
  const isGettingModelsLoading = useSelector((state) => state.Model.isGettingModelsLoading);
  const [predictiveAnalysisButtonVisible, showPredictiveAnalysisButton] = useState(false);
  const [predictiveAnalysisButtonVisible1, showPredictiveAnalysisButton1] = useState(false);
  const [active1Data, setActive1Data] = useState('');
  const [active2Data, setActive2Data] = useState('');
  const [active3Data, setActive3Data] = useState('');
  const [value, setValue] = React.useState('');
  useEffect(() => {
    // recall if any of the parameters are changed
    if (modelsList && modelsList.length > 0) {
      if (predictiveAnalysisButtonVisible) {
        history.push(`/predictiveAnalysis/${modelsList[0].id}`);
      }
    }
  }, [modelsList]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleSaveModelDataForPrediction = (uid) => {
    history.push(`/predictiveAnalysis/${uid}`);
  };

  const showAvailableModelsClick = () => {
    showPredictiveAnalysisButton(true);
    dispatch(
      showAvailableModels(active1Data, active2Data, active3Data, () => {
      }),
    );
  };

  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Select Model For Prediction

          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to="/aerosol">
            Aerosol
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to={{}}>
            Bioefficacy Prediction / Select Model For Prediction
          </Link>
        </Breadcrumbs>
        <>
          <Grid container>
            <Grid item xs={12}>
              <Paper sx={{ mt: 5, boxShadow: 'none' }} className='input-bg'>
                <Box p={1}>
                  <Grid container>
                    <Grid item xs={12} md={4} p={2} >
                      <Typography align="center" variant="body2" mb={1}>
                        Select Active 1
                      </Typography>
                      <Select
                        value={active1Data}
                        onChange={(temp => { setActive1Data(temp); showPredictiveAnalysisButton1(true) })}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={2}>
                      <Typography align="center" variant="body2" mb={1}>
                        Select Active 2
                      </Typography>
                      <Select
                        value={active2Data}
                        onChange={(temp => { setActive2Data(temp); showPredictiveAnalysisButton1(true) })}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={2}>
                      <Typography align="center" variant="body2" mb={1}>
                        Select Active 3
                      </Typography>
                      <Select
                        value={active3Data}
                        onChange={(temp => { setActive3Data(temp); showPredictiveAnalysisButton1(true) })}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={12} p={2} >
                      <Button fullWidth disabled={!predictiveAnalysisButtonVisible1} onClick={() => { showAvailableModelsClick() }} variant="contained" style={{ textTransform: 'capitalize', border: 'none', outline: 'none', background: '#F10086' }}>
                        Input Values
                      </Button>
                      {/* {predictiveAnalysisButtonVisible && (
                          <Button disabled={value === '' ? true : false} sx={{ marginLeft: '10px' }} onClick={() => { handleSaveModelDataForPrediction(); }} variant="contained" style={{ textTransform: 'capitalize' }}>
                            Go to Prediction
                          </Button>
                         */}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {isGettingModelsLoading && (
              <Loading />
            )}
            {!isGettingModelsLoading && modelsList.length > 0 ?
              (
                <>
                  {modelsList.map((member, index) => {
                    return (
                      <Grid item xs={12} key={index}>
                        <Paper
                          style={{ border: '1px solid #CECECE' }}
                          sx={{ marginTop: '20px' }}
                        >
                          {/* <Grid container sx={{ backgroundColor: '#F8EEF7' }}>
                            <Grid item md={12}>
                              <Box sx={{ float: 'right' }} >
                                <RadioGroup name="selectedModel" value={value} onChange={handleChange} row>
                                  <FormControlLabel label="Choose Model for Prediction" value={index} control={<Radio sx={{ color: indigo[800], '&.Mui-checked': { color: indigo[600], }, }} />} />
                                </RadioGroup>
                              </Box>
                            </Grid>
                          </Grid> */}
                          <DataModelDisplay modelData={member} nameAvailable />
                        </Paper>
                      </Grid>
                    )
                  })}
                </>
              ) : (
                <>
                  {predictiveAnalysisButtonVisible && !isGettingModelsLoading && (
                    <Grid fullWidth item xs={12} pt={5}>
                      <Alert severity="info">A prediction with this combination does not exist. Kindly try a different combination.</Alert>
                    </Grid>
                  )}
                </>
              )}
          </Grid>
        </>
      </Box>
    </SideMenuAppWrapper >
  );
};

export default PredictiveAnalysisPage;
