import { takeEvery, call, put, delay, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import firebase from '../../firebase';
// import 'firebase/firestore';
import * as types from '../types';
import API from '../../api';
// import 'firebase/storage';
import { isEmpty, toLower } from 'lodash';

function* saveSelectedModels(action) {
  try {
    const modelsList = [];
    for (let i = 0; i < action.payload.values.selected.length; i++) {
      if (action.payload.values.selected[i] === true) {
        const objectData = action.payload.modelData[i];
        objectData.name = action.payload.values.name[i];
        objectData.narration = action.payload.values.narration[i];
        // objectData.filePath = resp2;
        objectData.createdBy = action.payload.authEmail;
        // objectData.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
        // Add Models to firestore document
        // yield call(
        //   [addModelQuery, addModelQuery.add],
        //   objectData,
        // );
        modelsList.push(objectData);
        //const updateClientQuery = db.collection('cache').doc('data');

        // Add Individual actives to cache data document in firestore
        // for (let j = 0; j < objectData.Actives.length; j++) {
        //   if (objectData.Actives[j] !== "") {
        //     const temp = {
        //       actives: firebase.firestore.FieldValue.arrayUnion(
        //         objectData.Actives[j],
        //       ),
        //     };

        //     yield call(
        //       [updateClientQuery, updateClientQuery.update],
        //       temp,
        //     );
        //   }
        // }
      }
    }

    const response = yield call(
      API.saveModels,
      modelsList,
      action.meta.fileData,
    );

    // Upload File to Google Cloud Storage
    // Call POST API for Validation of uploaded file with a parameter which points to the location of the uploaded file
    // Call POST API of data generation
    // const storageRef = firebase.storage().ref();
    // const reports = action.meta.fileData.reports;

    // const folderName = Date.now().toString(36) + Math.random().toString(36).substr(2);
    // const reportFolderRef = storageRef.child(`${folderName}/${reports[0].name}`);
    // const file = reports[0];

    // // Store file in firebase cloud storage
    // yield call(
    //   [reportFolderRef, reportFolderRef.put],
    //   file,
    // );

    // // Get downloadurl of individual file after storage
    // const resp2 = yield call(
    //   [reportFolderRef, reportFolderRef.getDownloadURL],
    // );

    // const db = firebase.firestore();
    // const addModelQuery = db.collection('models');

    // for (let i = 0; i < action.payload.values.selected.length; i++) {
    //   if (action.payload.values.selected[i] === true) {
    //     const objectData = action.payload.modelData[i];
    //     objectData.name = action.payload.values.name[i];
    //     objectData.narration = action.payload.values.narration[i];
    //     objectData.filePath = resp2;
    //     objectData.createdBy = action.payload.authEmail;
    //     objectData.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
    //     // Add Models to firestore document
    //     yield call(
    //       [addModelQuery, addModelQuery.add],
    //       objectData,
    //     );

    //     const updateClientQuery = db.collection('cache').doc('data');

    //     // Add Individual actives to cache data document in firestore
    //     for (let j = 0; j < objectData.Actives.length; j++) {
    //       if (objectData.Actives[j] !== "") {
    //         const temp = {
    //           actives: firebase.firestore.FieldValue.arrayUnion(
    //             objectData.Actives[j],
    //           ),
    //         };

    //         yield call(
    //           [updateClientQuery, updateClientQuery.update],
    //           temp,
    //         );
    //       }
    //     }
    //   }
    // }
    if (response.data.data && response.data.data.length > 0) {
      yield call(toast.success, 'Duplicate models found for: ' + response.data.data.toString().split(","));
    } else {
      yield call(toast.success, 'Saved models successfully');
    }

    yield put({
      type: types.SAVE_SELECTED_MODELS_SUCCESS,
    });
    // on success
    yield delay(500);
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield call(toast.error, err.message);
    yield put({
      type: types.SAVE_SELECTED_MODELS_FAILURE,
    });
    if (action.meta.onCompletion) {
      yield call(action.meta.onCompletion);
    }
  }
}

function* getActivesList(action) {
  try {
    const response = yield call(
      API.getActives,
    );

    // const db = firebase.firestore();
    // const tempQuery = db.collection('cache').doc('data');

    // const response = yield call(
    //   [tempQuery, tempQuery.get],
    // );

    const tempActives = [];
    for (let i = 0; i < response.data.data.length; i++) {
      tempActives.push({
        label: response.data.data[i],
        value: response.data.data[i],
      });
    }
    yield put({
      type: types.GET_ACTIVES_LIST_SUCCESS,
      payload: {
        activesList: tempActives,
      },
    });

    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Got actives list successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_ACTIVES_LIST_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* showAvailableModels1(action) {
  try {
    const response = yield call(
      API.getModels,
    );
    // const db = firebase.firestore();
    // const tempABC = [];
    // if (action.payload.active1Data !== '' && !isEmpty(action.payload.active1Data) && action.payload.active1Data !== 'undefined' && action.payload.active1Data !== undefined) {
    //   tempABC.push(action.payload.active1Data.value);
    // }
    // if (action.payload.active2Data !== '' && !isEmpty(action.payload.active2Data) && action.payload.active2Data !== 'undefined' && action.payload.active2Data !== undefined) {
    //   tempABC.push(action.payload.active2Data.value);
    // }
    // if (action.payload.active3Data !== '' && !isEmpty(action.payload.active3Data) && action.payload.active3Data !== 'undefined' && action.payload.active3Data !== undefined) {
    //   tempABC.push(action.payload.active3Data.value);
    // }

    // let queryCall;
    // if (tempABC.length === 0) {
    //   queryCall = db
    //     .collection('models')
    //     .orderBy('createdAt', 'desc');
    // } else {
    //   queryCall = db
    //     .collection('models')
    //     .where('Actives', 'array-contains-any', tempABC);
    // }
    // const querySnapshot = yield call([queryCall, queryCall.get]);
    // const dataList = [];
    // const tempDataList = [];
    // yield all(
    //   querySnapshot.docs.map((doc) =>
    //     dataList.push({
    //       id: doc.id,
    //       ...doc.data()
    //     }),
    //   ),
    // );
    const dataList = response.data.data;
    const tempActives = [];
    const tempDataList = [];

    if (action.payload.active1Data !== '' && !isEmpty(action.payload.active1Data) && action.payload.active1Data !== 'undefined' && action.payload.active1Data !== undefined) {
      tempActives.push(action.payload.active1Data.value);
    }
    if (action.payload.active2Data !== '' && !isEmpty(action.payload.active2Data) && action.payload.active2Data !== 'undefined' && action.payload.active2Data !== undefined) {
      tempActives.push(action.payload.active2Data.value);
    }
    if (action.payload.active3Data !== '' && !isEmpty(action.payload.active3Data) && action.payload.active3Data !== 'undefined' && action.payload.active3Data !== undefined) {
      tempActives.push(action.payload.active3Data.value);
    }

    for (let i = 0; i < dataList.length; i++) {
      const allFounded = tempActives.every(ai => dataList[i].Actives.includes(ai));

      if (allFounded === true) {
        tempDataList.push(dataList[i]);
      }
    }
    yield put({
      type: types.SHOW_AVAILABLE_MODELS_1_SUCCESS,
      payload: {
        modelsList: tempDataList,
      },
    });

    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Got available models successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.SHOW_AVAILABLE_MODELS_1_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function arrayEquals(arr1, arr2) {
  // compare array lengths, if not equal then skip.
  if (arr1.length !== arr2.length) return false;

  // setup a variable of the array length
  let i = arr1.length;

  // iterate through every element in arr1 basically...
  while (i--) {
    // if arr2 doesn't include x element from arr1, return false
    if (!arr2.includes(arr1[i])) return false;

    // passes tests and checks out
  };
  return true;
}

function* showAvailableModels(action) {
  try {
    const response = yield call(
      API.getModels,
    );
    // const db = firebase.firestore();
    // const tempABC = [];
    // if (action.payload.active1Data !== '' && !isEmpty(action.payload.active1Data) && action.payload.active1Data !== 'undefined' && action.payload.active1Data !== undefined) {
    //   tempABC.push(action.payload.active1Data.value);
    // }
    // if (action.payload.active2Data !== '' && !isEmpty(action.payload.active2Data) && action.payload.active2Data !== 'undefined' && action.payload.active2Data !== undefined) {
    //   tempABC.push(action.payload.active2Data.value);
    // }
    // if (action.payload.active3Data !== '' && !isEmpty(action.payload.active3Data) && action.payload.active3Data !== 'undefined' && action.payload.active3Data !== undefined) {
    //   tempABC.push(action.payload.active3Data.value);
    // }

    // let queryCall;
    // if (tempABC.length === 0) {
    //   queryCall = db
    //     .collection('models')
    //     .orderBy('createdAt', 'desc');
    // } else {
    //   queryCall = db
    //     .collection('models')
    //     .where('Actives', 'array-contains-any', tempABC);
    // }
    // const querySnapshot = yield call([queryCall, queryCall.get]);
    // const dataList = [];
    // const tempDataList = [];
    // yield all(
    //   querySnapshot.docs.map((doc) =>
    //     dataList.push({
    //       id: doc.id,
    //       ...doc.data()
    //     }),
    //   ),
    // );
    const dataList = response.data.data;
    const tempActives = [];
    const tempDataList = [];

    if (action.payload.active1Data !== '' && !isEmpty(action.payload.active1Data) && action.payload.active1Data !== 'undefined' && action.payload.active1Data !== undefined) {
      tempActives.push(action.payload.active1Data.value);
    }
    if (action.payload.active2Data !== '' && !isEmpty(action.payload.active2Data) && action.payload.active2Data !== 'undefined' && action.payload.active2Data !== undefined) {
      tempActives.push(action.payload.active2Data.value);
    }
    if (action.payload.active3Data !== '' && !isEmpty(action.payload.active3Data) && action.payload.active3Data !== 'undefined' && action.payload.active3Data !== undefined) {
      tempActives.push(action.payload.active3Data.value);
    }

    for (let i = 0; i < dataList.length; i++) {
      // const allFounded = tempActives.every(ai => dataList[i].Actives.includes(ai));
      const allFounded = dataList[i].Actives.includes(action.payload.active1Data.value) && dataList[i].Actives.includes(action.payload.active2Data.value) && dataList[i].Actives.includes(action.payload.active3Data.value)

      if (arrayEquals(dataList[i].Actives, tempActives)) {
        // if (allFounded === true) {
        tempDataList.push(dataList[i]);
      }
    }
    yield put({
      type: types.SHOW_AVAILABLE_MODELS_SUCCESS,
      payload: {
        modelsList: tempDataList,
      },
    });

    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Got available models successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.SHOW_AVAILABLE_MODELS_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* showAllModels(action) {
  try {
    // const db = firebase.firestore();
    // const queryCall = db
    //   .collection('models')
    //   .orderBy('createdAt', 'desc');

    // const querySnapshot = yield call([queryCall, queryCall.get]);
    // const dataList = [];
    // yield all(
    //   querySnapshot.docs.map((doc) =>
    //     dataList.push({
    //       id: doc.id,
    //       ...doc.data()
    //     }),
    //   ),
    // );
    const response = yield call(
      API.getModels,
    );
    yield put({
      type: types.SHOW_ALL_MODELS_SUCCESS,
      payload: {
        modelsList: response.data.data,
      },
    });

    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Got all models successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.SHOW_ALL_MODELS_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* getSuggestiveFormulationsResult(action) {
  try {
    const response = yield call(
      API.getSuggestiveFormulation,
      action.payload.target,
      action.payload.targetValue,
      action.payload.models,
    );

    if (response.data.status === 'true') {
      // yield call(toast.success, 'Predicted successfully');
      yield put({
        type: types.GET_SUGGESTIVE_FORMULATIONS_RESULT_SUCCESS,
        payload: {
          suggestiveFormulationsResult: response.data.message,
        },
      });
    } else {
      yield call(toast.error, 'Some error occured');
      yield put({
        type: types.GET_SUGGESTIVE_FORMULATIONS_RESULT_SUCCESS,
        payload: {
          suggestiveFormulationsResult: response.data.message,
        },
      });
    }
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_SUGGESTIVE_FORMULATIONS_RESULT_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* getPredictionResult(action) {
  try {
    const response = yield call(
      API.getPrediction,
      action.payload.values,
      action.payload.modelData,
    );
    if (response.data.status === 'true') {
      // yield call(toast.success, 'Predicted successfully');
      yield put({
        type: types.PREDICT_SUCCESS,
        payload: {
          predictionResult: response.data.message,
        },
      });
    } else {
      yield call(toast.error, 'Some error occured');
      yield put({
        type: types.PREDICT_SUCCESS,
        payload: {
          predictionResult: response.data.message,
        },
      });
    }

    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.PREDICT_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* getModelDataFromId(action) {
  try {
    yield delay(500);
    // const db = firebase.firestore();
    // const getSingleClientQuery = db.collection('models').doc(action.payload.modelId);

    // const response = yield call(
    //   [getSingleClientQuery, getSingleClientQuery.get],
    // );
    const response = yield call(
      API.getModelDataFromId,
      action.payload.modelId,
    );
    yield put({
      type: types.GET_MODEL_DATA_FROM_ID_SUCCESS,
      payload: {
        modelData: response.data.data,
      },
    });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Predicted successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.GET_MODEL_DATA_FROM_ID_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

function* deleteModel(action) {
  try {
    yield delay(500);
    // const db = firebase.firestore();
    // const getSingleClientQuery = db.collection('models').doc(action.payload.modelId);

    // const response = yield call(
    //   [getSingleClientQuery, getSingleClientQuery.get],
    // );
    const response = yield call(
      API.deleteModel,
      action.payload.values,
    );
    yield put({
      type: types.DELETE_MODEL_SUCCESS,
      payload: {
        modelData: response.data.data,
      },
    });
    if (action.meta.onSuccess) {
      yield call(action.meta.onSuccess);
    }
    // yield call(toast.success, 'Predicted successfully');
  } catch (err) {
    console.log('error');
    console.log(err);
    yield put({
      type: types.DELETE_MODEL_FAILURE,
    });
    yield call(toast.error, err.message);
  }
}

export default function* () {
  yield takeEvery(types.SAVE_SELECTED_MODELS_REQUEST, saveSelectedModels);
  yield takeEvery(types.SHOW_AVAILABLE_MODELS_REQUEST, showAvailableModels);
  yield takeEvery(types.SHOW_AVAILABLE_MODELS_1_REQUEST, showAvailableModels1);
  yield takeEvery(types.SHOW_ALL_MODELS_REQUEST, showAllModels);
  yield takeEvery(types.GET_ACTIVES_LIST_REQUEST, getActivesList);
  yield takeEvery(types.PREDICT_REQUEST, getPredictionResult);
  yield takeEvery(types.GET_MODEL_DATA_FROM_ID_REQUEST, getModelDataFromId);
  yield takeEvery(types.GET_SUGGESTIVE_FORMULATIONS_RESULT_REQUEST, getSuggestiveFormulationsResult);
  yield takeEvery(types.DELETE_MODEL_REQUEST, deleteModel);
}
