import * as types from '../types';

export const DEFAULT_STATE = {
  isLoading: false,
  errorData: {},
  modelData: {},
  validationError: false,
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.UPLOAD_FILE_REQUEST: return {
      ...state,
      errorData: {},
      isLoading: true,
      validationError: false,
      modelData: {},
    };
    case types.UPLOAD_FILE_SUCCESS: return {
      ...state,
      errorData: action.payload.errorData,
      modelData: action.payload.modelData,
      isLoading: false,
      validationError: action.payload.validationError,
    };
    case types.UPLOAD_FILE_FAILURE: return {
      ...state,
      errorData: {},
      modelData: {},
      isLoading: false,
      validationError: false,
    };
    case types.DOWNLOAD_FILE_REQUEST: return {
      ...state,
      isLoading: true,
    };
    case types.DOWNLOAD_FILE_SUCCESS: return {
      ...state,
      isLoading: false,
    };
    case types.DOWNLOAD_FILE_FAILURE: return {
      ...state,
      isLoading: false,
    };
    default: return state;
  }
};
