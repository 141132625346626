import * as types from '../types';

export const getMasterData = () => ({
  type: types.GET_MASTER_DATA_REQUEST,
});

export const addNewMasterData = (values, onSuccess) => ({
  type: types.ADD_MASTER_DATA_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const editMasterData = (values, onSuccess) => ({
  type: types.EDIT_MASTER_DATA_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteMasterData = (values, onSuccess) => ({
  type: types.DELETE_MASTER_DATA_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});
