import * as types from '../types';

export const getUserAccessData = (values, onSuccess) => ({
  type: types.GET_USER_ACCESS_DATA_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const getUsers = () => ({
  type: types.GET_USERS_REQUEST,
});

export const addNewUser = (values, onSuccess) => ({
  type: types.ADD_USER_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const editUser = (values, onSuccess) => ({
  type: types.EDIT_USER_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteUser = (values, onSuccess) => ({
  type: types.DELETE_USER_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});
