import axios from 'axios';
import config from '../../config';

// Plant
const getExchangeRates = () => axios.get(
  `${config.BACKEND_API}/v1/exchangerate`,
);

const addNewExchangeRate = (values) => axios.post(
  `${config.BACKEND_API}/v1/exchangerate`,
  values,
);

const editExchangeRate = (values) => axios.put(
  `${config.BACKEND_API}/v1/exchangerate`,
  values,
);

const deleteExchangeRate = (values) => axios.delete(
  `${config.BACKEND_API}/v1/exchangerate`,
  { data: values },
);


export default {
  getExchangeRates,
  addNewExchangeRate,
  editExchangeRate,
  deleteExchangeRate,
};
