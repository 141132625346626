import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import Select from 'react-select';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
// import Energy from '../application/fonts/Energy-Regular.ttf';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import MUIDataTable from 'mui-datatables';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  Paper,
  Breadcrumbs,
  Alert,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Stack,
  TableFooter,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  getActivesList, showAvailableModels1, getSuggestiveFormulation,
} from '../../services/Model/actions';
import DataModelDisplay from '../../components/DataModelDisplay';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { Loading } from '../../components/Loading';
import { Link } from 'react-router-dom';
import MultipleLineChart from '../../components/Charts/MultipleLinePlot';
import '../../assets/stylesheet/kpi-pred.css';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  getMasterData,
} from '../../services/MasterData/actions';
import {
  getRegulatoryLimits,
} from '../../services/RegulatoryLimit/actions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import reckitt_logo from '../../assets/Asset_1.png';

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, 'solid', '#d3d3d3']],
      },
    },
    MuiTableCell: {
      root: {
        borderColor: '#d3d3d3',
      },
      head: {
        background: 'grey',
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: 'flex-start',
      },
    },
    // MUIDataTable
    MUIDataTableHeadCell: {
      sortLabelRoot: {
        // height: undefined,
      },
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '80px',
  // overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  // minWidth: '100px',
  overflowWrap: 'anywhere',
}));

const StyledTableFixedWithCell3 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  width: '20px',
}));

const StyledTableFixedWithCell2 = styled(TableCell)(({ theme }) => ({
  border: '1px solid grey',
  minWidth: '100px',
  overflowWrap: 'anywhere',
  color: 'white',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  root: {
    width: "max-content"
  }
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  // border: '1px solid grey',
  color: 'white',
  // minWidth: '100px',
  // overflowWrap: 'anywhere',
}));

const StyledTableCell2Coloured = ({ digit }) => {
  if (digit >= 0 && digit <= 0.5) {
    return (
      <StyledTableCell2 style={{ backgroundColor: '#FE7474' }}>
        {digit}
      </StyledTableCell2>
    )
  }
  if (digit >= 0.5 && digit <= 0.7) {
    return (
      <StyledTableCell2 style={{ backgroundColor: 'yellow' }}>
        {digit}
      </StyledTableCell2>
    )
  }
  if (digit >= 0.7 && digit <= 1) {
    return (
      <StyledTableCell2 style={{ backgroundColor: '#C6FEA6' }}>
        {digit}
      </StyledTableCell2>
    )
  }
  return (
    <StyledTableCell2>
      {digit}
    </StyledTableCell2>
  )
}

const StyledTableCellColoured = ({ digit }) => {
  if (digit >= 0 && digit <= 0.5) {
    return (
      <StyledTableCell style={{ backgroundColor: '#FE7474' }}>
        {digit}
      </StyledTableCell>
    )
  }
  if (digit >= 0.5 && digit <= 0.7) {
    return (
      <StyledTableCell style={{ backgroundColor: 'yellow' }}>
        {digit}
      </StyledTableCell>
    )
  }
  if (digit >= 0.7 && digit <= 1) {
    return (
      <StyledTableCell style={{ backgroundColor: '#C6FEA6' }}>
        {digit}
      </StyledTableCell>
    )
  }
  return (
    <StyledTableCell>
      {digit}
    </StyledTableCell>
  )
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#D2042D',
  // color: 'white',
  // backgroundColor: '#FF0000',
}));

const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  // color: 'white',
  // backgroundColor: '#FF0000',
}));

const PredictiveAnalysisPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // recall if any of the parameters are changed
    dispatch(
      getActivesList(),
    );
    dispatch(
      getMasterData()
    );
    dispatch(
      getRegulatoryLimits()
    )
  }, [dispatch]);

  const [showUpdateModal, setUpdateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [bannedFormulationPresent, setBannedFormulationPresent] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [numberOfItems, setNumberOfItems] = useState(5);
  const [showModal, setModal] = useState(false);
  const [showAllModels, setShowAllModels] = useState(false);

  const activesList = useSelector((state) => state.Model.activesList);
  const suggestiveFormulationsResult = useSelector((state) => state.Model.suggestiveFormulationsResult);
  const suggestiveFormulationsResultLoading = useSelector((state) => state.Model.suggestiveFormulationsResultLoading);
  const isLoading = useSelector((state) => state.Model.isLoading);
  const modelsList1 = useSelector((state) => state.Model.modelsList1);
  const [active1Data, setActive1Data] = useState('');
  const [active2Data, setActive2Data] = useState('');
  const [active3Data, setActive3Data] = useState('');
  const [suggestiveFormulationsResultFinal, setSuggestiveFormulationsResultFinal] = useState([]);
  const [selectedTargetType, setSelectedTargetType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ label: 'Global', value: 'global' });
  const [selectedTargetValue, setSelectedTargetValue] = useState('');
  const [selectedSortMethod, setSelectedSortMethod] = useState({ label: 'KT50', value: 'KT50' });
  const [tempLoading, setLoading] = useState(false);
  const [showSuggestiveFormulationResult, setShowSuggestiveFormulationResult] = useState(false);
  const [gotModels, setGotModels] = useState(false);
  const [checked, setChecked] = React.useState([]);
  const masterDataList = useSelector((state) => state.MasterData.masterDataList);
  const regulatoryLimitList = useSelector((state) => state.RegulatoryLimit.regulatoryLimitList);

  const [sortedSuggestiveFormulationsResult1, setSortedSuggestiveFormulationsResult1] = useState([]);
  const [sortedSuggestiveFormulationsResult2, setSortedSuggestiveFormulationsResult2] = useState([]);
  const [sortedSuggestiveFormulationsResult3, setSortedSuggestiveFormulationsResult3] = useState([]);

  const returnMatching = (dataList, rawMaterialValue) => {
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i]?.rawmaterial?.toLowerCase() === rawMaterialValue?.toLowerCase()) {
        return dataList[i];
      }
    }
    return {}
  }

  const returnRegulatoryLimitForEachActive = (dataList, rawMaterialValue) => {
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i]?.rawmaterial?.toLowerCase() === rawMaterialValue?.toLowerCase()) {
        return dataList[i];
      }
    }
    return {}
  }

  const toggleShowAllModelsAccordingToStatus = (tempLength) => {
    if (!showAllModels) {
      setNumberOfItems(tempLength);
    } else {
      setNumberOfItems(5);
    }
    setShowAllModels(!showAllModels);
  }

  const sortByCost = (sortBy) => {
    setSelectedSortMethod(sortBy);
    if (sortBy.value === 'KT50') {
      if (sortedSuggestiveFormulationsResult1.length > 0) {
        let tempChecked = [];
        for (let j = 0; j < sortedSuggestiveFormulationsResult1.length; j++) {
          if (j < 5) {
            tempChecked.push(sortedSuggestiveFormulationsResult1[j].id)
          }
        }
        setChecked(tempChecked);
      }
    }
    if (sortBy.value === 'Cost') {
      if (sortedSuggestiveFormulationsResult2.length > 0) {
        let tempChecked = [];
        for (let j = 0; j < sortedSuggestiveFormulationsResult2.length; j++) {
          if (j < 5) {
            tempChecked.push(sortedSuggestiveFormulationsResult2[j].id)
          }
        }
        setChecked(tempChecked);
      }
    }
  }

  // This is recalled when the suggestive formulation result is changed
  useEffect(() => {
    // recall if any of the parameters are changed
    const tempSuggestiveFormResult = [];

    for (let j = 0; j < suggestiveFormulationsResult.length; j++) {
      tempSuggestiveFormResult.push(suggestiveFormulationsResult[j]);
    }
    setBannedFormulationPresent(false);
    for (let j = 0; j < tempSuggestiveFormResult.length; j++) {
      let totalCostOfProject = 0;
      if (tempSuggestiveFormResult[j]?.hasOwnProperty('SuggestedData')) {
        if (tempSuggestiveFormResult[j]?.SuggestedData['Active 1%']) {
          const active1Cost = returnMatching(masterDataList, tempSuggestiveFormResult[j]?.Actives[0])[selectedCountry.value]?.countryCost * tempSuggestiveFormResult[j]?.SuggestedData['Active 1%'] || 0
          totalCostOfProject = totalCostOfProject + active1Cost;

          tempSuggestiveFormResult[j].active1Label = tempSuggestiveFormResult[j]?.Actives[0];
          tempSuggestiveFormResult[j].active1Value = tempSuggestiveFormResult[j]?.SuggestedData['Active 1%'];
        }
        if (tempSuggestiveFormResult[j]?.SuggestedData['Active 2%']) {
          const active2Cost = returnMatching(masterDataList, tempSuggestiveFormResult[j]?.Actives[1])[selectedCountry.value]?.countryCost * tempSuggestiveFormResult[j]?.SuggestedData['Active 2%'] || 0
          totalCostOfProject = totalCostOfProject + active2Cost;

          tempSuggestiveFormResult[j].active2Label = tempSuggestiveFormResult[j]?.Actives[1];
          tempSuggestiveFormResult[j].active2Value = tempSuggestiveFormResult[j]?.SuggestedData['Active 2%'];
        }
        if (tempSuggestiveFormResult[j]?.SuggestedData['Active 3%']) {
          const active3Cost = returnMatching(masterDataList, tempSuggestiveFormResult[j]?.Actives[2])[selectedCountry.value]?.countryCost * tempSuggestiveFormResult[j]?.SuggestedData['Active 3%'] || 0
          totalCostOfProject = totalCostOfProject + active3Cost;

          tempSuggestiveFormResult[j].active3Label = tempSuggestiveFormResult[j]?.Actives[2];
          tempSuggestiveFormResult[j].active3Value = tempSuggestiveFormResult[j]?.SuggestedData['Active 3%'];
        }
      }

      let TempNotWithinRegulatoryLimit = false;
      if (tempSuggestiveFormResult[j]?.SuggestedData['Active 1%']) {
        // Active exists
        // Get if the concentration of this active is in permissible limits
        for (let counter = 0; counter < regulatoryLimitList.length; counter++) {
          if (regulatoryLimitList[counter].active === tempSuggestiveFormResult[j]?.Actives[0]) {
            if (regulatoryLimitList[counter][selectedCountry.value]?.banned === 'banned') {
              tempSuggestiveFormResult[j].IsBanned = true;
              setBannedFormulationPresent(true);
            }
            if (tempSuggestiveFormResult[j]?.SuggestedData['Active 1%'] > regulatoryLimitList[counter][selectedCountry.value].upperConcentrationLimit) {
              TempNotWithinRegulatoryLimit = true;
            }
          }
        }
      }
      if (tempSuggestiveFormResult[j]?.SuggestedData['Active 2%']) {
        // Active exists
        // Get if the concentration of this active is in permissible limits
        for (let counter = 0; counter < regulatoryLimitList.length; counter++) {
          if (regulatoryLimitList[counter].active === tempSuggestiveFormResult[j]?.Actives[1]) {
            if (tempSuggestiveFormResult[j]?.SuggestedData['Active 2%'] > regulatoryLimitList[counter][selectedCountry.value].upperConcentrationLimit) {
              TempNotWithinRegulatoryLimit = true;
            }
            if (regulatoryLimitList[counter][selectedCountry.value]?.banned === 'banned') {
              tempSuggestiveFormResult[j].IsBanned = true;
              setBannedFormulationPresent(true);
            }
          }
        }
      }
      if (tempSuggestiveFormResult[j]?.SuggestedData['Active 3%']) {
        // Active exists
        // Get if the concentration of this active is in permissible limits
        for (let counter = 0; counter < regulatoryLimitList.length; counter++) {
          if (regulatoryLimitList[counter].active === tempSuggestiveFormResult[j]?.Actives[2]) {
            if (tempSuggestiveFormResult[j]?.SuggestedData['Active 3%'] > regulatoryLimitList[counter][selectedCountry.value].upperConcentrationLimit) {
              TempNotWithinRegulatoryLimit = true;
            }
            if (regulatoryLimitList[counter][selectedCountry.value]?.banned === 'banned') {
              tempSuggestiveFormResult[j].IsBanned = true;
              setBannedFormulationPresent(true);
            }
          }
        }
      }
      tempSuggestiveFormResult[j].NotWithinRegulatoryLimit = TempNotWithinRegulatoryLimit;
      // Calculate if the model is above RegulatoryLimit here and make it boolean as true or false
      // Then Depending on the true / false value we can render it as a red row
      tempSuggestiveFormResult[j].TotalCost = Number(totalCostOfProject).toFixed(3);

      tempSuggestiveFormResult[j].SrNo = j + 1;

      tempSuggestiveFormResult[j].goodnessKT50 = Number(tempSuggestiveFormResult[j].KT50.Rsquare).toFixed(3)
      tempSuggestiveFormResult[j].goodnessM24 = Number(tempSuggestiveFormResult[j].M24.Rsquare).toFixed(3)

      tempSuggestiveFormResult[j].KT50Value = tempSuggestiveFormResult[j].SuggestedData['KT50'];
      tempSuggestiveFormResult[j].M24Value = tempSuggestiveFormResult[j].SuggestedData['M24'];
    }
    // Calculate cost for each model and add it to array
    // Then Sort tempSuggestiveFormResult Array by cost here
    //setSuggestiveFormulationsResultFinal(tempSuggestiveFormResult);
    if (tempSuggestiveFormResult.length > 0) {
      let tempChecked = [];
      for (let j = 0; j < tempSuggestiveFormResult.length; j++) {
        if (j < 5) {
          tempChecked.push(tempSuggestiveFormResult[j].id)
        }
      }
      setChecked(tempChecked);
    }
    const costByCost = [];
    for (let j = 0; j < tempSuggestiveFormResult.length; j++) {
      costByCost.push(tempSuggestiveFormResult[j]);
    }
    // const costByCost = tempSuggestiveFormResult;
    costByCost.sort(function (a, b) {
      return a.TotalCost - b.TotalCost;
    });
    setSortedSuggestiveFormulationsResult1(tempSuggestiveFormResult);
    setSortedSuggestiveFormulationsResult2(costByCost);
    setSortedSuggestiveFormulationsResult3(tempSuggestiveFormResult);
  }, [suggestiveFormulationsResult]);

  // This use effect is called for the first API Result,
  // Earlier, we used to first show the models, then had a different button to get suggestive formulation result
  // But now we have combined both the APIS into one,
  // So now we get the models and then submit them automatically to get the formulation result
  useEffect(() => {
    // recall if any of the parameters are changed
    if (modelsList1.length > 0 && gotModels) {
      let accept = false;
      if (selectedTargetType && selectedTargetType !== 'undefined' && selectedTargetType !== '' && selectedTargetValue && selectedTargetValue !== 'undefined' && selectedTargetValue !== '' && modelsList1 && modelsList1.length > 0) {
        accept = true;
      }
      if (accept) {
        dispatch(
          getSuggestiveFormulation(selectedTargetType, selectedTargetValue, modelsList1, () => {
            //history.push('/showModels');
            // if (suggestiveFormulationsResult.length > 0) {
            //   setChecked([suggestiveFormulationsResult[0].id]);
            // }
            setShowSuggestiveFormulationResult(true);
            // Auto populate first line
          }),
        );
      }
      else {
        toast.error('Please Select Target and Enter Target Value');
      }
    }
  }, [modelsList1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      if (newChecked.length < 5) {
        newChecked.push(value);
      } else {
        //show react toastify max 4 allowed
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleTextInputChange = (event) => {
    setSelectedTargetValue(event.target.value);
  };

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active1Label',
      label: 'Active 1',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active1Value',
      label: 'Active Conc (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active2Label',
      label: 'Active 2',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active2Value',
      label: 'Active Conc (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active3Label',
      label: 'Active 3',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'active3Value',
      label: 'Active Conc (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'KT50Value',
      label: 'KT50 (Minutes)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'M24Value',
      label: 'M24 (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'TotalCost',
      label: 'Cost',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'goodnessKT50',
      label: 'goodnessKT50',
      options: {
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <>
              KT50 Goodness of Fit
              <Tooltip
                style={{ color: '#E72388', backgroundColor: 'white' }}
                placement="top"
                title="Goodness of fit represents how well data points fit to the predicted regression line. Red: 0 - 0.5, Amber: 0.5 - 0.75 , Green: 0.75 - 1.0">
                <IconButton color="primary">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        },
        customBodyRender: (value) => {
          if (value >= 0 && value <= 0.5) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: '#FE7474' }}>
                {value}
              </div >
            )
          }
          if (value >= 0.5 && value <= 0.7) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: 'yellow' }}>
                {value}
              </div >
            )
          }
          if (value >= 0.7 && value <= 1) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: '#C6FEA6' }}>
                {value}
              </div >
            )
          }
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: 'goodnessM24',
      label: 'goodnessM24',
      options: {
        customHeadLabelRender: ({ index, ...column }) => {
          return (
            <>
              M24 Goodness of Fit
              <Tooltip
                style={{ color: '#E72388', backgroundColor: 'white' }}
                placement="top"
                title="Goodness of fit represents how well data points fit to the predicted regression line. Red: 0 - 0.5, Amber: 0.5 - 0.75 , Green: 0.75 - 1.0">
                <IconButton color="primary">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        },
        customBodyRender: (value) => {
          if (value >= 0 && value <= 0.5) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: '#FE7474' }}>
                {value}
              </div >
            )
          }
          if (value >= 0.5 && value <= 0.7) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: 'yellow' }}>
                {value}
              </div >
            )
          }
          if (value >= 0.7 && value <= 1) {
            return (
              <div style={{ textAlign: 'center', backgroundColor: '#C6FEA6' }}>
                {value}
              </div >
            )
          }
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: '',
      label: 'View Details',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <>
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ color: 'white', backgroundColor: '#E72388' }}
                onClick={() => {
                  // setModalData(member);
                  // setDeleteModal(true);
                  // setUpdateModal(false);
                  setDeleteModal(true);
                  setModalData(sortedSuggestiveFormulationsResult3[tableMeta.rowIndex])
                }}
              >
                View Details
              </Button>
            </div>
          </>

        ),
      },
    },
  ];
  // Options for Mui-Datatable
  const options = {
    elevation: 0,
    // filterType: 'textField',
    // confirmFilters: true,
    setRowProps: (row, dataIndex, rowIndex) => {
      if (sortedSuggestiveFormulationsResult3[rowIndex].NotWithinRegulatoryLimit === true) {
        return {
          style: { background: "#f54963" }
        };
      }
    },
    setTableProps: () => {
      return {
        size: 'small',
        border: '1px solid grey'
      };
    },
    rowsPerPageOptions: [50, 100],
    pageSize: 50,
    responsive: 'standard',
    serverSide: false,
    filter: true,
    viewColumns: true,
    selectableRows: 'none',
    download: true,
    search: true, // Disable search option
    enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)
  };
  const handleGetSuggestiveFormulations = () => {
    let accept = false;
    if (selectedTargetType && selectedTargetType !== 'undefined' && selectedTargetType !== '' && selectedTargetValue && selectedTargetValue !== 'undefined' && selectedTargetValue !== '' && modelsList1 && modelsList1.length > 0) {
      accept = true;
    }
    if (accept) {
      dispatch(
        getSuggestiveFormulation(selectedTargetType, selectedTargetValue, modelsList1, () => {
          //history.push('/showModels');
          // if (suggestiveFormulationsResult.length > 0) {
          //   setChecked([suggestiveFormulationsResult[0].id]);
          // }
          setShowSuggestiveFormulationResult(true);
          // Auto populate first line
        }),
      );
    }
    else {
      toast.error('Please Select Target and Enter Target Value');
    }
  };

  const showAvailableModelsClick = () => {
    setShowSuggestiveFormulationResult(false);
    setGotModels(true);
    dispatch(
      showAvailableModels1(active1Data, active2Data, active3Data, () => {
      }),
    );
  };

  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            Suggestive Formulas

          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Box p={1}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
          <Link style={{ textDecoration: 'none', color: 'dimgrey', fontSize: '14px' }} underline="hover" to="/">
            Home
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to="/aerosol">
            Aerosol
          </Link>
          <Link style={{ textDecoration: 'none', color: 'black', fontSize: '14px' }} underline="hover" to={{}}>
            Suggestive Formulations
          </Link>
        </Breadcrumbs>
        <>
          <Grid container>
            <Grid item xs={12} alignItems="center">
              <Paper sx={{ mt: 5, boxShadow: 'none' }} className='input-bg'>
                <Box sx={{ padding: '20px' }}>
                  <Grid container>
                    <>
                      {suggestiveFormulationsResultLoading && (
                        <Loading />
                      )}
                      {isLoading && (
                        <Loading />
                      )}
                    </>
                    <Grid item xs={12} md={4} p={1} alignItems="center">
                      <Box textAlign="center">
                        <Typography align="center" variant="p" component="h6">
                          Select Active 1
                        </Typography>
                      </Box>
                      <Select
                        value={active1Data}
                        onChange={(temp => setActive1Data(temp))}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={1} alignItems="center">
                      <Box textAlign="center">
                        <Typography align="center" variant="p" component="h6">
                          Select Active 2
                        </Typography>
                      </Box>
                      <Select
                        value={active2Data}
                        onChange={(temp => setActive2Data(temp))}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={1} alignItems="center">
                      <Box textAlign="center">
                        <Typography align="center" variant="p" component="h6">
                          Select Active 3
                        </Typography>
                      </Box>
                      <Select
                        value={active3Data}
                        onChange={(temp => setActive3Data(temp))}
                        options={activesList}
                        placeholder="Select Active"
                        isClearable
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={1}>
                      <Box textAlign="center">
                        <Typography display="inline" align="center" variant="p" component="h6">
                          Select Target
                        </Typography>
                        <Typography display="inline" align="center" variant="p" component="h6" color="red">
                          (*)
                        </Typography>
                      </Box>
                      <Box pt={3}>
                        <Select
                          value={selectedTargetType}
                          onChange={(temp => setSelectedTargetType(temp))}
                          options={[{ label: 'KT50', value: 'KT50' }, { label: 'M24', value: 'M24' }]}
                          placeholder="Select Target"
                          isClearable
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} p={1} alignItems="center">
                      <Box textAlign="center">
                        <Typography display="inline" align="center" variant="p" component="h6">
                          Target Value
                        </Typography>
                        <Typography display="inline" align="center" variant="p" component="h6" color="red">
                          (*)
                          <Tooltip title="Result will be all the solutions upto target value">
                            <IconButton
                              size="sm">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Target Value"
                        size="small"
                        value={selectedTargetValue}
                        onChange={handleTextInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} p={1} alignItems="center" >
                      <Box textAlign="center">
                        <Typography align="center" variant="p" component="h6">
                          Select Country
                        </Typography>
                      </Box>
                      <Box pt={3}>
                        <Select
                          value={selectedCountry}
                          onChange={(temp => setSelectedCountry(temp))}
                          options={[{ label: 'Global', value: 'global' }, { label: 'Brazil', value: 'brazil' }, { label: 'Australia', value: 'australia' }, { label: 'India', value: 'india' }, { label: 'Malaysia', value: 'malaysia' }, { label: 'South Africa', value: 'southafrica' }]}
                          placeholder="Select Country"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} p={1} >
                      <Button onClick={() => { showAvailableModelsClick(); }} fullWidth variant="contained" style={{ textTransform: 'capitalize', border: 'none', outline: 'none' }}>
                        Get Suggestive Formulations
                      </Button>
                    </Grid>
                    {/* <Grid item md={3} p={1}>
                      <Button disabled={modelsList1.length > 0 ? false : true} sx={{ marginLeft: '10px' }} onClick={() => { handleGetSuggestiveFormulations(); }} variant="contained" style={{ textTransform: 'capitalize', border: 'none', outline: 'none' }}>
                        Get Suggestive Formulations
                      </Button>
                    </Grid> */}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container item>
            {bannedFormulationPresent ? (
              <Grid item xs={12} md={12} sx={{ paddingTop: 2 }}>
                <Alert severity="info">
                  Banned Active Present in the current formulation. Please try a different Active combination or a different country.
                </Alert>
              </Grid>
            ) : (
              <>
                {showSuggestiveFormulationResult && sortedSuggestiveFormulationsResult1.length == 0 && (
                  <Grid item xs={12} md={12} sx={{ paddingTop: 2 }}>
                    <Alert severity="info">
                      No combination exists. Please Try Again.
                    </Alert>
                  </Grid>
                )}
                {showSuggestiveFormulationResult && sortedSuggestiveFormulationsResult1.length > 0 ? (
                  <>
                    <Grid item xs={12} md={8}>
                      <Paper>
                        <MultipleLineChart checked={checked} suggestiveFormulationsResult={selectedSortMethod.value === 'KT50' ? sortedSuggestiveFormulationsResult1 : sortedSuggestiveFormulationsResult2} />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card sx={{ maxHeight: '450px', overflow: 'auto' }}>
                        <Box textAlign="center">
                          Sort by:
                        </Box>
                        <Select
                          value={selectedSortMethod}
                          onChange={(temp => sortByCost(temp))}
                          options={[{ label: 'KT50', value: 'KT50' }, { label: 'Cost', value: 'Cost' }]}
                          placeholder="Select Sort Method"
                        />
                        <Typography color="black" align="center" variant="body2">
                          Select models for plotting
                        </Typography>
                        <Typography align="center" variant="body2">
                          At the most 5 models can be selected
                        </Typography>
                        <Divider />
                        <List
                          dense
                        >
                          {(selectedSortMethod.value === 'KT50' ? sortedSuggestiveFormulationsResult1 : sortedSuggestiveFormulationsResult2).map((value) => {
                            const labelId = `transfer-list-all-item-${value.id}-label`;
                            return (
                              <ListItem
                                key={value.id}
                                role="listitem"
                                button
                                onClick={handleToggle(value.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.name} (KT50 : ${value?.SuggestedData?.KT50} minutes)  (Cost : ${value?.TotalCost} )`} />
                              </ListItem>
                            );
                          })}
                          <ListItem />
                        </List>
                      </Card>
                    </Grid>
                    <Grid item xs={12} alignItems="center">
                      {/* <StyledTableContainer sx={{ display: 'block', overflowX: 'auto' }}>
                        <Table size="small" aria-label="customized table" fixedHeader={false} style={{ tableLayout: "auto" }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center" padding="none">Model Name</StyledTableCell>
                              <StyledTableCell align="center" padding="none">Active 1</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Active Conc (%)</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Active 2</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Active Conc (%)</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Active 3</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Active Conc (%)</StyledTableCell>
                              <StyledTableCell padding="none" align="center">KT50 (Minutes)</StyledTableCell>
                              <StyledTableCell padding="none" align="center">M24 (%)</StyledTableCell>
                              <StyledTableCell padding="none" align="center">Cost</StyledTableCell>
                              <StyledTableCell padding="none" align="center">
                                <Typography align="center" variant="p" component="h6">
                                  KT50 Goodness of Fit
                                  <Tooltip placement="top" title="Goodness of fit represents how well data points fit to the predicted regression line. Red: 0 - 0.5, Amber: 0.5 - 0.75 , Green: 0.75 - 1.0">
                                    <IconButton color="primary">
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell padding="none" align="center">
                                <Typography align="center" variant="p" component="h6">
                                  M24 Goodness of Fit
                                  <Tooltip placement="top" title="Goodness of fit represents how well data points fit to the predicted regression line. Red: 0 - 0.5, Amber: 0.5 - 0.75 , Green: 0.75 - 1.0">
                                    <IconButton color="primary">
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell padding="none" align="center">View Details</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!suggestiveFormulationsResultLoading && sortedSuggestiveFormulationsResult3.slice(0, numberOfItems).map((member, index) => {
                              if (member.NotWithinRegulatoryLimit) {
                                return (
                                  <StyledTableRow>
                                    {/* <StyledTableCell>
                                      <>
                                        {index + 1}
                                      </>
                                    </StyledTableCell>
                                    <StyledTableCell2>
                                      {index + 1}
                                      {'. '}
                                      {member?.name}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 0 && (
                                            <>
                                              <>
                                                {member?.hasOwnProperty('SuggestedData') ? (
                                                  <>
                                                    {row}
                                                  </>
                                                ) : (
                                                  <div>
                                                    <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                      {row}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </>
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member.SuggestedData['Active 1%']}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 1 && (
                                            <>
                                              {member?.hasOwnProperty('SuggestedData') ? (
                                                <>
                                                  {row}
                                                </>
                                              ) : (
                                                <div>
                                                  <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                    {row}
                                                  </Typography>
                                                </div>)}
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member.SuggestedData['Active 2%']}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 2 && (
                                            <>
                                              {member?.hasOwnProperty('SuggestedData') ? (
                                                <>
                                                  {row}
                                                </>
                                              ) : (
                                                <div>
                                                  <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                    {row}
                                                  </Typography>
                                                </div>)}
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member.SuggestedData['Active 3%']}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member.SuggestedData['KT50']}
                                      {' '}
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member.SuggestedData['M24']}
                                      {' '}
                                      %
                                    </StyledTableCell2>
                                    <StyledTableCell2>
                                      {member?.TotalCost}
                                    </StyledTableCell2>
                                    <StyledTableCell2Coloured digit={Number(member?.KT50?.Rsquare).toFixed(3)} />
                                    <StyledTableCell2Coloured digit={Number(member?.M24?.Rsquare).toFixed(3)} />
                                    <StyledTableCell2>
                                      <Button variant="outlined" sx={{ backgroundColor: 'white' }} color="primary" onClick={() => { setModalData(member); setDeleteModal(true); }}>
                                        VIEW DETAILS
                                      </Button>
                                    </StyledTableCell2>
                                  </StyledTableRow>
                                )
                              } else {
                                return (
                                  <StyledTableRow2>
                                    <StyledTableCell>
                                      {index + 1}
                                      {'. '}
                                      {member?.name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 0 && (
                                            <>
                                              <>
                                                {member?.hasOwnProperty('SuggestedData') ? (
                                                  <>
                                                    {row}
                                                  </>
                                                ) : (
                                                  <div>
                                                    <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                      {row}
                                                    </Typography>
                                                  </div>
                                                )}
                                              </>
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member.SuggestedData['Active 1%']}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 1 && (
                                            <>
                                              {member?.hasOwnProperty('SuggestedData') ? (
                                                <>
                                                  {row}
                                                </>
                                              ) : (
                                                <div>
                                                  <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                    {row}
                                                  </Typography>
                                                </div>)}
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member.SuggestedData['Active 2%']}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member?.Actives?.map((row, index) => (
                                        <Stack>
                                          {index === 2 && (
                                            <>
                                              {member?.hasOwnProperty('SuggestedData') ? (
                                                <>
                                                  {row}
                                                </>
                                              ) : (
                                                <div>
                                                  <Typography variant="h5" style={{ fontSize: '16px' }}>
                                                    {row}
                                                  </Typography>
                                                </div>)}
                                            </>
                                          )}
                                        </Stack>
                                      ))}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member.SuggestedData['Active 3%']}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member.SuggestedData['KT50']}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member.SuggestedData['M24']}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {member?.TotalCost}
                                    </StyledTableCell>
                                    <StyledTableCellColoured digit={Number(member?.KT50?.Rsquare).toFixed(3)} />
                                    <StyledTableCellColoured digit={Number(member?.M24?.Rsquare).toFixed(3)} />
                                    <StyledTableCell>
                                      <Button color="primary" onClick={() => { setModalData(member); setDeleteModal(true); }}>
                                        VIEW DETAILS
                                      </Button>
                                    </StyledTableCell>
                                  </StyledTableRow2>
                                )
                              }
                            })}
                          </TableBody>
                        </Table>
                      </StyledTableContainer> */}
                      <Box>
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            data={sortedSuggestiveFormulationsResult3.slice(0, numberOfItems)}
                            columns={columns}
                            options={options}
                          />
                        </ThemeProvider>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {showAllModels ? (
                          <Button sx={{ width: 'max-content' }} fullWidth startIcon={<KeyboardArrowUpIcon />} onClick={() => toggleShowAllModelsAccordingToStatus(sortedSuggestiveFormulationsResult3.length)}>
                            Show Less
                          </Button>
                        ) : (
                          <Button sx={{ width: 'max-content' }} fullWidth startIcon={<KeyboardArrowDownIcon />} onClick={() => toggleShowAllModelsAccordingToStatus(sortedSuggestiveFormulationsResult3.length)}>
                            Show All Models
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    {/* {!suggestiveFormulationsResultLoading && suggestiveFormulationsResult.map((member, index) => {
                      return (
                        <Grid item xs={12} key={`${member}${index + 1}`}>
                          <Paper
                            style={{ border: '1px solid #CECECE' }}
                            sx={{ marginTop: '20px' }}
                          >
                            <DataModelDisplay masterDataList={masterDataList} selectedCountryValue={selectedCountry.value} modelData={member} nameAvailable />
                          </Paper>
                        </Grid>
                      );
                    })} */}
                  </>
                ) : (
                  <>
                    {!tempLoading && modelsList1 && modelsList1.length > 0 && gotModels ?
                      (
                        <>
                          {/* {modelsList1.map((member, index) => {
                            return (
                              <Grid item xs={12} key={index}>
                                <Paper
                                  style={{ border: '1px solid #CECECE' }}
                                  sx={{ marginTop: '20px' }}
                                >
                                  <DataModelDisplay showFormulaOptimisation modelData={member} nameAvailable masterDataList={masterDataList} selectedCountryValue={selectedCountry.value} />
                                </Paper>
                              </Grid>
                            );
                          })} */}
                        </>
                      ) : (
                        <>
                          {gotModels && !isLoading && (
                            <Grid item xs={12} pt={5}>
                              <Alert severity="info">No models found</Alert>
                            </Grid>
                          )}
                        </>
                      )
                    }
                  </>
                )}
              </>
            )}
          </Grid>
        </>
      </Box>
      <Dialog
        open={showDeleteModal}
        maxWidth="lg"
        onClose={() => {
          if (typeof setModalData === 'function') {
            setModalData(null);
          }
          if (typeof setDeleteModal === 'function') {
            setDeleteModal(false);
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              Model Details
            </Box>
            <Box>
              <IconButton onClick={() => {
                if (typeof setModalData === 'function') {
                  setModalData(null);
                }
                if (typeof setDeleteModal === 'function') {
                  setDeleteModal(false);
                }
              }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            {modalData && (
              <DataModelDisplay modelData={modalData} nameAvailable />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </SideMenuAppWrapper >
  );
};

export default PredictiveAnalysisPage;
