import React from 'react';
import Button from '@mui/material/Button';
import { Field, change } from 'redux-form';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import { renderSelectInput3 } from './SelectInput';
import Input from './Input';
import Input2 from './Input2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableFooter, TableRow, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { connect, useDispatch, useSelector } from 'react-redux';

const returnMatching = (dataList, singleClassificationData, countryKey) => {
  if (singleClassificationData) {
    const tempTds = singleClassificationData.split(':')[0];
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i]?.tds?.toLowerCase() === tempTds?.toLowerCase()) {
        if (dataList[i][countryKey]) {
          return dataList[i][countryKey];
        }
        return {}
      }
    }
  }
  return {}
}

const RenderComponentAdd = ({
  masterDataList, selectedCountry, costingData, fields, isLoading, submitting, label, meta: { touched, error },
}) => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ pt: 3 }}>
      <Grid container>
        <Typography color="initial">
          {label}
        </Typography>
        <>
          <Button color="secondary" variant="contained" size="medium" onClick={() => fields.push({})}>
            Add
          </Button>
          {touched && error && <span>{error}</span>}
        </>
        {fields.length > 0 && (
          <Grid item xs={12}>
            {fields.map((member, index) => {
              // if (costingData[index]?.quantity) {
              //   dispatch(change('DYNAMIC_COST_SELECTION_FORM', `${member}.amount1`, returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.countryCost * costingData[index].quantity));
              //   dispatch(change('DYNAMIC_COST_SELECTION_FORM', `${member}.amount2`, returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.usdCost * costingData[index].quantity));
              // }
              return (
                <Box key={`${member}${index + 1}`}>
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name={`${member}.classification`}
                        type="string"
                        component={renderSelectInput3}
                        label=""
                        required
                        list={masterDataList}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Field
                        name={`${member}.quantity`}
                        type="number"
                        placeholder={"Quantity(KG)"}
                        component={Input2}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Stack direction="row">
                        <Field
                          name={`${member}.amount1`}
                          type="string"
                          label={selectedCountry.label + " Cost: "}
                          component={Input2}
                          disabled
                          defaultValue={(returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.countryCost * costingData[index].quantity).toString()}
                        />
                        <Field
                          name={`${member}.amount2`}
                          disabled
                          type="string"
                          label={"GBP Cost: "}
                          component={Input2}
                          defaultValue={(returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.usdCost * costingData[index].quantity).toString()}
                        />
                      </Stack>
                      {/* {costingData[index]?.quantity && (
                        <>
                          <Box>
                            {selectedCountry.label + " Cost : "}
                            {returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.countryCost * costingData[index].quantity}
                          </Box>
                          <Box>
                            {"USD Cost : "}
                            {returnMatching(masterDataList, costingData[index]?.classification, selectedCountry.value)?.usdCost * costingData[index].quantity}
                          </Box>
                        </>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <ClearIcon
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        onClick={() => fields.remove(index)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )
            })}
          </Grid>
        )}
      </Grid>
    </Box >
  )
};

export default RenderComponentAdd;
