import axios from 'axios';
import config from '../../config';

const getPrediction = (formValues, modelData) => {
  // Uploading reports via formData
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  if (formValues) {
    formData.append('values', JSON.stringify(formValues));
  }
  if (modelData) {
    formData.append('model', JSON.stringify(modelData));
  }
  return axios.post(
    `${config.BACKEND_API}/v1/predict`,
    formData,
  );
};

const getSuggestiveFormulation = (target, targetValue, models) => {
  // Uploading reports via formData
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  if (targetValue) {
    formData.append('targetValue', targetValue);
  }
  if (target) {
    formData.append('target', target.value);
  }
  if (models) {
    formData.append('models', JSON.stringify(models));
  }
  return axios.post(
    `${config.BACKEND_API}/v1/suggestiveFormulation`,
    formData,
  );
};

const saveModels = (modelsList, fileData) => {
  // Uploading reports via formData
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  if (modelsList) {
    formData.append('models', JSON.stringify(modelsList));
  }
  if (fileData) {
    for (let i = 0; i < fileData.reports.length; i += 1) {
      formData.append('files', fileData.reports[i]);
    }
  }
  return axios.post(
    `${config.BACKEND_API}/v1/models`,
    formData,
  );
};

const getModels = () => axios.get(
  `${config.BACKEND_API}/v1/models`,
);

const deleteModel = (values) => axios.delete(
  `${config.BACKEND_API}/v1/models`,
  { data: values },
);

const getModelDataFromId = (modelId) => axios.get(
  `${config.BACKEND_API}/v1/model/${modelId}`,
);

const getActives = () => axios.get(
  `${config.BACKEND_API}/v1/actives`,
)

const addActive = (name) => {
  const formData = new FormData();
  if (name) {
    formData.append('active', name);
  }
  return axios.post(
    `${config.BACKEND_API}/v1/actives`,
    formData,
  );
}

export default {
  getPrediction,
  getSuggestiveFormulation,
  saveModels,
  getModels,
  getActives,
  addActive,
  getModelDataFromId,
  deleteModel
};
