import { makeStyles } from '@mui/styles';

const commonStyle = makeStyles({
    buttons: {

    },
    '&:hover': {
        background: 'red',
        borderRadius: '0',
        outline: 'none',
    },
});

export default commonStyle