import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
// import './spreadsheet.css';
// import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import FileInput from '../components/FileInput';
import { Loading, SmallLoading, LoadingIcon } from '../components/Loading';

const validate = (values) => {
  const errors = {};
  return errors;
};

let UploadFileForm = (props) => {
  const {
    handleSubmit,
    submitting,
    reports,
  } = props;
  const [displayRows, setDisplayRows] = useState([]);
  const [tempFile, setTempFile] = useState();
  const [displayCols, setDisplayCols] = useState([]);
  const [resetFileSelection, setResetFileSelection] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const errorData = useSelector(state => state.File.errorData);
  const validationError = useSelector(state => state.File.validationError);
  const isLoading = useSelector(state => state.File.isLoading);

  if (resetFileSelection === true) {
    ExcelRenderer(reports[0], (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        setDisplayCols(resp.cols);
        setDisplayRows(resp.rows);
        setResetFileSelection(false);
        setTempFile(reports[0]);
        setTimeout(() => setSpinner(false), 1000);
        setSpinner(true);
      }
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Field
            component={FileInput}
            type="file"
            name="reports"
            label=""
            disabled={submitting}
            resetFileSelection={setResetFileSelection}
          />
          {!spinner && displayRows.length > 0 && displayCols.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
            >
              BUILD MODEL
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {displayRows.length > 0 && displayCols.length > 0 && spinner && (
            <LoadingIcon />
          )}
        </Grid>
        {isLoading ? (
          <Grid item xs={12} md={12}>
            <Loading />
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            {displayRows.length > 0 && displayCols.length > 0 && !spinner && (
              <>
                <div>
                  <h3>File Preview</h3>
                </div>
                <div style={{ height: '60vh', width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                  <OutTable data={displayRows} columns={displayCols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                </div>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

UploadFileForm = reduxForm({
  form: 'UPLOAD_FORM',
  validate,
})(UploadFileForm);
const selector = formValueSelector('UPLOAD_FORM');

UploadFileForm = connect(state => { // eslint-disable-line
  const reports = selector(state, 'reports');
  return {
    reports,
  };
})(UploadFileForm);

export default UploadFileForm;
