import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { uniq } from 'lodash';

const ErrMsg = styled('div')(({ theme }) => ({
  display: 'block',
  width: '100%',
  marginTop: '0.25rem',
  fontSize: '80%',
  color: '#dc3545',
}));

const getUniqueBy = (prop, list) => {
  const objUniq = list.reduce((res, item) => ({ ...res, [item[prop]]: item }), {})
  return Object.keys(objUniq).map(item => objUniq[item])
}

export const renderSelectInput4 = ({
  input,
  label,
  tempKey,
  appendPurityPercentage,
  disabled,
  meta: { touched, error, warning },
  list,
  filterkey1,
  filterkey2,
  getunique,
}) => {
  let asdTempFilterRawMaterial = filterkey2;
  if (filterkey2 && filterkey2.includes(" ")) {
    asdTempFilterRawMaterial = filterkey2.split(" ")[0];
  }
  const tempList = [];
  const tempList2 = [];
  for (let k = 0; k < list.length; k++) {
    if (filterkey1 && !filterkey2) {
      if (list[k].classification.includes(filterkey1)) {
        tempList2.push(list[k]);
      }
    } else if (filterkey1 && filterkey2) {
      if (list[k].classification.includes(filterkey1) && list[k].rawmaterial.includes(asdTempFilterRawMaterial)) {
        tempList2.push(list[k]);
      }
    }
    else {
      tempList2.push(list[k]);
    }

  }
  if (tempList2 && tempList2.length > 0) {
    tempList2.forEach(function (element) {
      if (appendPurityPercentage && element['purityPercentage']) {
        tempList.push({
          label: element[tempKey] + ' ' + element['purityPercentage'] + '%',
          value: element[tempKey] + ' ' + element['purityPercentage'] + '%'
        })
      } else {
        tempList.push({
          label: element[tempKey],
          value: element[tempKey]
        })
      }

    });
  }
  return (
    <>
      <Box my={2} sx={{ width: '40%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          {...input}
          placeholder={`Select ${label}`}
          disabled={disabled}
          options={getunique ? getUniqueBy('value', tempList) : tempList}
          // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
          value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
          // options={list}
          // value={list && list.length > 0 && list.find((option) => option.value === input.value)}
          //options={tempList}
          // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
          // value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
          onChange={(value) => value ? input.onChange(value.value) : input.onChange(null)}
          onBlur={() => input.onBlur()}
        />
      </Box>
      {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
    </>
  )
};


export const renderSelectInput3 = ({
  input,
  label,
  disabled,
  meta: { touched, error, warning },
  list,
}) => {
  const tempList = [];
  if (list && list.length > 0) {
    list.forEach(function (element) {
      tempList.push({
        label: element.classification + ":" + element.rawmaterial + ":" + element.tds,
        value: element.tds + ":" + element.rawmaterial
      })
    });
  }
  return (
    <>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              {...input}
              placeholder={`Select ${label}`}
              disabled={disabled}
              options={tempList}
              // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
              value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
              // options={list}
              // value={list && list.length > 0 && list.find((option) => option.value === input.value)}
              //options={tempList}
              // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
              // value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
              onChange={(value) => value ? input.onChange(value.value) : input.onChange(null)}
              onBlur={() => input.onBlur()}
              maxMenuHeight={100}
            />
          </Grid>
        </Grid>
      </Box>
      {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
    </>
  )
};

export const renderSelectInput2 = ({
  input,
  label,
  disabled,
  meta: { touched, error, warning },
  list,
  significantkt50,
  significantm24,
  required,
  autoSelectIfOnlyOneParameter,
  showSignificant,
}) => {

  const tempList = [];
  if (list && list.length > 0) {
    list.forEach(function (element) {
      tempList.push({
        label: element,
        value: element
      })
    });
  }

  if (list && list.length === 1 && autoSelectIfOnlyOneParameter) {
    input.onChange(tempList[0].value)
  }

  if (showSignificant) {
    if (significantkt50 || significantm24) {
      return (
        <>
          <Box my={2}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
                <Typography component="h2" color="initial">
                  {label}
                  {(significantkt50 && !significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for KT50">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {(!significantkt50 && significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for M24">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {(significantkt50 && significantm24) ? (
                    <>
                      <Typography display="inline" sx={{ color: 'red' }} variant="h5" component="h6">
                        (*)
                      </Typography>
                      <Tooltip placement="top" title="Significant Factor for KT50 and M24">
                        <IconButton color="primary">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  {...input}
                  placeholder={`Select ${label}`}
                  disabled={disabled}
                  options={tempList}
                  // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
                  value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
                  // options={list}
                  // value={list && list.length > 0 && list.find((option) => option.value === input.value)}
                  //options={tempList}
                  // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
                  // value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
                  onChange={(value) => value ? input.onChange(value.value) : input.onChange(null)}
                  onBlur={() => input.onBlur()}
                  maxMenuHeight={100}
                  isClearable
                />
              </Grid>
            </Grid>
          </Box>
          {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
        </>
      )
    }
    else {
      return null;
    }
  }
  else {
    return (
      <>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
              <Typography component="h2" color="initial">
                {label}
                {(significantkt50 && !significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }}>
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for KT50">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
                {(!significantkt50 && significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }}>
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for M24">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
                {(significantkt50 && significantm24) ? (
                  <>
                    <Typography sx={{ color: 'red' }}>
                      (*)
                    </Typography>
                    <Tooltip placement="top" title="Significant Factor for KT50 and M24">
                      <IconButton color="primary">
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                {...input}
                placeholder={`Select ${label}`}
                disabled={disabled}
                options={tempList}
                // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
                value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
                // options={list}
                // value={list && list.length > 0 && list.find((option) => option.value === input.value)}
                //options={tempList}
                // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
                // value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
                onChange={(value) => value ? input.onChange(value.value) : input.onChange(null)}
                onBlur={() => input.onBlur()}
                maxMenuHeight={100}
                isClearable
              />
            </Grid>
          </Grid>
        </Box>
        {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
      </>
    )
  }
};

export const renderSelectInput = ({
  input,
  label,
  disabled,
  meta: { touched, error, warning },
  list,
  significantkt50,
  significantm24,
  required,
  autoSelectIfOnlyOneParameter,
}) => {
  return (
    <>
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
            <Typography component="h2" color="initial">
              {label}
            </Typography>
            {(significantkt50 && !significantm24) ? (
              <>
                <Typography sx={{ color: 'red' }}>
                  (*)
                </Typography>
                <Tooltip placement="top" title="Significant Factor for KT50">
                  <IconButton color="primary">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
              </>
            )}
            {(!significantkt50 && significantm24) ? (
              <>
                <Typography sx={{ color: 'red' }}>
                  (*)
                </Typography>
                <Tooltip placement="top" title="Significant Factor for M24">
                  <IconButton color="primary">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
              </>
            )}
            {(significantkt50 && significantm24) ? (
              <>
                <Typography sx={{ color: 'red' }}>
                  (*)
                </Typography>
                <Tooltip placement="top" title="Significant Factor for KT50 and M24">
                  <IconButton color="primary">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={9}>
            <Select
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              {...input}
              placeholder={`Select ${label}`}
              disabled={disabled}
              options={list}
              value={list && list.length > 0 && list.find((option) => option.value === input.value)}
              //options={tempList}
              // defaultValue={autoSelectIfOnlyOneParameter && tempList && tempList.length === 1 && input.onChange(tempList[0].value)}
              // value={tempList && tempList.length > 0 && tempList.find((option) => option.value === input.value)}
              onChange={(value) => value ? input.onChange(value.value) : input.onChange(null)}
              onBlur={() => input.onBlur()}
              maxMenuHeight={100}
              isClearable
            />
          </Grid>
        </Grid>
      </Box>
      {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
    </>
  )
};

export const renderMultiSelectInput = ({
  input,
  label,
  disabled,
  meta: { touched, error, warning },
  list,
  closeMenuOnSelect,
}) => (
  <>
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
          <Typography component="h2" color="initial">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Select
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            {...input}
            disabled={disabled}
            options={list}
            value={input.value}
            onChange={(value) => input.onChange(value)}
            onBlur={() => input.onBlur()}
            isMulti
            closeMenuOnSelect={closeMenuOnSelect}
            isClearable
          />
        </Grid>
      </Grid>
    </Box>
    {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
  </>
);

export const renderNoMenuMultiSelectInput = ({
  input,
  label,
  disabled,
  meta: { touched, error, warning },
  list,
}) => (
  <>
    <Box my={2}>
      <Grid container>
        <Grid item xs={12} sm={3} style={{ paddingTop: '5px' }}>
          <Typography component="h2" color="initial">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Select
            {...input}
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            placeholder={`Select ${label}`}
            disabled={disabled}
            options={list}
            value={input.value}
            onChange={(value) => input.onChange(value)}
            onBlur={() => input.onBlur()}
            isMulti
            isClearable
            components={
              {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Menu: () => null,
              }
            }
          />
        </Grid>
      </Grid>
    </Box>
    {touched && ((error && <ErrMsg>{error}</ErrMsg>) || (warning && <span>{warning}</span>))}
  </>
);
