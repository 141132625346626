import React from 'react';
import Grid from '@mui/material/Grid';
import SideMenuAppWrapper from '../../components/SideMenuAppWrapper';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import cocacola from '../../assets/img/Path32.png';
import Liquid_Vapo from '../../assets/img/LV.png';
import Box from '@mui/material/Box';
import coil from '../../assets/img/coil.png';
import personal_insect_repp from '../../assets/img/personal_repp.png';
import reckitt_logo from '../../assets/Asset_1.png';
import '../../assets/stylesheet/landing-page.css';

const HomePage = () => {
  return (
    <SideMenuAppWrapper>
      <Box
        className='top-bar'
        sx={{
          justifyContent: 'space-between', display: 'flex',
        }}
      >
        <div />
        <div sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white", textAlign: "center", p: 1 }}>
            SmartPredict
          </Typography>
        </div>
        <div>
          <img
            style={{
              width: '75px', margin: '0 auto', padding: '10px', cursor: 'pointer',
            }}
            src={reckitt_logo}
            alt="logo"
          />
        </div>
      </Box>
      <Grid container sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 6
      }}>
        <Grid item xs={12} md={3} sx={{ padding: '10px' }}>
          <Card sx={{ maxWidth: 250 }}>
            <Link to="/aerosol">
              <CardMedia
                component="img"
                height="200"
                image={cocacola}
                alt="green iguana"
              />
              <CardActions style={{
                background: 'linear-gradient(0deg, rgba(206,0,55,1) 0%, rgba(255,94,137,1) 100%)'
              }}>
                <Button style={{ color: "#fff", border: 'none', outline: 'none', margin: '0 auto', padding: '0px', textTransform: 'capitalize', fontSize: '16px' }}>
                  Aerosol
                </Button>
              </CardActions>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} sx={{ padding: '10px' }} alignItems="center">
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="200"
              image={Liquid_Vapo}
              alt="green iguana"
            />
            <CardActions style={{
              background: 'linear-gradient(0deg, rgba(206,0,55,1) 0%, rgba(255,94,137,1) 100%)'
            }}>
              <Button disabled style={{ margin: '0 auto', padding: '0px', textTransform: 'capitalize', fontSize: '16px' }}>
                Liquid Vaporiser
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{
        display: 'flex',
        justifyContent: 'center',
        pt: 6
      }}>
        <Grid item xs={12} md={3} sx={{ padding: '10px' }} alignItems="center">
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="200"
              image={coil}
              alt="green iguana"
            />
            <CardActions style={{
              background: 'linear-gradient(0deg, rgba(206,0,55,1) 0%, rgba(255,94,137,1) 100%)'
            }}>
              <Button disabled style={{ margin: '0 auto', padding: '0px', textTransform: 'capitalize', fontSize: '16px' }}>
                Coil
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} sx={{ padding: '10px' }} alignItems="center">
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia
              component="img"
              height="200"
              image={personal_insect_repp}
              alt="green iguana"
            />
            <CardActions style={{
              background: 'linear-gradient(0deg, rgba(206,0,55,1) 0%, rgba(255,94,137,1) 100%)'
            }}>
              <Button disabled style={{
                margin: '0 auto', padding: '0px', textTransform: 'capitalize', fontSize: '16px',
              }}>
                Personal Insect Repellant
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </SideMenuAppWrapper>
  );
};

export default HomePage;
