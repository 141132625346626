import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';
import Button from '@mui/material/Button';
import Input from '../components/Input';
import CheckboxInput from '../components/CheckboxInput';

const validate = (values) => {
  const errors = {};
  return errors;
};

let LoginForm = (props) => {
  const {
    handleSubmit,
    submitting,
  } = props;
  const isLoading = useSelector((state) => state.Auth.isLoading);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name={`email`}
        type="string"
        label="Email"
        component={Input}
        required
      />
      <Field
        name="projects"
        type="checkbox"
        component={CheckboxInput}
        label="Projects Access"
        required
      />
      <Field
        name="users"
        type="checkbox"
        component={CheckboxInput}
        label="User Mgmt Access"
        required
      />
      <Field
        name="masterdata"
        type="checkbox"
        component={CheckboxInput}
        label="Master Data Access"
        required
      />
      <Field
        name="exchangerate"
        type="checkbox"
        component={CheckboxInput}
        label="Exchange Rate Access"
        required
      />
      <Field
        name="showallmodels"
        type="checkbox"
        component={CheckboxInput}
        label="Show All Models"
        required
      />
      <Field
        name="uploaddatafile"
        type="checkbox"
        component={CheckboxInput}
        label="Upload Data File"
        required
      />
      <Field
        name="regulatorylimit"
        type="checkbox"
        component={CheckboxInput}
        label="Regulatory Limit"
        required
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={submitting || isLoading}
      >
        Submit
      </Button>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'VALID_USERS_FORM',
  validate,
})(LoginForm);

export default LoginForm;
