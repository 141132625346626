import axios from 'axios';
import config from '../../config';

// Plant
const getMasterData = () => axios.get(
  `${config.BACKEND_API}/v1/masterdata`,
);

const addNewMasterData = (values) => axios.post(
  `${config.BACKEND_API}/v1/masterdata`,
  values,
);

const editMasterData = (values) => axios.put(
  `${config.BACKEND_API}/v1/masterdata`,
  values,
);

const deleteMasterData = (values) => axios.delete(
  `${config.BACKEND_API}/v1/masterdata`,
  { data: values },
);


export default {
  getMasterData,
  addNewMasterData,
  editMasterData,
  deleteMasterData,
};
