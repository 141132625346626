import axios from 'axios';
import config from '../../config';

// Plant
const getProjects = () => axios.get(
  `${config.BACKEND_API}/v1/projects`,
);

const addNewProject = (values) => axios.post(
  `${config.BACKEND_API}/v1/projects`,
  values,
);

const editProject = (values) => axios.put(
  `${config.BACKEND_API}/v1/projects`,
  values,
);

const deleteProject = (values) => axios.delete(
  `${config.BACKEND_API}/v1/projects`,
  { data: values },
);


export default {
  getProjects,
  addNewProject,
  editProject,
  deleteProject,
};
